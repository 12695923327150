import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Starter U2",
    id: "SW9-2024-Starter-U2-P56-E1",
    exerciseKey: "/img/FriendsPlus/Page56/key/e1-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` <span style='color:#574d89;font-weight:800;'>Speaking</span>  

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;color:rgb(87, 77, 137);' > 1 </span> 
                    <span > Look at the photo below. What are the people learning about? </span>
                    <input id=0 />
                </div>
                <img src='/img/FriendsPlus/Page56/13.jpg' style='width:600px;margin-top:20px' />

        </div>
      
        `,
        answer: ["Film-making"],
      },
    ],
  },
  2: {
    unit: "Starter U2",
    id: "SW9-2024-Starter-U2-P56-E2",
    exerciseKey: "/img/FriendsPlus/Page56/key/e2-key.jpg",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 800,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` <span style='color:#574d89;font-weight:800;'>Reading</span> 

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;color:rgb(87, 77, 137);' > 2 </span> 
                    <span > Read Tom’s blog post. Did Tom enjoy himself last Saturday? Why / Why not? </span> <br>
                    <input id=0 />
                </div>
                <img src='/img/FriendsPlus/Page56/13.jpg' style='width:600px;margin-top:20px' />

        </div>
      
        `,
        answer: ["Yes. It was a great experience and he got a lot out of it."],
      },
    ],
  },
  3: {
    unit: "Starter U2",
    id: "SW9-2024-Starter-U2-P56-E3",
    exerciseKey: "/img/FriendsPlus/Page56/key/e3-key.jpg",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 50,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Read the blog post again. Number the events a–f in the correct order. 

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <img src='/img/FriendsPlus/Page56/13.jpg' style='width:600px;margin-top:20px' />
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > a </span> 
                    <span > The students ate lunch </span>
                    <span style='font-weight:bold;border-bottom:2px dotted;'> 3 </span> 
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > b </span> 
                    <span > Tom posted the film on his blog. </span>
                    <input id=0 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > c </span> 
                    <span > Everybody saw all the different films </span>
                    <input id=1 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > d </span> 
                    <span > Tom had a coffee with the other students </span>
                    <input id=2 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > e </span> 
                    <span > Tom helped to do the lights for his film </span>
                    <input id=2 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > f </span> 
                    <span > The students learned to use the cameras </span>
                    <input id=3 />
                </div>

        </div>
      
        `,
        answer: ["6", "5", "1", "4", "2"],
      },
    ],
  },
  4: {
    unit: "Starter U2",
    id: "SW9-2024-Starter-U2-P56-E4",
    exerciseKey: "/img/FriendsPlus/Page56/key/e4-key.jpg",
    audio: "/Audios/1.18.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 200,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: `  <span style='color:#574d89;font-weight:800;'>Listening</span>  
        <br>
       <span style='font-size:30px'>4</span> <headphone name=18 colorImg=#71699c></headphone>  Listen to the conversation. Choose the correct answer.

`,
        left: -50,
        star: 0,
        color: "#574d89",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: [],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "58px",
              left: "232px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          // borderBottom: "none",
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a<br>|b<br>|c"],
        answers: ["0-0"],
        initialValue: [],
      },
      Layout: `
        
        <div style='margin-top:20px;'>
            <p>Who is showing Emma how to use a camera?</p>
            <div style='display:flex;'>
              <div style='margin-right:10px;'> <input id=0 type=Circle /> </div>
              <div>
                <span>An assistant in a camera shop </span> <br>
                <span>A helper at a youth club  </span> <br>
                <span>A teacher in a lesson at school </span> 
              </div>
            </div>
        </div>
      `,
    },
  },
  5: {
    unit: "Starter U2",
    id: "SW9-2024-Starter-U2-P56-E5",
    exerciseKey: "/img/FriendsPlus/Page56/key/e5-key.jpg",
    audio: "/Audios/1.18.mp3",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 100,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=18 colorImg=#71699c></headphone> Listen again and write true or false.  

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
            <span > Emma used to borrow her brother’s camera </span>
            <input id=0 />
          </div>
                   
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
            <span > Emma has seen a camera like this before </span>
            <input id=1 />
          </div>
                     
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
            <span > This type of camera has been around for a long time. </span>
            <input id=2 />
          </div>
                      
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span> 
            <span > There’s a tough bag to carry the camera in </span>
            <input id=3 />
          </div>
                     
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span> 
            <span > Emma rarely breaks things </span>
            <input id=4 />
          </div>
                
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 6 </span> 
            <span > Emma makes a decision about the camera </span>
            <input id=5 />
          </div>               

        </div>
      
        `,
        answer: ["true", "false", "false", "true", "true", "false"],
      },
    ],
  },
  6: {
    unit: "Starter U2",
    id: "SW9-2024-Starter-U2-P56-E6",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` <span style='color:#574d89;font-weight:800;'>Writing</span>  

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px; width:26cm'>

               <b style='font-size:30px'>6</b>&ensp;<span style='margin-right:10px;font-weight:bold;' > A TASK Write a blog post about a
                  technology workshop that you have done, for example, about taking photos,
                  making a website, creating a new app or developing a video game.
                 </span> <br>
                 <textarea rows=5 id=0 ></textarea>
        
                <span style='margin-right:10px;font-weight:bold;' > B THINK AND PLAN </span> <br> 
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > What kind of workshop was it and what did you learn
                    about? Which friend did you go with? <br> What were the other people like? </span>
                    <textarea rows=2 id=1 ></textarea>
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                    <span > What did you do during the day? What was your friend doing? </span>
                    <textarea rows=2 id=2 ></textarea>
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                    <span > What happened at the end? How did you feel? </span>
                    <textarea rows=2 id=3 ></textarea>
                </div>

                <span style='margin-right:10px;font-weight:bold;' > C WRITE Use the text in exercise 2 and the writing
                guide. </span> <br>
                <img src='/img/FriendsPlus/Page56/14.jpg' style='width:600px;margin-left:200px;margin-top:40px' />
                <br><br>
                <textarea rows=6 id=4 ></textarea>

        </div>
      
        `,
        answer: [],
      },
    ],
  },
};

export default json;
