/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

function HeadPhone({
  marginLeft,
  name,
  src,
  typeImg = "sound",
  colorImg,
  style,
}) {
  const audio = new Audio(src);
  React.useEffect(() => {
    return () => {
      audio.pause();
    };
  }, [audio]);

  return (
    <div style={{ display: "inline-block" }}>
      <img
        src={`img/${typeImg}.png`}
        alt=""
        style={{
          width: "85px",
          background: colorImg ?? "none",
          // cursor: "url(img/volume.png) 12 12, auto"
        }}
        onClick={() => {
          audio.play();
        }}
      />
      <span
        style={{
          fontSize: 22,
          fontWeight: "bold",
          // color: colorWhite ?? "white",
          marginLeft: marginLeft ?? "-42px",
          marginRight: 15,
          ...style,
        }}
      >{` ${name}`}</span>
    </div>
  );
}

export default HeadPhone;
