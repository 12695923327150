import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "SW9-2024-Starter-P5-E1",
    exerciseKey: "img/FriendsPlus/Page5/Key/E1answerKey.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "am",
          "are",
          "do",
          "does",
          "download",
          "is",
          "’m",
          "’re",
          "’s",
          "uploads",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the table with the words.`,
        color: "#1dbfd6",
        left: 50,
        star: 1,
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
          <div style='position:absolute;top:111px;left:167px'>#</div>
          <div style='position:absolute;top:167px;left:21px'>#</div>
          <div style='position:absolute;top:203px;left:21px'>#</div>
          <div style='position:absolute;top:306px;left:35px'>#</div>
          <div style='position:absolute;top:341px;left:167px'>#</div>
          <div style='position:absolute;top:375px;left:280px'>#</div>
          <div style='position:absolute;top:481px;left:21px'>#</div>
          <div style='position:absolute;top:515px;left:21px'>#</div>
          <div style='position:absolute;top:550px;left:21px'>#</div>
            <img src="img/FriendsPlus/Page5/E1/1.jpg" style='height:17cm'/>
        </div>
      
        
        `,
        answer: ["uploads", "Do", "Does", "'m", "'s", "'re", "Am", "Is", "Are"],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SW9-2024-Starter-P5-E2",
    exerciseKey: "img/FriendsPlus/Page5/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Complete the online chat with the
present simple or present continuous form of the
verbs in brackets.`,
        color: "#1dbfd6",
        width: "27cm",
        left: 50,
        star: 2,
        colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "check",
          "download",
          "follow",
          "<s>post</s>",
          "share",
          "spend time",
          "update",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "2px solid",
      borderBottom: "none",
      width: 150,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
         <div style='position:relative'>
          <div style='position:absolute;top:55px;left:30px'><input width=223px /></div>
          <div style='position:absolute;top:149px;left:318px'><input width=191px /></div>
          <div style='position:absolute;top:183px;left:341px'><input width=173px /></div>
          <div style='position:absolute;top:218px;left:435px'><input width=184px /></div>
          <div style='position:absolute;top:288px;left:30px'><input width=184px /></div>
          <div style='position:absolute;top:366px;left:186px'><input width=184px /></div>
          <div style='position:absolute;top:400px;left:299px'><input width=184px /></div>
          <div style='position:absolute;top:436px;left:208px'><input width=184px /></div>
          <div style='position:absolute;top:515px;left:171px'><input width=184px /></div>
          <div style='position:absolute;top:550px;left:412px'><input width=184px /></div>
          <div style='position:absolute;top:585px;left:190px'><input width=184px /></div>
          <div style='position:absolute;top:620px;left:234px'><input width=184px /></div>
          <div style='position:absolute;top:727px;left:187px'><input width=184px /></div>
          
            <img src="img/FriendsPlus/Page5/E2/1.jpg" style='height:21cm'/>
        </div>
        
      
        
        `,
        answer: [
          "Are you writing",
          "leave",
          "'m looking",
          "want",
          "don't know",
          "organises",
          "resets",
          "doesn't tell",
          "isn't answering",
          "'s working",
          "works",
          "Do you know",
          "remember",
        ],
      },
    ],
  },

  3: {
    unit: "Starter",
    id: "SW9-2024-Starter-P5-E3",
    exerciseKey: "img/FriendsPlus/Page5/Key/E3answerKey.png",
    audio: "",
    video: "",
    // textAlign:'center',
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 150,
      paddingTop: 10,
      textAlign: "center",
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "awesome places to visit",
          "big hotels",
          "cool shops and markets",
          "fun entertainment",
          "historic buildings",
          "nice parks to walk in",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
      {
        src: [
          "big queues",
          "cheap places to eat",
          "crowds of tourists",
          "green spaces",
          "litter",
          "space on public transport",
          "traffic",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Choose the correct question words.`,
        width: "27cm",
        color: "#1dbfd6",
        left: -50,
        star: 2,
        colorStar: "#b4e2ef",
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "what / when",
          "whom / how",
          "where / what",
          "when / how",
          "what / when",
        ],
        answers: ["0-0", "1-0", "2-0", "3-4", "4-4"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='width:26cm;line-height:50px'>
        <b>1</b>&ensp;Can you tell me <input id=0 type=Circle /> to do to improve my English?<br>
        <b>2</b>&ensp;I'm not sure <input id=1 type=Circle /> to ask for help.<br>
        <b>3</b>&ensp;Do you know <input id=2 type=Circle /> to find Mary's blog?<br>
        <b>4</b>&ensp;Have you learned <input id=3 type=Circle /> to use this software before?<br>
        <b>5</b>&ensp;The teacher is telling her students <input id=4 type=Circle /> to hand in their projects.<br>
        
      </div>
      `,
    },
  },
  4: {
    unit: "Starter",
    id: "SW9-2024-Starter-P5-E4",
    exerciseKey: "img/FriendsPlus/Page5/Key/E4answerKey.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    // isHello:true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 200,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: {
      width: 940,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "book",
          "pronounce",
          "buy",
          "ask",
          "learn",
          "fix",
          "improve",
          "<s>deal</s>",
          "handle",
          "contact",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with to-infinitive
of the verbs in the box.
`,
        color: "#1dbfd6",
        width: "27cm",
        left: 50,
        star: 2,
        colorStar: "#b4e2ef",
      },
    ],
    // KeyMau: true,
    // isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
      <div style='width:27cm'>
        <b>1</b>&ensp;I'm not sure how <u style='text-decoration-style: dotted'>&emsp;to deal&emsp;</u>  with these complaints.<br>
        <b>2</b>&ensp;Can you explain how # this word correctly?<br>
        <b>3</b>&ensp;Do you know where # tickets for the concert?<br>
        <b>4</b>&ensp;Tell me how # difficult situations at work.<br>
        <b>5</b>&ensp;Have you considered when # coding for your job?<br>
        <b>6</b>&ensp;I wonder whether # the boss for a raise or not.<br>
        <b>7</b>&ensp;Can you tell me where # this cheesecake?<br>
        <b>8</b>&ensp;Do you know who # in case of an emergency?<br>
        <b>9</b>&ensp;Tell me how # my writing skills.<br>
        <b>10</b>&ensp;Have you figured out how # the issue with your computer?<br>
      </div>

          
        `,
        answer: [
          "to pronounce",
          "to book",
          "to handle",
          "to learn",
          "to ask",
          "to buy",
          "to contact",
          "to improve",
          "to fix",
        ],
      },
    ],
  },
  5: {
    unit: "Starter",
    id: "SW9-2024-Starter-P5-E5",
    exerciseKey: "img/FriendsPlus/Page5/Key/E5answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 850,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: ["how many", "what", "when", "<s>where</s>", "who", "why"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Order the phrases to make meaningful
questions and answer them.`,
        color: "#1dbfd6",
        left: 50,
        star: 3,
        colorStar: "#b4e2ef",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        improve time / know how to / management skills? / Do you <br>
        <u style='text-decoration-style: dotted'>&emsp;Do you know how to improve time management skills?&emsp;</u>
        <div style='width:27cm'>
            <b>1</b>&ensp;me why to / for a term? / consider studying abroad / Can you tell<br>#<br>
            <b>2</b>&ensp;who to approach / Do you know / on writing a / for guidance / good essay?<br>#<br>
            <b>3</b>&ensp;recommend where to / Can you / party next week? / celebrate our year-end<br>#<br>
            <b>4</b>&ensp;tell me who / before using the hall? / to ask for permission / Can you <br>#<br>
        </div>
          
        `,
        answer: [
          "Can you tell me why to consider studying abroad for a term?",
          "Do you know who to approach for guidance on writing a good essay?",
          "Can you recommend where to celebrate our year-end party next week?",
          "Can you tell me who to ask for permission before using the hall?",
        ],
      },
    ],
  },
};

export default json;
