import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit1",
    id: "SW9-2024-U1-P8-E1",
    exerciseKey: "img/FriendsPlus/Page8/Key/E1answerKey.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Find ten more verbs and phrases in the
word search.`,
        color: "#0067b4",
        left: -50,
        star: 1,
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: true,
        colorLineTo: "#40c7f4",
        newPointDot: true,
        // notOverlapse: true,
        circlePadding: "0 0",
        // margin: "0 0 0 10px",
        padding: "4px 0px 4px 15px",
        border: "1px solid ",
        // transform: "translate(50%)",
        circleStringArray: [
          "COMEACROSS",
          "VZLTWOEYQE",
          "LOOKBACKXB",
          "LEAVEDOFKE",
          "XKEFORGETL",
          "REMINDNPKO",
          "YEINPLIKDN",
          "KPQDDWSVPG",
          "REMEMBERET",
          "GETBACKNIO",
        ],
        boxMatch: [],
        answers: [
          "0_0-0_9",
          "2_0-2_7",
          "2_9-9_9",
          "3_0-3_4",
          "0_6-8_6",
          "5_0-5_5",
          "4_1-7_1",
          "4_3-7_3",
          "4_3-4_8",
          "8_0-8_7",
          "9_0-9_6",
        ],
        initialValue: ["0_0-0_9"],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: ["remember"],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        initialWordStyle: {
          border: "2px solid",
          borderColor: "transparent",
          padding: "1px 5px",
          borderRadius: "50%",
          fontSize: 20,
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: [
          "soda / ice_tea",
          "mushroom / meat",
          "lemonade / melon",
          "onion / melon",
          "onion / meat",
          "lemonade / ice_tea",
          "cucumber / lemonade",
          "onion / mushrooms",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
  
      <div id='circle-container' style='position: relative; width: 14cm;  display: grid; grid-template-columns: repeat(10, 1fr)'>
            <input id=0 type='boxMatch'/>
            <input id=1 type='boxMatch'/>
            <input id=2 type='boxMatch'/>
            <input id=3 type='boxMatch'/>
            <input id=4 type='boxMatch'/>
            <input id=5 type='boxMatch'/>
            <input id=6 type='boxMatch'/>
            <input id=7 type='boxMatch'/>
            <input id=8 type='boxMatch'/>
            <input id=9 type='boxMatch'/>
          </div>
        Which word or phrase in the word search is the opposite of forget? 
        <input id=0 />
      `,
    },
  },
  2: {
    unit: "Unit1",
    id: "SW9-2024-U1-P8-E2",
    exerciseKey: "img/FriendsPlus/Page8/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Match the verbs and phrases from
exercise 1 with the definitions.`,
        color: "#0067b4",
        width: "27cm",
        left: 50,
        star: 2,
        colorStar: "#95b0db",
      },
    ],
    styleHint: {
      borderColor: "#0067b4",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "check",
          "download",
          "follow",
          "<s>post</s>",
          "share",
          "spend time",
          "update",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 240,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        to think about something in your past <u style='text-decoration-style: dotted'>&emsp;look back&emsp;</u>
        <div style='width:26cm'>
          <b>1</b>&ensp;to not remember something  #<br>
          <b>2</b>&ensp;to discover something  #<br>
          <b>3</b>&ensp;to go away from a place without taking something with you  #<br>
          <b>4</b>&ensp;to know who somebody is because you have seen them before  #<br>
          <b>5</b>&ensp;if something makes you remember a person, place or thing  #<br>
          <b>6</b>&ensp;to continue to have something and not give or throw it away   #<br>
          <b>7</b>&ensp;to be owned by somebody  #<br>
          <b>8</b>&ensp;to obtain something again after loss #<br>
          <b>9</b>&ensp;to have an image in your memory of a person, place or thing  #<br>
          <b>10</b>&ensp;to find something by chance #<br>
        </div>
      
        
        `,
        answer: [
          "forget",
          "find",
          "leave",
          "recognise",
          "remind",
          "keep",
          "belong to",
          "get back",
          "remember",
          "come across",
        ],
      },
    ],
  },

  3: {
    unit: "Unit1",
    id: "SW9-2024-U1-P8-E3",
    exerciseKey: "img/FriendsPlus/Page8/Key/E3answerKey.png",
    audio: "",
    video: "",
    // textAlign:'center',
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      width: 200,
      // padding: 0,
      fontSize: 23,
      paddingTop: 5,
      textAlign: "center",
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    styleHint: {
      borderColor: "#0067b4",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      marginLeft: 200,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>come across</s>",
          "get it back",
          "keep",
          "leave",
          "look back",
          "look forward to",
          "find",
          "recognise",
          "remember",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the dialogue with the correct form of the verbs.`,
        width: "27cm",
        color: "#0067b4",
        left: 50,
        star: 2,
        colorStar: "#95b0db",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        
        <hintbox id=0></hintbox>
        <div style='display:flex'>
          <div>
            <b>Oscar</b><br>
            <b>Clara</b><br>
            <b>Oscar</b><br>
            <b>Clara</b><br>
            <b>Oscar</b><br><br><br>
            <b>Clara</b><br><br>
            <b>Oscar</b><br>
            <b>Clara</b><br><br>
            <b>Oscar</b><br>
            <b>Clara</b><br>
          </div>
           <div style='width:25cm;margin-left:20px'>
              Look at this old photo of me that I
              <u style='text-decoration-style: dotted'>&emsp;came across&emsp;</u> on my cousin’s Facebook
              page!
              <br> You look just the same – you’re easy to
              <sup>1</sup># ! How old were you?
              <br> I was about six. I was at my cousin’s party.
              I can <sup>2</sup># it well.
              <br> What’s the story behind it?
              <br> Well, I was fed up because my cousin kept
              playing with my toy car and I didn’t want
              to <sup>3</sup># without it. I <sup>4</sup>#
              later, though! So, have you got any old
              family photos?
              <br> We’ve got lots of photos of me, and we
              still <sup>5</sup># some really cool ones
              of my parents as teenagers.
              <br> Really?
              <br> Yeah. We <sup>6</sup># most of those old
              photos in my grandparents' album lately.
              It’s nice to <sup>7</sup># on the past
              sometimes.
              <br> That’s true. My mum’s got some very
              funny primary school photos of us.
              <br> Wow! I <sup>8</sup># seeing them!
           </div>

        </div>





          
        `,
        answer: [
          "recognise",
          "remember",
          "leave",
          "got it back",
          "keep",
          "have found",
          "look back",
          "look forward to",
        ],
      },
    ],
  },
  4: {
    unit: "Unit1",
    id: "SW9-2024-U1-P8-E4",
    exerciseKey: "img/FriendsPlus/Page8/Key/E4answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    // isHello:true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 200,
      marginLeft: 20,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: {
      width: 700,
      resize: "none",
      paddingTop: 3,
      marginBottom: -23,
      marginLeft: 17,
      marginTop: -3,
    },
    styleHint: {
      borderColor: "#0067b4",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "book",
          "pronounce",
          "buy",
          "ask",
          "learn",
          "fix",
          "improve",
          "<s>deal</s>",
          "handle",
          "contact",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Write sentences about the topics below.
Use the words on this page and your own ideas.`,
        color: "#0067b4",
        width: "27cm",
        left: 50,
        star: 3,
        colorStar: "#95b0db",
      },
    ],
    // KeyMau: true,
    // isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:20cm'>
          Something you’d like to keep for the future<br>
            <u style='text-decoration-style: dotted'>&emsp;I’d like to keep my action figures because my younger cousins can play with them one day.&emsp;</u> <br>
            <b>1</b>&ensp;Something you quickly recognise at your primary school <textarea id=0 rows=2 ></textarea>
            <b>2</b>&ensp;Something you remember most about the first day of school <textarea id=1 rows=2 ></textarea>
            <b>3</b>&ensp;Something you look forward to every week <textarea id=2 rows=2 ></textarea>
            <b>4</b>&ensp;Something you came across recently at home <textarea id=3 rows=2 ></textarea>

        </div>





          
        `,
        answer: [
          "I quickly recognise the very large tree at my primary school. It's in the middle of the schoolyard.",
          "I remember my first school lesson in English alphabets most. My teacher was very gentle and kind then.",
          "I always look forward to drama club after school on Fridays because it's good fun.",
          "I came across my old diary at home recently. It's interesting to read so I decided to keep it.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit1",
    id: "SW9-2024-U1-P8-E5",
    exerciseKey: "img/FriendsPlus/Page8/Key/E5answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 850,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    styleHint: {
      borderColor: "#0067b4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "brother(s) / sister(s)",
          "live in …",
          "name",
          "used to play / go / like",
        ],
        width: 600,
        borderColor: "#0067b4",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `You meet a friend who you haven’t seen
for a long time. Write four or five questions using
question tags. Use the phrases in the box and
exercise 4 for ideas.`,
        color: "#0067b4",
        left: 50,
        star: 3,
        colorStar: "#95b0db",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <textarea id=0 rows=5></textarea>
        `,
        initialValue: ["Hey, it's Derin, isn't it?"],
        answer: [
          "Can you tell me why to consider studying abroad for a term?",
          "Do you know who to approach for guidance on writing a good essay?",
          "Can you recommend where to celebrate our year-end party next week?",
          "Can you tell me who to ask for permission before using the hall?",
        ],
      },
    ],
  },
};

export default json;
