import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P14-E1",
    exerciseKey: "img/FriendsPlus/Page14/Key/E1answerKey.png",
    // audio: "Audios/1.03.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 40,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "decided",
          "didn’t stay",
          "’d left",
          "hadn’t seen",
          "wasn’t enjoying",
          "<s>were driving</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the words or sentences about
a school trip to an art gallery.`,
        color: "#0067b4",
        left: 50,
        star: 1,
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "#0067b4",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: T6,
    questions: [
      {
        title: `
      <div style='display:flex'>
      <div style='position:relative;margin-right:20px'>
        <div style='position:absolute;top:62px;left:155px'><input border-bottom=none /></div>
        <div style='position:absolute;top:62px;left:205px'><input border-bottom=none /></div>
        <div style='position:absolute;top:62px;left:255px'><input border-bottom=none /></div>
        <div style='position:absolute;top:62px;left:305px'><input border-bottom=none /></div>
        <div style='position:absolute;top:112px;left:55px'><input border-bottom=none /></div>
        <div style='position:absolute;top:112px;left:105px'><input border-bottom=none /></div>
        <div style='position:absolute;top:112px;left:155px'><input border-bottom=none /></div>
        <div style='position:absolute;top:112px;left:205px'><input border-bottom=none /></div>
        <div style='position:absolute;top:112px;left:255px'><input border-bottom=none /></div>
        <div style='position:absolute;top:162px;left:5px'><input border-bottom=none /></div>
        <div style='position:absolute;top:162px;left:55px'><input border-bottom=none /></div>
        <div style='position:absolute;top:162px;left:105px'><input border-bottom=none /></div>
        <div style='position:absolute;top:162px;left:155px'><input border-bottom=none /></div>
        <div style='position:absolute;top:162px;left:205px'><input border-bottom=none /></div>
        <div style='position:absolute;top:162px;left:255px'><input border-bottom=none /></div>
        <div style='position:absolute;top:162px;left:305px'><input border-bottom=none /></div>
        <div style='position:absolute;top:162px;left:355px'><input border-bottom=none /></div>
        <div style='position:absolute;top:212px;left:105px'><input border-bottom=none /></div>
        <div style='position:absolute;top:212px;left:155px'><input border-bottom=none /></div>
        <div style='position:absolute;top:212px;left:205px'><input border-bottom=none /></div>
        <div style='position:absolute;top:212px;left:255px'><input border-bottom=none /></div>
        <div style='position:absolute;top:262px;left:55px'><input border-bottom=none /></div>
        <div style='position:absolute;top:262px;left:105px'><input border-bottom=none /></div>
        <div style='position:absolute;top:262px;left:155px'><input border-bottom=none /></div>
        <div style='position:absolute;top:262px;left:205px'><input border-bottom=none /></div>
        <div style='position:absolute;top:262px;left:255px'><input border-bottom=none /></div>
        <div style='position:absolute;top:262px;left:305px'><input border-bottom=none /></div>
        <div style='position:absolute;top:262px;left:355px'><input border-bottom=none /></div>
        <div style='position:absolute;top:262px;left:405px'><input border-bottom=none /></div>
        <div style='position:absolute;top:312px;left:155px'><input border-bottom=none /></div>
        <div style='position:absolute;top:312px;left:205px'><input border-bottom=none /></div>
        <div style='position:absolute;top:312px;left:255px'><input border-bottom=none /></div>
        <div style='position:absolute;top:312px;left:305px'><input border-bottom=none /></div>
        <div style='position:absolute;top:312px;left:355px'><input border-bottom=none /></div>
        <div style='position:absolute;top:362px;left:55px'><input border-bottom=none /></div>
        <div style='position:absolute;top:362px;left:105px'><input border-bottom=none /></div>
        <div style='position:absolute;top:362px;left:155px'><input border-bottom=none /></div>
        <div style='position:absolute;top:362px;left:205px'><input border-bottom=none /></div>
        
        <img src="img/FriendsPlus/Page14/E1/1.jpg" style='height:11cm'/>
      </div>
      <div style='width:11cm'>
          I often change my … at the last minute.<br>
          <b>1</b>&ensp;You need to … your options open.<br>
          <b>2</b>&ensp;I always … with my decisions. I don’t change my mind.<br>
          <b>3</b>&ensp;Do you … all the different options and choose things carefully?<br>
          <b>4</b>&ensp;We usually wait. We don’t … into things.<br>
          <b>5</b>&ensp;When I’m nervous or not sure what to say, I … before I speak.<br>
          <b>6</b>&ensp;We try not to make decisions. We … them!<br>
          <b>7</b>&ensp;I decide slowly. I always … my time.<br>
          Mystery word: <input width=200px /><br>
      </div>
      </div>

     
        `,
        // initialValue: ["", "", "1"],
        answer: [
          "k",
          "e",
          "e",
          "p",
          "s",
          "t",
          "i",
          "c",
          "k",
          "c",
          "o",
          "n",
          "s",
          "i",
          "d",
          "e",
          "r",
          "r",
          "u",
          "s",
          "h",
          "h",
          "e",
          "s",
          "i",
          "t",
          "a",
          "t",
          "e",
          "a",
          "v",
          "o",
          "i",
          "d",
          "t",
          "a",
          "k",
          "e",
          "decisive",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P14-E2",
    exerciseKey: "img/FriendsPlus/Page14/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct words in the dialogue.`,
        color: "#0067b4",
        width: "27cm",
        left: -50,
        star: 2,
        colorStar: "#95b0db",
      },
    ],
    styleHint: {
      borderColor: "#0067b4",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 200,
      paddingTop: 10,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#0067b4",
          border: "2px solid",
          borderColor: "#0067b4",
        },
        limitSelect: 1,
        listWords: [
          "hesitating / committing / avoiding",
          "options / mind / time",
          "into / on / off",
          "consider / think / try",
          "rush / get / stick",
          "on / off / with",
          "bother / change / keep",
          "into / off / out",
        ],
        answers: ["1-4", "2-8", "3-4", "4-0", "5-8", "6-0", "7-8"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
        <div>
          <b>Jay</b><br><br><br>
          <b>Lily</b><br>
          <b>Jay</b><br><br>
          <b>Lily</b><br><br><br><br>
          <b>Jay</b><br><br><br><br>
          <b>Lily</b>
        </div>
         <div style='margin-left:20px;width:20cm'>
              I need to plan my work experience, but I
            keep <span style="border: 2px solid rgb(0, 103, 180); font-weight: bold; border-radius: 50%;"><span>hesitating</span></span> / <span style="border: 2px solid white; font-weight: bold;"><span>committing</span></span> / <span style="border: 2px solid white; font-weight: bold;"><span>avoiding</span></span>.
            Do you think you could help me to make
            up my <sup>1</sup><input id=1 type=Circle />?
            <br>Sure! When do you need to decide by?
            <br>Tomorrow! I always put <sup>2</sup><input id=2 type=Circle />
            choosing things until the very last
            minute!
            <br>Well, it’s definitely a good idea to always
            <sup>3</sup><input id=3 type=Circle /> twice about your
            decisions, and not <sup>4</sup><input id=4 type=Circle /> into
            things, but at some point you need to
            decide and stick <sup>5</sup><input id=5 type=Circle /> your
            decision!
            <br>I know. My problem is that I often don’t
            <sup>6</sup><input id=6 type=Circle /> thinking about
            my options carefully and then I
            sometimes drop <sup>7</sup><input id=7 type=Circle /> of things
            because I’ve made a bad decision.
            <br>OK. Sit down and I’ll help you to decide!
         </div>
      </div>
      
      `,
    },
  },

  3: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P14-E3",
    exerciseKey: "img/FriendsPlus/Page14/Key/E3answerKey.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "2px dotted",
      borderBottom: "none",
      width: 800,
      padding: 0,
      paddingBottom: 5,
      fontSize: 23,
      paddingTop: 5,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    styleHint: {
      borderColor: "#0067b4",
      justifyContent: "center",
      width: "15cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#0067b4",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "changed",
          "<s>commit</s>",
          "dropped out",
          "keep your options",
          "made up my mind",
          "rush into",
          "stick with",
          "took my time",
          "try out",
        ],
        width: 600,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the forum posts with the phrases in the box.`,
        width: "27cm",
        color: "#0067b4",
        left: 50,
        star: 2,
        colorStar: "#95b0db",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
      <hintbox id=0></hintbox>

    <div style='position:relative'>
        <div style='position:absolute;top:444px;left:291px'><input font-size=22px width=188px /></div>
        <div style='position:absolute;top:478px;left:267px'><input font-size=22px width=188px /></div>
        <div style='position:absolute;top:513px;left:319px'><input font-size=22px width=188px /></div>
        <div style='position:absolute;top:547px;left:220px'><input font-size=22px width=188px /></div>
        <div style='position:absolute;top:582px;left:235px'><input font-size=22px width=188px /></div>
        <div style='position:absolute;top:686px;left:35px'><input font-size=22px width=153px /></div>
        <div style='position:absolute;top:686px;left:354px'><input font-size=22px width=188px /></div>
        <div style='position:absolute;top:720px;left:270px'><input font-size=22px width=188px /></div>
        
        <img src="img/FriendsPlus/Page14/E3/1.jpg" style='height:24cm'/>
      </div>
        `,
        answer: [
          "try out",
          "rush into",
          "took my time",
          "keep your options",
          "made up my mind",
          "changed",
          "dropped out",
          "stick with",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P14-E4",
    exerciseKey: "img/FriendsPlus/Page14/Key/E4answerKey.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    // isHello:true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 200,
      // marginLeft: 20,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
      marginBottom: -23,
      // marginLeft: 17,
      marginTop: -3,
    },
    styleHint: {
      borderColor: "#0067b4",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["finish", "go", "lose", "not eat", "not fly", "not see"],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Write about the situations using
the phrases on this page and your own ideas.
`,
        color: "#0067b4",
        width: "27cm",
        left: 50,
        star: 3,
        colorStar: "#95b0db",
      },
    ],
    // KeyMau: true,
    // isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='position:relative'>
        Describe a situation when …<br>
        you were very decisive.<br>
        <u style='text-decoration-style: dotted'>My music teacher asked me to sing in a concert last year.&emsp;</u>&emsp;<br>
        <u style='text-decoration-style: dotted'>I immediately made up my mind and said ‘yes’.&emsp;</u>&emsp;&emsp;<br>
        <b>1</b>&ensp;you were very indecisive.<textarea id=0 rows=2 ></textarea>
        <b>2</b>&ensp;you made a good decision.<textarea id=1 rows=2 ></textarea>
        <b>3</b>&ensp;you made a bad decision.<textarea id=2 rows=2 ></textarea>
       
      </div>
        `,
        answer: [
          "There was a school trip to see a play at the theatre. I put off making a decision about it for ages, but I went in the end.",
          "My friend asked me to join the new volleyball club. I hesitated at first, but then I joined the club and I really enjoyed it.",
          "I dropped out of a Saturday job last month and I regret it now. It's better to think twice and stick with things sometimes.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P14-E5",
    exerciseKey: "img/FriendsPlus/Page14/Key/E5answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 850,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    styleHint: {
      borderColor: "#0067b4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "brother(s) / sister(s)",
          "live in …",
          "name",
          "used to play / go / like",
        ],
        width: 600,
        borderColor: "#0067b4",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `You meet a friend who you haven’t seen
for a long time. Write four or five questions using
question tags. Use the phrases in the box and
exercise 4 for ideas.`,
        color: "#0067b4",
        left: 50,
        star: 3,
        colorStar: "#95b0db",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <textarea id=0 rows=5></textarea>
        `,
        initialValue: ["Hey, it's Derin, isn't it?"],
        answer: [
          "Can you tell me why to consider studying abroad for a term?",
          "Do you know who to approach for guidance on writing a good essay?",
          "Can you recommend where to celebrate our year-end party next week?",
          "Can you tell me who to ask for permission before using the hall?",
        ],
      },
    ],
  },
};

export default json;
