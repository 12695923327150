import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "LFP-Starter Unit 6",
    id: "SW9-2024-LF-U6-P66-E1",
    exerciseKey: "/img/FriendsPlus/Page66/Key/E1answerkey.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontWeight: "bold",
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "herself",
          "himself",
          "itself",
          "<s>myself</s>",
          "myself",
          "ourselves",
          "themselves",
          "yourself",
          "yourselves",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Complete the dialogues with the words in the box.`,
        left: 50,
        star: 0,
        color: "#4E3E94",
        colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>

‘Be very careful with that knife.’<br>
‘Don’t worry. I won’t cut <u style='text-decoration-style: dotted'>&emsp;myself&emsp;&emsp;</u>.’

        
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px'>
              ‘Is Alex an introvert or an extrovert?’<br>
              ‘I think he’s an introvert. He doesn’t express # very much.’
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px'>
              ‘Don’t touch those hot plates, Ann and Liza. You<br>
              might burn #.’  ‘OK.’
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px'>
              ‘Who are those new students?’<br>
              ‘They’re Dominic and Richard. They introduced # to me yesterday.’
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px'>
              ‘I’m sorry about breaking your camera.’<br>
              ‘Don’t blame #. It wasn’t your fault.’
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px'>
              ‘My sister fell over in the street yesterday.’  <br>
              ‘Oh no, did she hurt #?’
            </div>
          </div>
          <div style='display:flex'>
            <b>6</b> 
            <div style='margin-left:15px'>
              ‘Did you and your family have a good holiday<br>
              in Croatia?'  ‘Yes, thanks! We really enjoyed #.’
            </div>
          </div>
          <div style='display:flex'>
            <b>7</b> 
            <div style='margin-left:15px'>
              ‘What’s the matter with your cat?’<br>
              ‘It has a sore leg. It injured # last week.’
            </div>
          </div>
          <div style='display:flex'>
            <b>8</b> 
            <div style='margin-left:15px'>
              ‘Who taught you to play the cello?’<br>
              ‘Nobody did. I taught #.’
            </div>
          </div>
   
        `,
        answer: [
          "himself",
          "yourselves",
          "themselves",
          "yourself",
          "herself",
          "ourselves",
          "itself",
          "myself",
        ],
      },
    ],
  },
  2: {
    unit: "LFP-Starter Unit 6",
    id: "SW9-2024-LF-U6-P66-E2",
    exerciseKey: "/img/FriendsPlus/Page66/Key/E2answerkey.jpg",
    audio: "",
    video: "",
    component: Circle_Write,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 20,
      // fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct words.`,
        left: -30,
        width: "27cm",
        // star: 0,
        color: "#4E3E94",
        // colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          background: "none",
          fontSize: 24,
          // borderBottom: "none",
          borderBottom: "2px dotted",
          width: 200,
          height: 32,
          paddingTop: 2,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        // TickRong: true,
        initialWordStyle: {
          // marginLeft: 10,
          padding: "1px 3px",
          border: "2px solid",
          borderRadius: 5,
          // color: "transparent",
          borderColor: "white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 3px",
          // borderRadius: 5,
          borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: [
          "yourself / herself / themself",
          "himself / myself / yourself",
          "yourselves / themselves / ourselves",
          "myself / himself / yourself",
          "ourselves / themselves / itself",
          "yourselves / ourselves / themselves",
          "ourselves / himself / itself",
          "himself / herself / itself",
        ],
        answers: ["0-4", "1-8", "2-8", "3-0", "4-8", "5-8", "6-4", "7-4"],
        initialValue: ["0-4"],
      },
      Layout: `
 
      Marina is hard-working. She learned to speak French <input id=0 type=Circle />.
            
            <div style='line-height:45px;width:27cm'>
              <b>1</b>&ensp;Your painting is fantastic! Did you do it all <input id=1 type=Circle /> without any help?<br>
              <b>2</b>&ensp;‘Can I help you two in the kitchen?’ ‘No. We’ll do the cooking <input id=2 type=Circle />. We love it!’<br>
              <b>3</b>&ensp;I make notes about school work at home. I like writing things down <input id=3 type=Circle />.<br>
              <b>4</b>&ensp;That horse is clever! It opened the door <input id=4 type=Circle />!<br>
              <b>5</b>&ensp;‘Did you help your friends to write this?’ ‘No, they wrote the story <input id=5 type=Circle />.’<br>
              <b>6</b>&ensp;Ed Sheeran is very talented. He writes a lot of his songs <input id=6 type=Circle />.<br>
              <b>7</b>&ensp;My sister bought <input id=7 type=Circle /> a laptop to use at university.<br>
            </div>

  `,
    },
  },
  3: {
    unit: "LFP-Starter Unit 6",
    id: "SW9-2024-LF-U6-P66-E3",
    exerciseKey: "/img/FriendsPlus/Page66/Key/E3answerkey.jpg",
    audio: "",
    video: "",

    stylesTextInput: {
      // textAlign: "center",
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 20,
      // fontWeight: "bold",
      width: 700,
      paddingTop: 10,
      // marginLeft: 20,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Identify which are defining (D) and which are nondefining (ND) relative clauses.`,
        left: 50,
        star: 0,
        color: "#4E3E94",
        // colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 100,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["ND", "D"],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
<div style='display:flex'>
            <select id=0></select> <b>1</b> 
            <div style='margin-left:15px;width:23cm'>
              I have a friend who speaks 6 languages.
            </div>
          </div>
          <div style='display:flex'>
            <select id=1></select> <b>2</b> 
            <div style='margin-left:15px;width:23cm'>
              Greek poet Odysseus Elytis, who won the Nobel Prize in 1979, will be speaking at the conference.
            </div>
          </div>
          <div style='display:flex'>
            <select id=2></select> <b>3</b> 
            <div style='margin-left:15px;width:23cm'>
              Mount Everest, which is the highest peak of the Himalayan mountain range, is a popular expedition for mountain climbers from all over the world.
            </div>
          </div>
          <div style='display:flex'>
            <select id=3></select> <b>4</b> 
            <div style='margin-left:15px;width:23cm'>
              This work requires the computer that has a fast processor.
            </div>
          </div>
          <div style='display:flex'>
            <select id=4></select> <b>5</b> 
            <div style='margin-left:15px;width:23cm'>
              Pablo Picasso, who served as co-founder of the Cubist movement, was one of the most influential artists of the 20th century.
            </div>
          </div>
            
      
        
        `,
        answer: ["D", "ND", "ND", "D", "ND"],
      },
    ],
  },
  4: {
    unit: "LFP-Starter Unit 6",
    id: "SW9-2024-LF-U6-P66-E2",
    exerciseKey: "img/FriendsPlus/Page66/Key/E4answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with <i>who, which, where, whose</i> or <i>who’s</i>.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#4D3C98",
      justifyContent: "center",
      width: "11cm",
      marginBottom: 10,
      marginLeft: 200,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>help</s>",
          "not like",
          "live",
          "phone",
          "plan",
          "start",
          "not watch",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      // marginLeft: 20,
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 200,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,

    questions: [
      {
        title: `

        Malta is an island  <u style='text-decoration-style: dotted'>&emsp;which&emsp;</u> has lovely beaches.
        <div style='width:24cm'>
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px'>
              A pessimist is a person # thinks that bad things are going to happen.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px'>
              Is that the boy # father is an inventor?
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px'>
              Here’s the British Museum # you can see that new exhibition.
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px'>
              My brother is an introvert # quite shy.
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px'>
              Are you interested in books # have challenging ideas?
            </div>
          </div>
          <div style='display:flex'>
            <b>6</b> 
            <div style='margin-left:15px'>
              She’s the director # film won an Oscar.
            </div>
          </div>
          <div style='display:flex'>
            <b>7</b> 
            <div style='margin-left:15px'>
              Antalya is a city # has a lot of unusual shops.
            </div>
          </div>
          <div style='display:flex'>
            <b>8</b> 
            <div style='margin-left:15px'>
              Have you been to that small market # you can buy unusual food?
            </div>
          </div>
          </div>
        
        `,
        answer: [
          "who",
          "whose",
          "where",
          "who's|who is",
          "which",
          "whose",
          "which",
          "where",
        ],
      },
    ],
  },
  5: {
    unit: "LFP-Starter Unit 6",
    id: "SW9-2024-LF-U6-P66-E2",
    exerciseKey: "img/FriendsPlus/Page66/Key/E5answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "5",
        title: `Order the words to make sentences`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      marginLeft: 140,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "doesn’t have to",
          "don’t have to have to",
          "must",
          "mustn’t",
          "should",
          "shouldn’t",
        ],
      },
    ],
    textareaStyle: {
      width: 700,
      marginBottom: -24,
      resize: "none",
      paddingTop: 3,
    },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 700,

      paddingTop: 10,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        because / don’t / They / rules / obey / rebels / they’re<br>
        <u style='text-decoration-style: dotted'>They don’t obey rules because they’re rebels.&emsp;&emsp;&emsp;&emsp;</u>
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px;width:22cm'>
              introverts, / socialising / We’re / but / like / we
              <br>#
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px;width:22cm'>
              studied / Megan / medicine / that / she / so / become / doctor / a / could
              <br>#
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px;width:22cm'>
              understand / I / didn’t / asked / I / so / teacher / my
              <br>#
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px;width:22cm'>
              hungry / eaten / He’s / hasn’t / because / he
              <br>#
            </div>
          </div>
          
          
   

        `,
        answer: [
          "We're introverts, but we like socialising.",
          "Megan studied medicine so that she could become a doctor.",
          "I didn't understand so I asked my teacher.",
          "He's hungry because he hasn't eaten.",
        ],
      },
    ],
  },
  6: {
    unit: "LFP-Starter Unit 6",
    id: "SW9-2024-LF-U6-P66-E6",
    exerciseKey: "img/FriendsPlus/Page66/Key/E5answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "6",
        title: `The bold words are in the wrong place in the
sentences. Correct the sentences.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      marginLeft: 20,
      width: 650,
      paddingTop: 10,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
   
        These products are useful and attractive <span style='text-decoration:line-through'>both</span> .<br>
        <u style='text-decoration-style: dotted'>These products are both useful and attractive.&emsp;&emsp;</u><br>
        <b>1</b>&ensp;This car isn’t only great but convenient <b>also</b>.<br>#<br>
        <b>2</b>&ensp;I bought a new coat a pair of jeans <b>as well as</b>.<br>#<br>
        <b>3</b>&ensp;This jacket is available <b>both</b> in blue and red.<br>#<br>
        <b>4</b>&ensp;I went to the shops and played <b>too</b> football.<br>#<br>
        <b>5</b>&ensp;This hat is not cheap <b>only</b> but also practical.<br>#<br>
        <b>6</b>&ensp;She often buys <b>as well</b> shoes <b>as</b> bags.<br>#<br>
        
        
        `,
        answer: [
          "This car isn't only great but also convenient.",
          "I bought a new coat as well as a pair of jeans.",
          "This jacket is available in both blue and red.",
          "I went to the shops and played football, too.",
          "This hat is not only cheap but also practical.",
          "She often buys shoes as well as bags.",
        ],
      },
    ],
  },
};

export default json;
