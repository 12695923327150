import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P33-E1",
    exerciseKey: "/img/FriendsPlus/Page33/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 127,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["do", "<s>explore</s>", "find", "live", "look", "stop"],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the table with the gerund or
          infinitive form of the verbs.
`,
        left: 50,
        star: 1,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page33/9.jpg' style='width:600px' />

          <div style='position:absolute;top:163px;left:302px'> <input id=0 />  </div>
          <div style='position:absolute;top:202px;left:368px'> <input id=1 />  </div>
          <div style='position:absolute;top:371px;left:311px'> <input id=2 />  </div>
          <div style='position:absolute;top:475px;left:204px'> <input id=3 />  </div>
          <div style='position:absolute;top:580px;left:204px'> <input id=4 />  </div>
          



        </div>
      
        
        `,
        answer: ["stopping", "living", "to look", "to find", "to do"],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P33-E2",
    exerciseKey: "/img/FriendsPlus/Page33/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 127,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "buy",
          "<s>create</s>",
          "eat",
          "listen",
          "live",
          "start",
          "test",
          "visit",
          "watch",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Complete the sentences with the gerund
          or infinitive form of the verbs
`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;'>
          <p>
          <span style='border-bottom:2px dotted black;font-weight:bold'>Creating</span>
          a new invention often takes a long time.
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            <span>
            <input id=0 ></input>
             the Royal Observatory in Greenwich,
              London, is a good way to learn about the stars.

            </span> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span>
              It’s important
              <input id=1 ></input>
              all new products carefully in a design laboratory.
            </span> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              Daniel turned on the TV
              <input id=2 ></input>
              a documentary about new technology.
            </span> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
              <input id=3 ></input>
              food with a lot of sugar and fat can be bad for you, according to scientific studies.
            </span> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
            My sister intends
              <input id=4 ></input>
              an engineering course at college this September.
            </span> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">6</span>
            <span>
              Can you imagine
              <input id=5 ></input>
              on the moon?
            </span> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">7</span>
            <span>
              I’m interested in
              <input id=6 ></input>
              that new underwater camera, but it’s expensive.
            </span> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">8</span>
            <span>
              Are you planning
              <input id=7 ></input>
              to that podcast about designing and inventing products?
            </span> 
          </div>
          

        </div>
      
        
        `,
        answer: [
          "Visiting",
          "to test",
          "to watch",
          "Eating",
          "to start",
          "living",
          "buying",
          "to listen",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P33-E3",
    exerciseKey: "/img/FriendsPlus/Page33/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 127,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "buy",
          "<s>create</s>",
          "eat",
          "listen",
          "live",
          "start",
          "test",
          "visit",
          "watch",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Complete the product review with the
        gerund or infinitive form of the verbs in brackets.
`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page33/10.jpg' style='width:600px;margin-left:200px' />
          <div style='position:absolute;top:171px;left:444px'> <input id=0 />  </div>
          <div style='position:absolute;top:202px;left:488px'> <input id=1 />  </div>
          <div style='position:absolute;top:263px;left:498px'> <input id=2 />  </div>
          <div style='position:absolute;top:429px;left:446px'> <input id=3 />  </div>
          <div style='position:absolute;top:461px;left:293px'> <input id=4 />  </div>
          <div style='position:absolute;top:565px;left:413px'> <input id=5 />  </div>
          <div style='position:absolute;top:595px;left:501px'> <input id=6 />  </div>
          <div style='position:absolute;top:627px;left:437px'> <input id=7 />  </div>
          <div style='position:absolute;top:685px;left:519px'> <input id=8 />  </div>
         
        </div>
      
        
        `,
        answer: [
          "to know",
          "kicking",
          "to keep",
          "to create",
          "to help",
          "to use",
          "producing",
          "Using",
          "scoring",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P33-E4",
    exerciseKey: "/img/FriendsPlus/Page33/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 500,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "buy",
          "<s>create</s>",
          "eat",
          "listen",
          "live",
          "start",
          "test",
          "visit",
          "watch",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: ` Imagine you want to get a job in science,
            technology or medicine. Complete the text with
            the ideas in the box, or your own, and the gerund or
            infinitive form of the verbs
`,
        left: 50,
        star: 3,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    character: ",",
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
            <img src='/img/FriendsPlus/Page33/11.jpg' style='width:600px;margin-left:200px;margin-bottom:50px' /> <br>
            One day, I’d like <sup>1</sup> <input id=0 /> <br>
            because I enjoy <sup>2</sup> <input id=1 /> <br>
            and I want <sup>3</sup> <input id=2 /> <br>
            After <sup>4</sup> <input id=3 /> <br>
            I plan <sup>5</sup> <input id=4 /> <br>
            I can imagine <sup>6</sup> <input id=5 /> <br>
            and also <sup>7</sup> <input id=6 /> <br>
        </div>
      
        
        `,
        answer: [
          "to be a sound engineer",
          "using the latest technology",
          "to work with musicians",
          "studying sound engineering at university",
          "to find a job",
          "meeting famous people",
          "creating fantastic music",
        ],
      },
    ],
  },
};

export default json;
