import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit3",
    id: "SW9-2024-U2-P24-E1",
    exerciseKey: "/img/FriendsPlus/Page24/key/e1-key.png",
    audio: "/Audios/1.08.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=08 colorImg='#f0ab9c'></headphone>Read and listen to the email
newsletter and choose the correct words in the
summary.`,
        left: -50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 20,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "is / isn’t",
          "retailers / consumers",
          "warn&nbsppeople / encourage&nbspspending",
          "colour / position",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: `
       <div style='margin-top:20px;position:relative;width:850px'>

          <div style="background-color:rgb(250,232,224);padding:10px 20px;margin-bottom:40px">
          The writer of this newsletter <sup><b>1</b></sup>
            <input id=0 type=Circle /> an expert
            on shopping. He’s on the side of the <sup><b>2</b></sup> <input id=1 type=Circle /> and his main aim is to <sup><b>3</b></sup>
            <input id=2 type=Circle />. In the newsletter, he writes
            about different aspects of shopping including the
            <sup><b>4</b></sup> <input id=3 type=Circle /> of products in shops
          </div>
        <img src='/img/FriendsPlus/Page24/9.jpg' style='height:40cm'/>
       </div>
      `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P24-E2",
    exerciseKey: "/img/FriendsPlus/Page24/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 100,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Read the email newsletter again and write
true or false.
`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>Consumers are learning how shops make us spend money
          <span style='border-bottom:2px dotted black;font-weight:bold'>true</span>
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            <span>
              Shoppers need to look up or down to find things
              for a good price
            </span> 
            <input id=0 ></input>.
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span>
              Consumers always find good deals at the end of
              shop aisles
            </span> 
            <input id=1 ></input>.
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              Clothes shops use the same techniques as other shops
            </span> 
            <input id=2 ></input>.
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
              The writer thinks that learning how to control what you buy is very important
            </span> 
            <input id=3 ></input>.
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
              The next newsletter will explain why online shopping is cheaper
            </span> 
            <input id=4 ></input>.
          </div>
          <div style='margin-top:30px'></div>
          <img src='/img/FriendsPlus/Page24/9.jpg' style='width:600px'/>
          
        </div>
      
        
        `,
        answer: ["true", "false", "false", "true", "false"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P24-E3",
    exerciseKey: "/img/FriendsPlus/Page24/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Answer the questions. Write complete
sentences.
`,
        left: 50,
        star: 3,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>Do shops only use traditional techniques to make
          consumers buy things? <br>
          <span style='border-bottom:2px dotted black;font-weight:bold'>No, they’re always thinking of new ones.</span>
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            <span>
              Why does the writer know the sales techniques? 
            </span> 
            <textarea rows=2 id=0 ></textarea>  
          </div>
          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span>
              Why do shops want you to buy luxury brands? 
            </span> 
            <textarea rows=2 id=1 ></textarea>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              Are ‘three for the price of two’ offers always a
              good idea? Why / Why not? 
            </span> 
            <textarea rows=2 id=2 ></textarea>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
              What do shop assistants do to the clothes in some shops? Why? 

            </span> 
            <textarea rows=2 id=3 ></textarea>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
              What does the writer think you should do before
              you start shopping? Why? 
            </span> 
            <textarea rows=2 id=4 ></textarea>.
          </div>

          
        </div>
      
        
        `,
        answer: [
          "Because he has experience as a marketing manager for a department store.",
          "Because they're more expensive.|Because they are more expensive.",
          "No. You often don't save much money.|No. You often do not save much money.",
          "They make the clothes untidy so people think that everybody has been looking at them.",
          "He thinks you should plan what to buy so that you're not a victim of sales techniques|He thinks you should plan what to buy so that you are not a victim of sales techniques",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P24-E4",
    exerciseKey: "/img/FriendsPlus/Page24/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 230,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `VOCABULARY PLUS Find and match the
compound nouns in the newsletter with meanings
1—5.
`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>a regular report with information
          <span style='border-bottom:2px dotted black;font-weight:bold'>newsletter</span>
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            <span>
              a large shop that sells a lot of different products and brands

            </span> 
            <input id=0 ></input>  
          </div>
          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span>
              a thing for carrying the products you want to buy when you’re in a shop

            </span> 
            <input id=1 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              the duration of a person’s life 
            </span> 
            <input id=2 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
              a large place with a lot of different retailers 

            </span> 
            <input id=3 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
              the different ways that shops encourage you to buy things
            </span> 
            <input id=4 ></input>
          </div>

          
        </div>
      
        
        `,
        answer: [
          "department store",
          "shopping basket",
          "lifetime",
          "shopping centre",
          "sales techniques",
        ],
      },
    ],
  },
};

export default json;
