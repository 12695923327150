import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "LFP-Starter Unit 7",
    id: "SW9-2024-LF-U7-P67-E1",
    exerciseKey: "/img/FriendsPlus/Page67/Key/E1answerkey.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontWeight: "bold",
      width: 100,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "herself",
          "himself",
          "itself",
          "<s>myself</s>",
          "myself",
          "ourselves",
          "themselves",
          "yourself",
          "yourselves",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with so or such.`,
        left: 50,
        star: 0,
        color: "#4E3E94",
        colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    questions: [
      {
        title: `
        The cake was <u style='text-decoration-style: dotted'>&emsp;so&emsp;</u> delicious that everyone wanted
a second serving.
        
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px'>
              The weather was # beautiful that we decided to have a picnic in the park.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px'>
              It was # a challenging puzzle that even the experienced players struggled to solve it.
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px'>
              The news was # shocking that it left everyone in the room speechless.
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px'>
              She is # a skilled artist that her paintings always receive praise and admiration.
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px'>
              The mountain was # high that it took us hours to reach the summit.
            </div>
          </div>
          
   
        `,
        answer: ["so", "such", "so", "such", "so"],
      },
    ],
  },
  2: {
    unit: "LFP-Starter Unit 7",
    id: "SW9-2024-LF-U7-P67-E2",
    exerciseKey: "/img/FriendsPlus/Page67/Key/E2answerkey.jpg",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 20,
      // fontWeight: "bold",
      width: 800,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Rewrite the sentences in exercise 1 beginning with the words given.`,
        left: 50,
        width: "27cm",
        // star: 0,
        color: "#4E3E94",
        // colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
        
            The cake was so delicious that everyone wanted a 
            second serving.<br>
           It was  <u style='text-decoration-style: dotted'>such a delicious cake that everyone wanted a 
            second serving&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</u>.
            <br>
            <b>1</b>&ensp;It was<input width=936px />.<br>
            <b>2</b>&ensp;The puzzle was<input width=832px />.<br>
            <b>3</b>&ensp;It was<input width=936px />.<br>
            <b>4</b>&ensp;The artist is<input width=871px />.<br>
            <b>5</b>&ensp;It was<input width=936px />.<br>
        </div>
            
      
        
        `,
        answer: [
          "such beautiful weather that we decided to have a picnic in the park.",
          "so challenging that even the experienced players struggled to solve it.",
          "such shocking news that it left everyone in the room speechless.",
          "so skilled that her paintings always receive praise and admiration.",
          "such a high mountain that it took us hours to reach the summit.",
        ],
      },
    ],
  },
  3: {
    unit: "LFP-Starter Unit 7",
    id: "SW9-2024-LF-U7-P67-E3",
    exerciseKey: "/img/FriendsPlus/Page67/Key/E3answerkey.jpg",
    audio: "",
    video: "",

    stylesTextInput: {
      textAlign: "center",
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 20,
      // fontWeight: "bold",
      width: 250,
      paddingTop: 10,
      // marginLeft: 20,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the passive sentences with the
affirmative or negative of <i>be</i>.`,
        left: 50,
        star: 0,
        color: "#4E3E94",
        // colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 100,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["ND", "D"],
    component: T6,
    // hideBtnFooter: true,
    character: "/",
    questions: [
      {
        title: `
        This post office <u style='text-decoration-style: dotted'>&emsp;was&emsp;</u> opened in 1875.<br>
        <b>1</b>&ensp;If you look inside the book, it # written in German. It’s in English.<br>
        <b>2</b>&ensp;That castle # restored next year. It’ll look absolutely fantastic.<br>
        <b>3</b>&ensp;Those souvenirs # sold in the gift shop. You need to buy them online.<br>
        <b>4</b>&ensp;The paintings # discovered last year. It was two years ago.<br>
        <b>5</b>&ensp;I think that the ancient statue might # repaired soon.<br>
        <b>6</b>&ensp;The exhibition # opened next week.<br>

            
      
        
        `,
        answer: [
          "is not / isn't",
          "will be",
          "are not / aren't",
          "were not / weren't",
          "be",
          "will be / won't be",
        ],
      },
    ],
  },
  4: {
    unit: "LFP-Starter Unit 7",
    id: "SW9-2024-LF-U7-P67-E2",
    exerciseKey: "img/FriendsPlus/Page67/Key/E4answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the present, past or future passive form of the verbs in the box.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#4D3C98",
      justifyContent: "center",
      width: "11cm",
      marginBottom: 10,
      marginLeft: 200,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "discover",
          "give",
          "open",
          "restore",
          "sell",
          "<s>unearth</s>",
          "write",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      // marginLeft: 20,
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 200,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,

    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        

        The ancient jewellery <u style='text-decoration-style: dotted'>&emsp;was unearthed&emsp;</u> in an old garden not far from Ephesus.
        <div style='width:24cm'>
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px'>
              The new football stadium # by the president in June next year.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px'>
              The body of the ancient Egyptian queen # in an old tomb in the 1920s.
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px'>
              These amazing books on archaeology # for £10 online now.
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px'>
              This old building # by experts last year and it looks beautiful again.
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px'>
              Free information # to all tourists when they arrive at the museum.
            </div>
          </div>
          <div style='display:flex'>
            <b>6</b> 
            <div style='margin-left:15px'>
              In the near future, all museum guide books # in three different languages.
            </div>
          </div>
          
          </div>
        
        `,
        answer: [
          "will be opened",
          "was discovered",
          "are sold",
          "was restored",
          "is given",
          "will be written",
        ],
      },
    ],
  },
  5: {
    unit: "LFP-Starter Unit 7",
    id: "SW9-2024-LF-U7-P67-E2",
    exerciseKey: "img/FriendsPlus/Page67/Key/E5answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "5",
        title: `Write passive questions. Use <i>it</i> or <i>they</i>.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      marginLeft: 140,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "doesn’t have to",
          "don’t have to have to",
          "must",
          "mustn’t",
          "should",
          "shouldn’t",
        ],
      },
    ],
    textareaStyle: {
      width: 700,
      marginBottom: -24,
      resize: "none",
      paddingTop: 3,
    },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 700,

      paddingTop: 10,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        That Roman villa wasn’t discovered last year.<br>
        When <u style='text-decoration-style: dotted'>&emsp;was it discovered&emsp;</u>?        
        
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px;'>
              The museum won’t be built in the city centre.<br>
              Where <input width=400px />?
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px;'>
              These small lemons aren’t grown in Turkey.<br>
              Where <input width=398px />?
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px;'>
              This old house wasn’t destroyed by fire.<br>
              How <input width=420px />?
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px;'>
              The purse hasn’t been stolen for the money.<br>
              Why <input width=421px />?
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px;'>
              This museum isn’t cleaned every day.<br>
              When <input width=403px />?
            </div>
          </div>
          
          
   

        `,
        answer: [
          "will it be built",
          "are they grown",
          "was it destroyed",
          "has it been stolen",
          "is it cleaned",
        ],
      },
    ],
  },
  6: {
    unit: "LFP-Starter Unit 7",
    id: "SW9-2024-LF-U7-P67-E6",
    exerciseKey: "img/FriendsPlus/Page67/Key/E6answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "6",
        title: `Correct the words in bold.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      // marginLeft: 20,
      width: 150,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `

        If you walk in a circle <span style='text-decoration:line-through'>across</span> the building, you’ll see the beautiful gardens. <u style='text-decoration-style: dotted'>&emsp;around&emsp;</u><br>
        <b>1</b>&ensp;Open the box and look <b>here</b>. You’ll find an old ring there. #<br>
        <b>2</b>&ensp;The monument is just <b>left</b> the road. It’s directly on the other side from here. #<br>
        <b>3</b>&ensp;If you look <b>down</b> , you can see a tall tower above you. #<br>
        <b>4</b>&ensp;Walk into that room at the end of the long corridor. The coins are displayed <b>here</b>. #<br>
        
        
        
        `,
        answer: ["inside", "across", "up", "there"],
      },
    ],
  },
};

export default json;
