import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P19-E1",
    exerciseKey: "/img/FriendsPlus/Page19/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 40,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match 1–6 with a–f.`,
        left: 50,
        star: 1,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='display:flex'>
            <div style ='width:565px;'>
                <b>1</b>&ensp;Although we chose an interesting question  <u style='text-decoration-style: dotted'>&ensp;b&ensp;</u><br>
                <b>2</b>&ensp;The majority agreed with the idea. However, #<br>
                <b>3</b>&ensp;Our teacher was in favour. In contrast, the #<br>
                <b>4</b>&ensp;Those in favour made clear points, whereas #<br>
                <b>5</b>&ensp;Two people weren’t sure. However #<br>
                <b>6</b>&ensp;The survey was quick to do, although #<br>
            </div>
            <div style='margin-left: 100px'>
                <b>a</b>&ensp;those against didn’t make any comments<br>
                <b>b</b>&ensp;some people didn’t understand it.<br>
                <b>c</b>&ensp;c it took a long time to write up the results.<br>
                <b>d</b>&ensp;a minority completely disagreed.<br>
                <b>e</b>&ensp;students in the class were against.<br>
                <b>f</b>&ensp;the rest of the class had very strong opinions.<br>
            </div>
        </div>
      
        
        `,
        answer: ["d", "e", "a", "f", "c"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P18-E2",
    exerciseKey: "/img/FriendsPlus/Page19/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 700,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Order the words to complete the sentences.`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style ="margin-top:30px">
          <span>Most of us liked participating in the survey, (Ellie and Anthony / whereas / it / enjoy / didn’t)</span> <br>
           <span style ='border-bottom-style: dotted'><b><i>whereas Ellie and Anthony didn’t enjoy it.</i></b></span>
        <div style='margin-bottom:20px'></div>


          <div style ="display:flex">
             <div style='width:30px'><b>1</b></div>
             <span> Ten people said ‘yes’. (people / three / sure / weren’t / however)</span>
          </div>
          <div style="margin-bottom:30px"><input id=0/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>2</b></div>
             <span> I agreed with the question. (of / in / majority / disagreed / the / contrast, / people)</span>
          </div>
          <div style="margin-bottom:30px"><input id=1/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>3</b></div>
             <span> Only one student was completely undecided, (didn’t / explain / he / although / why)</span>
          </div>
          <div style="margin-bottom:30px"><input id=2/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>4</b></div>
             <span> Some people thought the results were surprising, (surprised / wasn’t / whereas / I)</span>
          </div>
          <div style="margin-bottom:30px"><input id=3/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>5</b></div>
             <span> Most students made up their mind quickly. (contrast / in / people / two / slowly / decided)</span>
          </div>
          <div style="margin-bottom:30px"><input id=4/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>6</b></div>
             <span> We did an interesting survey last year, (question / we / a / although / asked / different)</span>
          </div>
          <div style="margin-bottom:30px"><input id=5/></div>

         
        </div>
       
        `,
        answer: [
          "However, three people weren't sure",
          "In contrast, the majority of people disagreed.",
          "although he didn't explain why.",
          "whereas I wasn't surprised.",
          "In contrast, two people decided slowly.",
          "although we asked a different question.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P19-E3",
    exerciseKey: "/img/FriendsPlus/Page19/key/e3-key.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "3",
        title: `Replace the bold words in the report with
the phrases in the box. Note that in most countries
you have to be eighteen to drive, but in the UK and a
few other countries, such as Australia, it's seventeen.`,
        width: "27cm",
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      marginLeft: "250px",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "against",
          "all in al",
          "indicate",
          "in favour",
          "the majority of",
          "<s>participated</s>",
          "said",
          "side",
          "think",
          "undecided",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "2px solid",
      // borderBottom: "none",
      width: 190,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:400px; margin-left: 200px;margin-top: 50px; '>
          <img src='/img/FriendsPlus/Page19/9.jpg'style='width:600px'/>
          <div style='margin-top: 50px;'></div>
         
        </div>

        <div style="display:flex; justify-content:space-between; margin-left: 200px; width:600px;">

            <div>
                <div style="width:190px;border-bottom:2px solid rgb(168, 168, 168); margin-left: 25px; text-align:center"> <span style=''>participated</span> </div>
                <div><b>1</b> <input id=0/></div>
                <div><b>2</b> <input id=1/></div>
                <div><b>3</b> <input id=2/></div>
                <div><b>4</b> <input id=3/></div>
             </div>
             
            <div>
              <div><b>5</b> <input id=4/></div>
              <div><b>6</b> <input id=5/></div>
              <div><b>7</b> <input id=6/></div>
              <div><b>8</b> <input id=7/></div>
              <div><b>9</b> <input id=8/></div>
            </div>
         </div>
        
        `,
        answer: [
          "undecided",
          "in favour",
          "against",
          "side",
          "think",
          "said",
          "All in all",
          "indicate",
          "The majority of",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P19-E4",
    exerciseKey: "",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "4",
        title: ` Write a report on the survey results below.
Use the report in exercise 3 to help you.
Is eighteen the right age to leave school?`,
        width: "27cm",
        left: 50,
        star: 3,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      marginLeft: "250px",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "against",
          "all in al",
          "indicate",
          "in favour",
          "the majority of",
          "<s>participated</s>",
          "said",
          "side",
          "think",
          "undecided",
        ],
      },
    ],
    textareaStyle: {
      width: 860,
      resize: "none",
      paddingTop: 3,
      marginTop: 30,
    },
    stylesTextInput: {
      background: "none",
      // borderBottom: "2px solid",
      // borderBottom: "none",
      width: 190,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div style='border:1px solid rgb(0,170,76);margin-top:30px'>
          <div style='display:flex;background-color:rgb(160,212,174);color:white;border-bottom:1px solid rgb(0,170,76)'>
            <div style='width:140px;border-right:1px solid rgb(0,170,76);margin-left:10px '>Results</div>
            <div style='margin-left:10px'>Comments
            </div>
          </div>

          <div style='display:flex; border-bottom:1px solid rgb(0,170,76)'>
            <div style='width:140px;border-right:1px solid rgb(0,170,76);margin-left:10px '>Yes: 30%</div>
            
            <div style='margin-left:10px;width:700px'>‘Young people who stay in school have more  time to choose a career.’ ‘They can improve their languages, maths and IT skills.
            </div>
          </div>

          <div style='display:flex; border-bottom:1px solid rgb(0,170,76)'>
            <div style='width:140px;border-right:1px solid rgb(0,170,76);margin-left:10px '>No: 60%</div>
            
            <div style='margin-left:10px;width:700px'>‘A lot of young people want to start working
            and earn money earlier.’ ‘Some young people aren’t keen on academic subjects.’
            </div>
          </div>

          <div style='display:flex;'>
            <div style='width:140px;border-right:1px solid rgb(0,170,76);margin-left:10px '>Undecided:
10%</div>
            
            <div style='margin-left:10px;width:700px'>‘It’s difficult to say! Some students get more
            out of school than others.’
            </div>
          </div>
        
        </div>
        <textarea id=0 rows="10"></textarea>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
