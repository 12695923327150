import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P41-E1",
    exerciseKey: "/img/FriendsPlus/Page41/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      // fontSize: 22,
      width: 180,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["in", "that (x2)", "where", "which", "<s>who</s>", "whose"],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `   Complete the table with the words.

`,
        left: 50,
        star: 1,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-left:100px'> <hintbox id=0 ></hintbox>  </div>
        
           <div style='margin-top:20px;position:relative'>
              <img src='/img/FriendsPlus/Page41/11.jpg' style='width:600px;margin-left:100px' />
              <div style='position:absolute;top:94px;left:402px'> <input id=0 /> </div>
              <div style='position:absolute;top:166px;left:426px'> <input id=1 /> </div>
              <div style='position:absolute;top:274px;left:275px'> <input id=2 /> </div>
              <div style='position:absolute;top:274px;left:412px'> <input id=3 /> </div>
              <div style='position:absolute;top:345px;left:416px'> <input id=4 /> </div>
              <div style='position:absolute;top:454px;left:593px'> <input id=5 /> </div>
              

        </div>
      
        `,
        answer: ["that", "where", "which", "that", "whose", "in"],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P41-E2",
    exerciseKey: "/img/FriendsPlus/Page41/key/e2-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Choose the correct words.
`,
        left: -50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "which / who / whose",
          "where / which / that",
          "which / whose / who’s",
          "that / where / who",
          "which / who / where",
          "who’s / whose / who",
          "who / whose / that",
          "which / where / who",
        ],
        answers: ["0-4", "1-0", "2-8", "3-4", "4-0", "5-4", "6-8", "7-0"],
        initialValue: [],
      },
      Layout: `
        
           <div style='margin-top:20px;position:relative'>
           <div style='border:2px solid black; width:600px'>
              <div style='background-color:rgb(129, 130, 134); color:white;font-size:16px; padding-left:10px'>
                <span>Top ten holidays for teenagers</span>
              </div>
              <span style='font-weight:bold;font-size:18px;padding-left:10px'>1 Scandinavian adventure – Norway</span>
              <div style='border:1px solid black'></div>

              <p style='padding:0 20px 0 10px'>
                  This is an unusual experience
                  <span style='font-weight:bold'> <span style='border:1px solid;border-radius:50%'>that</span> / where / who </span>
                  is perfect for independent teenagers <sup>1</sup> 
                  <input id=0 type=Circle />
                  are looking for something different.
                  On most summer programmes, you have a campsite  <sup>2</sup>
                  <input id=1 type=Circle />
                  you sleep every night. But on this holiday you’ll walk and cycle across Norway.
                  So you need to be someone <sup>3</sup>
                  <input id=2 type=Circle />
                  into exercise! The expedition is great for nature lovers because it covers beautiful forests
                  <sup>4</sup>
                    <input id=3 type=Circle />
                  you see amazing plants and birds.
              </p>

              <p style='padding:0 20px 0 10px; '>
                It’s a programme <sup>5</sup>
                <input id=4 type=Circle />
                started in 1987 and the leader is Karl Olsen,
                <sup>6</sup> <input id=5 type=Circle />
                father created the very first expedition. So he has lots of experience
                <sup>7</sup> <input id=6 type=Circle />
                will help you! This unique expedition is challenging, but it’s a very rewarding trip
                <sup>8</sup> <input id=7 type=Circle />
                 you’ll remember for the rest of your life!
              </p>
           
           </div>
              

        </div>
      `,
    },
  },
  3: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P41-E3",
    exerciseKey: "/img/FriendsPlus/Page41/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 60,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["in", "that (x2)", "where", "which", "<s>who</s>", "whose"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `    Read the sentences and identify which
              ones are defining (D) and which are non-defining
              (ND) relative clauses

`,
        left: 50,
        star: 1,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        
         <div style='margin-top:20px;'>
          Is this the trip which you’re really interested in?
          <span style='border-bottom:2px dotted;'><b>D</b></span>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span> That’s the camp leader who I often sit next to </span>
            <input id=0 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span> Is Đà Nẵng the place where Hoa moved to? </span>
            <input id=1 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span> Mrs Johnson, whose son is a famous actor, is
            hosting a charity event next week </span>
            <input id=2 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span> Sailing is the thing which I’m very afraid of </span>
            <input id=3 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span> The newcomer, who just joined our class, is quite
            shy </span>
            <input id=4 /> 
          </div>
          
        </div>
      
        `,
        answer: ["D", "D", "ND", "D", "ND"],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P41-E4",
    exerciseKey: "/img/FriendsPlus/Page41/key/e4-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  Underline and correct the mistakes.
`,
        left: -50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    character: "/",
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 220,
        },
        answers: ["Marie Curie, who", "whose", "who", "who", "which"],
        initialValue: [],
      },

      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          textDecoration: "underline",

          // color: "#00aeef",
          // border: "2px solid",
          // borderColor: "#00aeef",
        },
        limitSelect: 3,
        listWords: [
          "Marie | Curie | that | made | remarkable | discoveries | in | the | field | of | radioactivity, was | a |pioneering | scientist",
          "If | you’re | a | teenager | who’s | interests | are | sailing | and | surfing |, you’ll | enjoy | the |course.",
          "Yesterday | I | met | my | new | boss |, | that | was | very | nice",
          "Mr | Fielding | is | a | teacher | whose | went | on | the | school | expedition | last | year",
          "February | when | is | my | favourite | month |, | lasts | 29 | days | this | year",
        ],
        answers: ["0-0", "0-6", "0-12", "1-24", "2-40", "3-30", "4-6"],
        initialValue: [],
      },
      Layout: `
        
           <div style='margin-top:20px;position:relative'>
           Do you know the man <span style='text-decoration:underline'>who’s</span> son is an activity
           leader? <span style='border-bottom:1px solid;font-weight:bold'>whose</span>
           <br> <br>
           <b>1</b> <input id=0 type=Circle />  <input id=0 /> <br><br>
           <b>2</b> <input id=1 type=Circle />  <input id=1 /> <br><br>
           <b>3</b> <input id=2 type=Circle />  <input id=2 /> <br><br>
           <b>4</b> <input id=3 type=Circle />  <input id=3 /> <br><br>
           <b>5</b> <input id=4 type=Circle />  <input id=4 /> <br><br>
              

          </div>
      `,
    },
  },
  5: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P41-E5",
    exerciseKey: "/img/FriendsPlus/Page41/key/e5-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 500,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["in", "that (x2)", "where", "which", "<s>who</s>", "whose"],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: ` Complete the sentences using the relative
          pronouns who, which, whose and where and your
          own ideas.

`,
        left: 50,
        star: 3,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        
         <div style='margin-top:20px;'>
          Margarita is someone 
          <span style='border-bottom:2px dotted;'><b>who’s very adventurous and confident at sports.</b></span>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span> Climbing is an activity </span>
            <input id=0 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span> My best friend is someone </span>
            <input id=1 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span> Victoria Beckham is a celebrity </span>
            <input id=2 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span> A summer camp is a place </span>
            <input id=3 /> 
          </div>
          
        </div>
      
        `,
        answer: [
          "which is challenging and a bit dangerous",
          "who likes to be independent.",
          "whose husband is famous, too.",
          "where you can learn skills like teamwork",
        ],
      },
    ],
  },
};

export default json;
