import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P23-E1",
    exerciseKey: "/img/FriendsPlus/Page23/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      width: 200,
      fontSize: 20,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the table with the words.
`,
        left: 50,
        star: 1,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top:20px;position:relative;'>
          <img src='/img/FriendsPlus/Page23/11.jpg' style='width:600px'/>
          <div style='position:absolute;top:89px;left:125px'> <input id=0 /> </div>
          <div style='position:absolute;top:176px;left:63px'> <input id=1 /> </div>
          <div style='position:absolute;top:239px;left:40px'> <input id=2 /> </div>
          <div style='position:absolute;top:353px;left:68px'> <input id=3 /> </div>
          <div style='position:absolute;top:387px;left:151px'> <input id=4 /> </div>
          <div style='position:absolute;top:496px;left:41px'> <input id=5 /> </div>
          <div style='position:absolute;top:531px;left:67px'> <input id=6 /> </div>
          

        </div>
      
        
        `,
        answer: [
          "doesn't open",
          "'s renting",
          "'m not waiting",
          "'ll pay",
          "won't drive",
          "'m going to buy",
          "aren't going to shop",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P23-E2",
    exerciseKey: "/img/FriendsPlus/Page23/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 600,
      marginLeft: "18px",
      textAlign: "left",
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Your friend is going to a new shopping
centre. Order the words to make questions.
`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>you / time / What / are / going / go out / to / ? <br>
          <span style='border-bottom:2px dotted black;font-weight:bold'>What time are you going to go out?</span>
          </p>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">1</span>
            <span>
              leave / When / train / your / does / ?
            </span> <br>
            <input id=0 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">2</span>
            <span>
              you / meeting / Is / Leah / the / at / station / ?
            </span> <br>
            <input id=1 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">3</span>
            <span>
              the / Will / shopping centre / busy / be / ?
            </span> <br>
            <input id=2 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">4</span>
            <span>
              you / lunch / Where / going / are / have / to / ?
            </span> <br>
            <input id=3 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">5</span>
            <span>
              leave / time / flight / to / What / does / New York / their / ?
            </span> <br>
            <input id=4 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">6</span>
            <span>
              Leah / Is / going / buy / anything / to / ?
            </span> <br>
            <input id=5 ></input>
          </div>

          

        </div>
      
        
        `,
        answer: [
          "When does your train leave?",
          "Is Leah meeting you at the station?",
          "Will the shopping centre be busy?",
          "Where are you going to have lunch?",
          "What time does their flight to New York leave?",
          "Is Leah going to buy anything?",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P23-E3",
    exerciseKey: "/img/FriendsPlus/Page23/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 800,
      marginLeft: "16px",
      textAlign: "left",
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Write sentences using the future form
        in brackets.
`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>I / get / some new jeans on Friday (going to) <br>
          <span style='border-bottom:2px dotted black;font-weight:bold'>I’m going to get some new jeans on Friday.</span>
          </p>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">1</span>
            <span>
              in the future, / people / not go to shops (will)
            </span> <br>
            <input id=0 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">2</span>
            <span>
              our bus / arrive / at 8.00 tonight (present simple)
            </span> <br>
            <input id=1 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">3</span>
            <span>
              Jo / buy / a bike today (present continuous)
            </span> <br>
            <input id=2 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">4</span>
            <span>
              my daughter / take / a few exams / at the end of
              this week. (going to)
            </span> <br>
            <input id=3 ></input>
          </div>

          
        </div>
      
        
        `,
        answer: [
          "In the future, people won't go to shops.",
          "Our bus arrives at 8.00 tonight.",
          "Jo is buying a bike today.",
          "My daughter is going to take a few exams at the end of this week.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit3",
    id: "SW9-2024-U2-P23-E4",
    exerciseKey: "/img/FriendsPlus/Page23/key/e4-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Choose the correct words in the email.`,
        left: -50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontSize: 22,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "’ll&nbsphave / have / ’re&nbsphaving",
          "’ll&nbspbuy / buy / ’m&nbspgoing&nbspto&nbspbuy",
          "opens / opening / ’ll&nbspopen",
          "’s&nbspbeing / ’ll&nbspbe&nbspbeing / ’ll&nbspbe",
          "’s&nbspcoming / comes / ’ll&nbspcome",
          "waits / ’s&nbspwaiting / ’ll&nbspwait",
          "call / ’ll&nbspcall / ’m&nbspcalling",
          "’ll&nbsptalk / talk / ’re&nbsptalking",
        ],
        answers: ["0-0", "1-8", "2-0", "3-8", "4-0", "5-8", "6-4", "7-0"],
        initialValue: [],
      },
      Layout: `
       <div style='margin-top:20px;position:relative'>
       <img src='/img/FriendsPlus/Page23/12.jpg' style='width:700px'/>
       <div style='position:absolute;top:248px;left:141px'> <input id=0 type=Circle /> </div>
       <div style='position:absolute;top:282px;left:261px'> <input id=1 type=Circle /> </div>
       <div style='position:absolute;top:356px;left:144px'> <input id=2 type=Circle /> </div>
       <div style='position:absolute;top:389px;left:51px'> <input id=3 type=Circle /> </div>
       <div style='position:absolute;top:421px;left:272px'> <input id=4 type=Circle /> </div>
       <div style='position:absolute;top:453px;left:200px'> <input id=5 type=Circle /> </div>
       <div style='position:absolute;top:529px;left:146px'> <input id=6 type=Circle /> </div>
       <div style='position:absolute;top:561px;left:49px'> <input id=7 type=Circle /> </div>
       
       </div>
      `,
    },
  },
  5: {
    unit: "Unit 3",
    id: "SW9-2024-U2-P23-E5",
    exerciseKey: "/img/FriendsPlus/Page23/key/e5-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 700,
      textAlign: "left",
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: ` Complete the sentences using the future
forms on this page and your own ideas.
`,
        left: 50,
        star: 3,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>I’ve decided that next year, 
          <span style='border-bottom:2px dotted black;font-weight:bold'>I’m going to get a job.</span>
          </p>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">1</span>
            <span>
              In 2050,
            </span> 
            <input id=0 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">2</span>
            <span>
              Next month
            </span> 
            <input id=1 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">3</span>
            <span>
              This Saturday afternoon,
            </span> 
            <input id=2 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">4</span>
            <span>
              Hurry up! We need to leave now because the shop
            </span>
            <input id=3 ></input>
          </div>

          
        </div>
      
        
        `,
        answer: [
          "there won't be any shops. People will order everything online.|there will not be any shops. People will order everything online.",
          "I'll take up a new sport.|I will take up a new sport.",
          "I'm going shopping with friends in town.|I am going shopping with friends in town.",
          "closes in ten minutes.",
        ],
      },
    ],
  },
};

export default json;
