import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P34-E1",
    exerciseKey: "/img/FriendsPlus/Page34/key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Choose the odd word out.
`,
        left: -50,
        star: 1,
        color: "#ff9f38",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontSize: 22,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "gravity|&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|life|&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|death",
          "Earth|&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|disease|&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|sun",
          "universe|&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|space|&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|astronaut",
          "planet|&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|humans|&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|Earth",
          "star|&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|meteor|&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|species",
          "sun|&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|disease|&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|threat",
        ],
        answers: ["0-0", "1-4", "2-8", "3-4", "4-8", "5-0"],
        initialValue: [],
      },
      Layout: `
       <div style='margin-top:20px;'>
        <div style='display:flex; align-items:center'>
          <span style='margin-right:40px'>human</span>
          <span style='margin-right:40px'>astronaut</span>
          <span style='border:1px solid black;border-radius:50%;padding:2px 5px'>alien</span>
        </div>
        <div style='margin-top:10px'>
          <span style='font-size:22px;font-weight:bold;margin-right:10px'>1</span>
          <input id=0 type=Circle /> <br>
        </div>
          
        <div style='margin-top:10px'>
          <span style='font-size:22px;font-weight:bold;margin-right:10px'>2</span>
          <input id=1 type=Circle /> <br>
        </div>

        <div style='margin-top:10px'>
          <span style='font-size:22px;font-weight:bold;margin-right:10px'>3</span>
          <input id=2 type=Circle /> <br>
        </div>

        <div style='margin-top:10px'>
          <span style='font-size:22px;font-weight:bold;margin-right:10px'>4</span>
          <input id=3 type=Circle /> <br>
        </div>

        <div style='margin-top:10px'>
          <span style='font-size:22px;font-weight:bold;margin-right:10px'>5</span>
          <input id=4 type=Circle /> <br>
        </div>

        <div style='margin-top:10px'>
          <span style='font-size:22px;font-weight:bold;margin-right:10px'>6</span>
          <input id=5 type=Circle /> <br>
        </div>
          
        

       </div>
      `,
    },
  },
  2: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P34-E2",
    exerciseKey: "/img/FriendsPlus/Page34/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 127,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#ff9f38",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "astronaut",
          "Earth",
          "gravity",
          "life",
          "<s>planet</s>",
          "space",
          "stars",
          "sun",
          "universe",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Complete the article with the words.
`,
        left: 50,
        star: 1,
        color: "#ff9f38",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page34/11.jpg' style='width:600px' />

          <div style='position:absolute;top:337px;left:365px'> <input id=0 />  </div>
          <div style='position:absolute;top:431px;left:207px'> <input id=1 />  </div>
          <div style='position:absolute;top:493px;left:39px'> <input id=2 />  </div>
          <div style='position:absolute;top:521px;left:39px'> <input id=3 />  </div>
          <div style='position:absolute;top:615px;left:44px'> <input id=4 />  </div>
          <div style='position:absolute;top:677px;left:41px'> <input id=5 />  </div>
          <div style='position:absolute;top:677px;left:309px'> <input id=6 />  </div>
          <div style='position:absolute;top:710px;left:122px'> <input id=7 />  </div>

        </div>
      
        
        `,
        answer: [
          "stars",
          "Earth",
          "universe",
          "sun",
          "gravity",
          "astronaut",
          "space",
          "life",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P34-E3",
    exerciseKey: "/img/FriendsPlus/Page34/key/e3-key.png",
    audio: "/Audios/1.11.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=11 colorImg=#ff9f38></headphone>  Listen to a radio programme
        about a science lesson. When astronaut Tim Peake
        was on the International Space Station (ISS), he
        gave a science lesson to students on Earth. Predict
        the answers to 1–5. Then listen to check your ideas.
`,
        left: -50,
        star: 2,
        color: "#ff9f38",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontSize: 22,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "meteors / stars / planets",
          "stayed&nbspstill / moved&nbsparound / fell&nbspdown",
          "more&nbspquickly / slowly / strongly",
          "two / six / twelve",
          "swimming / cycling / playing&nbspfootball",
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-4"],
        initialValue: [],
      },
      Layout: `
       <div style='margin-top:20px;'>
        <span>
        The astronaut Tim Peake was travelling at 276 /
        2,760 / <span style='border:1px solid;border-radius:50%;padding:2px 5px'>27,600</span> km an hour
        </span>

        <div style='display:flex;margin-top:20px'>
          <span style='font-weight:bold;margin-right:10px'>1</span>
          <span>
            One student asked Tim about the possibility of
            <input id=0 type=Circle /> hitting the ISS.
          </span> 
        </div>

        <div style='display:flex;margin-top:20px'>
          <span style='font-weight:bold;margin-right:10px'>2</span>
          <span>
            When Tim removed his hands from the microphone, it 
            <input id=1 type=Circle />
          </span>
        </div>

        <div style='display:flex;margin-top:20px'>
          <span style='font-weight:bold;margin-right:10px'>3</span>
          <span>
            People’s hearts start to beat
            <input id=2 type=Circle />  in space
          </span>
        </div>

        <div style='display:flex;margin-top:20px'>
          <span style='font-weight:bold;margin-right:10px'>4</span>
          <span>
            Astronauts need to do
            <input id=3 type=Circle /> hours of exercise every day.
          </span>
        </div>

        <div style='display:flex;margin-top:20px'>
          <span style='font-weight:bold;margin-right:10px'>5</span>
          <span>
            The astronauts exercised by running and
              <input id=4 type=Circle /> 
          </span>
        </div>


       </div>
        

        
      `,
    },
  },
  4: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P34-E4",
    exerciseKey: "/img/FriendsPlus/Page34/key/e4-key.png",
    audio: "/Audios/1.11.mp3",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 127,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#ff9f38",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "astronaut",
          "Earth",
          "gravity",
          "life",
          "<s>planet</s>",
          "space",
          "stars",
          "sun",
          "universe",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=11 colorImg=#ff9f38></headphone>  Listen again. Correct the
              information in bold.
`,
        left: 50,
        star: 1,
        color: "#ff9f38",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        
        <div style='margin-top:20px;'>
          Tim was on the ISS for six <s><strong>weeks</strong></s>. <span style='border-bottom:1px dotted'>months</span>

          <div style='margin-top:20px;>
            <span style='font-weight:bold;margin-right:10px'>1</span>
            <span>The video call lasted <strong>thirty</strong>  minutes</span>
            <input id=0 />
          </div>

          <div>
            <span style='font-weight:bold;margin-right:10px'>2</span>
            <span>Tim answered questions from students across
              <strong>the USA</strong> </span>
            <input id=1 />
          </div>

          <div>
            <span style='font-weight:bold;margin-right:10px'>3</span>
            <span>To see the effect of zero gravity, students told
                  Tim to touch his 
              <strong>nose</strong> </span>
            <input id=2 />
          </div>

          <div>
            <span style='font-weight:bold;margin-right:10px'>4</span>
            <span>Students also asked Tim to drink some <strong>tea</strong> </span> to
            see the effect of zero gravity.
              
            <input id=3 />
          </div>

          <div>
            <span style='font-weight:bold;margin-right:10px'>5</span>
            <span>If astronauts didn’t do exercise, their hearts
                  would get  
              <strong>bigger</strong> </span>
            <input id=4 />
          </div>

        </div>
      
        
        `,
        answer: ["twenty", "the UK", "feet", "water", "smaller"],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P34-E5",
    exerciseKey: "/img/FriendsPlus/Page34/key/e5-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 800,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#ff9f38",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "afraid of",
          "aliens / another species",
          "death / disease",
          "find",
          "in danger of",
          "life on other planets",
          "meteors",
          "think",
          "a threat",
          "travel",
          "the universe",
          "<s>zero-gravity</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: ` Imagine that you have a science lesson
        with an astronaut. Write four questions to ask him
        or her. Use some of the words in the box and your
        own ideas.
`,
        left: 50,
        star: 3,
        color: "#ff9f38",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    character: ",",
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top:20px;'>
          <span style='border-bottom:1px dotted; font-weight:bold'>Is zero gravity a threat to your health in space?
          </span> <p></p>
          <input id=0 /> <br>
          <input id=1 /> <br>
          <input id=2 /> <br>
          <input id=3 />

        </div>
      
        
        `,
        InputRong: true,
        answer: [
          "Are you afraid of finding aliens in space?",
          "Will astronauts travel to another part of the universe in the future?",
          "Are meteors a serious threat to Earth?",
          "Do you think there’s life on other planets?",
        ],
      },
    ],
  },
};

export default json;
