import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "SW9-2024-U1-P13-E1",
    exerciseKey: "img/FriendsPlus/Page13/Key/E1answerKey.png",
    // audio: "Audios/1.03.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 100,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#07a955",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#07a955",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "decided",
          "didn’t stay",
          "’d left",
          "hadn’t seen",
          "wasn’t enjoying",
          "<s>were driving</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the words or sentences about
a school trip to an art gallery.`,
        color: "#07a955",
        left: 50,
        star: 1,
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "#07a955",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm'>
          Just <u style='text-decoration-style: dotted'>&emsp;as&emsp;</u> I got to the school gates, the coach arrived.<br>
            <b>1</b>&ensp;W# we were sitting on the brand new coach, I looked at some photos on Instagram.<br>
            <b>2</b>&ensp;By the# we arrived, I felt tired.<br>
            <b>3</b>&ensp;But the moment# we got off the coach, I suddenly felt better!<br>
            <b>4</b>&ensp;First, we looked at valuable paintings and after# we looked at rare vases.<br>
            <b>5</b>&ensp;I went to the shop and mean# my friends went to the café.<br>
            <b>6</b>&ensp;Everything was OK u# I lost my friends! I just couldn’t find them.<br>
            <b>7</b>&ensp;I looked at the map of the museum. I walked around for ages b# I found myself on the roof!<br>
            <b>8</b>&ensp;In the#  , I found all my friends by the coach. They wanted to know where I’d been!<br>
            <b>9</b>&ensp;F#  , we went home on the coach.<br>
        </div>
        `,
        // initialValue: ["", "", "1"],
        answer: [
          "hile",
          "time",
          "that",
          "wards",
          "while",
          "ntil",
          "efore",
          "end",
          "inally",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SW9-2024-U1-P13-E2",
    exerciseKey: "img/FriendsPlus/Page13/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct words in the blog post
about a school trip to a science museum.`,
        color: "#07a955",
        width: "27cm",
        left: -50,
        star: 2,
        colorStar: "#a0d4ae",
      },
    ],
    styleHint: {
      borderColor: "#07a955",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#07a955",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 200,
      paddingTop: 10,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#07a955",
          border: "2px solid",
          borderColor: "#07a955",
        },
        limitSelect: 1,
        listWords: [
          "Before / Just / Meanwhile",
          "that / of / as",
          "Until / Finally / While",
          "moment / time / end",
          "until / while /meanwhile",
          "Until / Afterwards / While",
          "just as / by_the_time / meanwhile",
          "while / finally / until",
          "as / that / by",
          "end / moment / time",
        ],
        answers: [
          "1-0",
          "2-8",
          "3-4",
          "4-0",
          "5-4",
          "6-10",
          "7-4",
          "8-0",
          "9-0",
        ],
        initialValue: [],
      },
      Layout: `
      <div style='position:relative'>
        <img src="img/FriendsPlus/Page13/E2/1.jpg" style='height:16cm'/>
        <div style='position:absolute;top:81px;left:64px;width:179mm;line-height:35.5px;font-size:22px'>
         <span style="font-weight: bold; border: 2px solid rgb(7, 169, 85); border-radius: 50%;"><span>Before</span></span> / <span style="font-weight: bold; border: 2px solid transparent;"><span>Just</span></span> / <span style="font-weight: bold; border: 2px solid transparent;"><span>Meanwhile</span></span> we went to the science museum, I thought it sounded boring! But the moment <sup>1</sup><input id=1 type=Circle /> we arrived, I began to change my opinion. <sup>2</sup><input id=2 type=Circle /> we were walking through the entrance, I realised that it was a fun place! By the <sup>3</sup><input id=3 type=Circle /> we ate lunch at 1.30, I’d seen 
          a lot of cool gadgets.<br>
          We walked around the museum <sup>4</sup><input id=4 type=Circle /> 3.00,
          when we had a fascinating talk. <sup>5</sup><input id=5 type=Circle />, we
          looked at more inventions, and <sup>6</sup><input id=6 type=Circle />
          some of the others went to the café. We <sup>7</sup><input id=7 type=Circle /> left
          the museum at 5.00. Just <sup>8</sup><input id=8 type=Circle /> we were leaving, my sister
          called me and I told her about the visit. The traffic was slow on the
          way home, but we arrived in the <sup>9</sup><input id=9 type=Circle /> at 7.30.
        </div>
      </div> 
      
      `,
    },
  },

  3: {
    unit: "Unit 1",
    id: "SW9-2024-U1-P13-E3",
    exerciseKey: "img/FriendsPlus/Page13/Key/E3answerKey.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "2px dotted",
      borderBottom: "none",
      width: 800,
      padding: 0,
      paddingBottom: 5,
      fontSize: 23,
      paddingTop: 5,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    styleHint: {
      borderColor: "#07a955",
      justifyContent: "center",
      width: "15cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#07a955",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "amazingly",
          "as",
          "end",
          "finally",
          "meanwhile",
          "<s>started</s>",
          "that",
          "time",
          "while",
        ],
        width: 600,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read and complete the blog post from a school website.`,
        width: "27cm",
        color: "#07a955",
        left: 50,
        star: 2,
        colorStar: "#a0d4ae",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
      <hintbox id=0></hintbox>

    <div style='position:relative'>
        <div style='position:absolute;top:368px;left:155px'><input width=121px /></div>
        <div style='position:absolute;top:368px;left:429px'><input width=121px /></div>
        <div style='position:absolute;top:500px;left:98px'><input width=121px /></div>
        <div style='position:absolute;top:623px;left:139px'><input width=121px /></div>
        <div style='position:absolute;top:756px;left:425px'><input width=121px /></div>       
        <div style='position:absolute;top:878px;left:44px'><input width=121px /></div>       
        <div style='position:absolute;top:919px;left:510px'><input width=121px /></div>       
        <div style='position:absolute;top:1002px;left:24px'><input width=121px /></div>       
        <img src="img/FriendsPlus/Page13/E3/1.jpg" style='height:29cm'/>
      </div>
        `,
        answer: [
          "amazingly",
          "that",
          "time",
          "While",
          "as",
          "Meanwhile",
          "end",
          "finally",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SW9-2024-U1-P13-E4",
    exerciseKey: "img/FriendsPlus/Page13/Key/E4answerKey.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    // isHello:true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 200,
      // marginLeft: 20,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: {
      width: 421,
      resize: "none",
      paddingTop: 3,
      marginBottom: -23,
      // marginLeft: 17,
      marginTop: -3,
    },
    styleHint: {
      borderColor: "#07a955",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#07a955",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["finish", "go", "lose", "not eat", "not fly", "not see"],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Write a blog post about a real or
imaginary school trip. Use the blog post in
exercise 3 to help you and make notes using the
table below.
`,
        color: "#07a955",
        width: "27cm",
        left: 50,
        star: 3,
        colorStar: "#a0d4ae",
      },
    ],
    // KeyMau: true,
    // isHello: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
        <div style='position:absolute;top:7px;left:186px'><textarea id=0 rows=2 ></textarea></div>
        <div style='position:absolute;top:95px;left:186px'><textarea id=1 rows=2 ></textarea></div>
        <div style='position:absolute;top:181px;left:186px'><textarea id=2 rows=2 ></textarea></div>
       
        <img src="img/FriendsPlus/Page13/E4/1.jpg" style='height:7cm'/>
      </div>
        `,
        answer: [
          "Fascinatingly",
          "immediately",
          "identical",
          "quickly",
          "Incredibly",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SW9-2024-U1-P13-E5",
    exerciseKey: "img/FriendsPlus/Page13/Key/E5answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 850,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    styleHint: {
      borderColor: "#07a955",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#07a955",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "brother(s) / sister(s)",
          "live in …",
          "name",
          "used to play / go / like",
        ],
        width: 600,
        borderColor: "#07a955",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `You meet a friend who you haven’t seen
for a long time. Write four or five questions using
question tags. Use the phrases in the box and
exercise 4 for ideas.`,
        color: "#07a955",
        left: 50,
        star: 3,
        colorStar: "#a0d4ae",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <textarea id=0 rows=5></textarea>
        `,
        initialValue: ["Hey, it's Derin, isn't it?"],
        answer: [
          "Can you tell me why to consider studying abroad for a term?",
          "Do you know who to approach for guidance on writing a good essay?",
          "Can you recommend where to celebrate our year-end party next week?",
          "Can you tell me who to ask for permission before using the hall?",
        ],
      },
    ],
  },
};

export default json;
