import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P30-E1",
    exerciseKey: "/img/FriendsPlus/Page30/key/e1-key.png",
    audio: "/Audios/1.10.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=10 colorImg=#f0ab9c></headphone> Read and listen to the advice
website and choose the correct answer.

`,
        left: -50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a&nbsp <br> b&nbsp <br> c"],
        answers: ["0-8"],
        initialValue: [],
      },
      Layout: `
       <div style='margin-top:20px;'>
       
        <div style='display:flex'>

         <div>
         <input id=0 type=Circle /> 
         </div>
         

         <div>
         <span> how to express your personality with the colours
          in your bedroom.
          </span> <br>
         
         <span> how to match the colour of your bedroom walls
          and furniture.</span> <br>
         <span> how the colours in your bedroom can affect your
          mood.</span>
         </div>

        </div>

        <div style='margin-top:20px;'>
        <img src='/img/FriendsPlus/Page30/11.jpg' style='width:600px'/>
        </div>
       </div>
      `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P30-E2",
    exerciseKey: "/img/FriendsPlus/Page30/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 120,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the website again. Complete the
              sentences with a word from the advice.
`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <div style='display:flex'>
          
          <div>
          <p>
            <span style='border-bottom:2px dotted black;font-weight:bold'>
              Black
            </span>
            or dark grey aren’t the best choice of colours for a bedroom.
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            
            <span> 
             It’s important to think about
            </span> 
            <input  id=0 ></input> as well as colour.

          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span> 
              The best colours for sleep are yellow,
            </span> 
            <input  id=1 ></input> and blue.
            
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              People associate the colour
            </span> 
            <input  id=2 ></input> with bad weather.

          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <input  id=3 ></input>
            <span>
              can help people to work hard on projects with details.
            </span> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
              A whole room painted red is
            </span> 
            <input  id=4 ></input>
          </div>
          </div>



          <div>
          <img src='/img/FriendsPlus/Page30/11.jpg' style='width:600px' />
          </div>
          
          </div>

        </div>
      
        
        `,
        answer: ["tone", "green", "grey", "Red", "intense"],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P30-E3",
    exerciseKey: "/img/FriendsPlus/Page30/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 1100,
      marginLeft: 8,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Answer the questions. Write complete
        sentences.
`,
        left: 50,
        star: 3,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>

         <p>
            Which colours don’t help you sleep? <br>
            <span style='border-bottom:2px dotted black;font-weight:bold'>
              Brown and purple don’t help you sleep.
            </span>
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            
            <span> 
             Why do you think Zoë suggests a pale blue
              colour to Lizzie? <br>
            </span> 
            <input  id=0 ></input> 

          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span> 
              What is the problem with black walls? <br>
            </span> 
            <input  id=1 ></input> 
            
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              According to a study, what emotions did grey
              bring out in people? <br>
            </span> 
            <input  id=2 ></input> 

          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
              Which person wants to make their room more
              modern? Which colour could be the answer? <br>
            </span>
            <input  id=3 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
              What two reasons does Zoë give Findlay for
              painting just one wall red? <br>
            </span> 
            <input  id=4 ></input>
          </div>

        </div>
      
        
        `,
        answer: [
          "I think it's because bright colours could keep her awake at night.|I think it is because bright colours could keep her awake at night.",
          "They can make a room appear smaller.",
          "It brought out sadness and boredom.",
          "Findlay wants to make his room more modern. A deep shade of red could be the answer.",
          "It would be cheaper and it would avoid a whole room in red, which could make him feel stressed.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P30-E4",
    exerciseKey: "/img/FriendsPlus/Page30/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 50,
      marginLeft: 8,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `VOCABULARY PLUS Match 1–6 with a–f.
`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>

          <div style='display:flex; justify-content:space-between;width:600px'>
          
            <div>
              <span style='font-weight:bold;margin-right:10px'>1</span>
              <span> It’s a nice colour, it’s green <span style='border-bottom:1px dotted;font-weight:bold'>e</span> </span> <br>

              <span style='font-weight:bold;margin-right:10px'>2</span>
              <span> He won’t listen. He’s narrow <input id=0 /> </span> <br>
            
              <span style='font-weight:bold;margin-right:10px'>3</span>
              <span> That design expert is well <input id=1 /> </span> <br>

              <span style='font-weight:bold;margin-right:10px'>4</span>
              <span> This isn’t dark, it’s bright <input id=2 /> </span> <br>

              <span style='font-weight:bold;margin-right:10px'>5</span>
              <span> That paint is cheap, it’s low <input id=3 /> </span> <br>

              <span style='font-weight:bold;margin-right:10px'>6</span>
              <span> His bedroom is a bit old <input id=4 /> </span> <br>

            </div>


            <div>
              <span style='font-weight:bold;margin-right:10px'>a</span>
              <span> fashioned</span> <br>

              <span style='font-weight:bold;margin-right:10px'>b</span>
              <span> respected</span> <br>

              <span style='font-weight:bold;margin-right:10px'>c</span>
              <span> coloured</span> <br>

              <span style='font-weight:bold;margin-right:10px'>d</span>
              <span> priced</span> <br>

              <span style='font-weight:bold;margin-right:10px'>e</span>
              <span> toned</span> <br>

              <span style='font-weight:bold;margin-right:10px'>f</span>
              <span> minded</span>

            </div>
          
          </div>

        </div>
      
        
        `,
        answer: ["f", "b", "c", "d", "a"],
      },
    ],
  },
};

export default json;
