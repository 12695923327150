import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "LFP-Starter Unit 5",
    id: "SW9-2024-LF-U5-P65-E1",
    exerciseKey: "/img/FriendsPlus/Page65/Key/E1answerkey.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontWeight: "bold",
      width: 250,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "become",
          "find",
          "<s>play</s>",
          "study",
          "visit",
          "walk",
          "watch",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Choose the correct words.`,
        left: 50,
        star: 0,
        color: "#4E3E94",
        colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <u style='text-decoration-style: dotted'>&emsp;Playing&emsp;</u> my favourite video game, World of Warcraft, is always exciting.<br>
        <div style='width:25cm'>
            <b>1</b>&ensp;We intend # the Kennedy Space Center on our trip to Florida next year.<br>
            <b>2</b>&ensp;I’m enjoying # design at school.<br>
            <b>3</b>&ensp;Is it very difficult # in a spacesuit?<br>
            <b>4</b>&ensp;Doctors hope # a cure for the disease in the near future.<br>
            <b>5</b>&ensp;Is this plant in danger of # extinct?<br>
            <b>6</b>&ensp;# science fiction films on TV is a good way to relax.<br>
          </div>
      `,
        answer: [
          "to visit",
          "studying",
          "to walk",
          "to find",
          "becoming",
          "Watching",
        ],
      },
    ],
  },
  2: {
    unit: "LFP-Starter Unit 5",
    id: "SW9-2024-LF-U5-P65-E2",
    exerciseKey: "/img/FriendsPlus/Page65/Key/E2answerkey.jpg",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 20,
      // fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Rewrite the sentences so that the meaning is the
same. Use a gerund or an infinitive form.`,
        left: 50,
        width: "27cm",
        // star: 0,
        color: "#4E3E94",
        // colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    questions: [
      {
        title: `

        Learning about the planets is fun.<br>
        It’s fun<u style='text-decoration-style: dotted'>to learn about the planets.&emsp;&emsp;&emsp;&emsp;&emsp;</u>
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px'>
              Billy wants to design products.<br>
              Billy is interested in <input width=306px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px'>
              Exploring new places is exciting.<br>
              It’s exciting <input width=400px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px'>
              They aim to go to the new museum soon.<br>
              They plan <input width=414px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px'>
              Adrian’s hobby is inventing things.<br>
              Adrian really enjoys <input width=303px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px'>
              We can’t do the maths exercise.<br>
              It’s impossible <input width=364px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>6</b> 
            <div style='margin-left:15px'>
              Can you use the microscope well?<br>
              Are you good at <input width=342px />?
            </div>
          </div>
   
        `,
        answer: [
          "designing products",
          "to explore new places",
          "to go to the new museum soon",
          "inventing things",
          "to do the maths exercise",
          "using the microscope",
        ],
      },
    ],
  },
  3: {
    unit: "LFP-Starter Unit 5",
    id: "SW9-2024-LF-U5-P65-E3",
    exerciseKey: "/img/FriendsPlus/Page65/Key/E3answerkey.jpg",
    audio: "",
    video: "",

    stylesTextInput: {
      // textAlign: "center",
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 20,
      // fontWeight: "bold",
      width: 700,
      paddingTop: 10,
      // marginLeft: 20,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Write the sentences with the zero, first or second
conditional.`,
        left: 50,
        star: 0,
        color: "#4E3E94",
        // colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    component: T6,
    questions: [
      {
        title: `
        
        unless / we / read / this book, / we / not understand / the discovery<br>
        (1<sup>st</sup>) <u style='text-decoration-style: dotted'>&emsp;Unless we read this book, we won’t understand the discovery.&emsp;</u>
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px'>
              I / not be afraid / if / I / meet / an alien<br>
              (2<sup>nd</sup>) <input width=708px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px'>
              if / you / mix / red and blue, / it / make / purple<br>
              (zero)  <input width=694px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px'>
              they / not produce / that new invention / unless / it / be / cheap<br>
              (1<sup>st</sup>) <input width=714px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px'>
              If / he / speak / German, / he / will / spend a year / working there<br>
              (2<sup>nd</sup>) <input width=708px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px'>
              if / you / see / a meteor, / what / you / do / ?<br>
              (2<sup>nd</sup>)  <input width=708px />.
            </div>
          </div>
          
   

        `,
        answer: [
          "I wouldn't be afraid if I met an alien.",
          "If you mix red and blue, it makes purple.",
          "They won't produce that new invention unless it's cheap.",
          "If he spoke German, he would spend a year working there.",
          "If you saw a meteor, what would you do?",
        ],
      },
    ],
  },
  4: {
    unit: "LFP-Starter Unit 5",
    id: "SW9-2024-LF-U5-P65-E2",
    exerciseKey: "img/FriendsPlus/Page65/Key/E4answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the correct form of the
verbs in brackets.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#4D3C98",
      justifyContent: "center",
      width: "11cm",
      marginBottom: 10,
      marginLeft: 200,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>help</s>",
          "not like",
          "live",
          "phone",
          "plan",
          "start",
          "not watch",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      // marginLeft: 20,
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 200,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,

    questions: [
      {
        title: `
        The Smiths wish that they  <u style='text-decoration-style: dotted'>&emsp;knew&emsp;</u> (know) Marina’s phone number.
        <div style='width:24cm'>
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px'>
              Scientists wish that they #(can) learn more
              about the universe without spending much on space exploration.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px'>
              He wishes that he #(afford) enough to
              travel to exotic places to explore different cultures.
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px'>
              We wish that there #(will) be more
              opportunities for professional development in our school.
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px'>
              They wish that the traffic #(clear) up soon
              so that they could reach the destination in time.
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px'>
              They wish that they #(can) perform time
              travel and undo their mistakes.
            </div>
          </div>
          </div>
        
        `,
        answer: ["could", "afforded", "would", "cleared", "could"],
      },
    ],
  },
  5: {
    unit: "LFP-Starter Unit 5",
    id: "SW9-2024-LF-U5-P65-E2",
    exerciseKey: "img/FriendsPlus/Page65/Key/E5answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "5",
        title: `Order the words to make sentences.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      marginLeft: 140,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "doesn’t have to",
          "don’t have to have to",
          "must",
          "mustn’t",
          "should",
          "shouldn’t",
        ],
      },
    ],
    textareaStyle: {
      width: 700,
      marginBottom: -24,
      resize: "none",
      paddingTop: 3,
    },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 200,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        doctor / the immune / vitamin C / suggested / His / using / to support<br>

        <u style='text-decoration-style: dotted'>His doctor suggested using vitamin C to support the immune.&emsp;&emsp;&emsp;&emsp;</u>
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px;width:22cm'>
              The / suggested / travel guide / the original features / exploring / Việt Nam / when / in / Tết / of<br>
              <textarea  id=0 rows=2></textarea>
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px;width:22cm'>
              suggested / The / coach / focusing / improving soft skills / during / on / the training course<br>
              <textarea  id=1 rows=2></textarea>
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px;width:22cm'>
              suggested / Her doctor / regularly / and longer life / for better health / exercising<br>
              <textarea  id=2 rows=2></textarea>
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px;width:22cm'>
              rehearsing / Professional public speakers / the speech / many times / suggested / before speech delivery<br>
              <textarea  id=3 rows=2></textarea>
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px;width:22cm'>
              The manager / implementing / suggested / to increase/ productivity / a new strategy<br>
              <textarea  id=4 rows=2></textarea>
            </div>
          </div>
          
   

        `,
        answer: [
          "The travel guide suggested exploring the original features of Tết when in Việt Nam.",
          "The coach suggested focusing on improving soft skills during the training course.",
          "Her doctor suggested exercising regularly for better health and longer life.",
          "Professional public speakers suggested rehearsing the speech many times before speech delivery.",
          "The manager suggested implementing a new strategy to increase productivity.",
        ],
      },
    ],
  },
  6: {
    unit: "LFP-Starter Unit 5",
    id: "SW9-2024-LF-U5-P65-E6",
    exerciseKey: "img/FriendsPlus/Page65/Key/E6answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "6",
        title: `The bold words are in the wrong place in the
sentences. Correct the sentences.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      marginLeft: 20,
      width: 650,
      paddingTop: 10,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
   
        These products are useful and attractive <span style='text-decoration:line-through'>both</span> .<br>
        <u style='text-decoration-style: dotted'>These products are both useful and attractive.&emsp;&emsp;</u><br>
        <b>1</b>&ensp;This car isn’t only great but convenient <b>also</b>.<br>#<br>
        <b>2</b>&ensp;I bought a new coat a pair of jeans <b>as well as</b>.<br>#<br>
        <b>3</b>&ensp;This jacket is available <b>both</b> in blue and red.<br>#<br>
        <b>4</b>&ensp;I went to the shops and played <b>too</b> football.<br>#<br>
        <b>5</b>&ensp;This hat is not cheap <b>only</b> but also practical.<br>#<br>
        <b>6</b>&ensp;She often buys <b>as well</b> shoes <b>as</b> bags.<br>#<br>
        
        
        `,
        answer: [
          "This car isn't only great but also convenient.",
          "I bought a new coat as well as a pair of jeans.",
          "This jacket is available in both blue and red.",
          "I went to the shops and played football, too.",
          "This hat is not only cheap but also practical.",
          "She often buys shoes as well as bags.",
        ],
      },
    ],
  },
};

export default json;
