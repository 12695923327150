import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "SW9-2024-Starter-P7-E1",
    exerciseKey: "img/FriendsPlus/Page7/Key/E1answerKey.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words to complete the table.`,
        color: "#1dbfd6",
        left: -50,
        star: 1,
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "used_to / use_to",
          "do / did",
          "used_to / use_to",
          "studied / study",
          "used_to / use_to",
          "used_to / use_to",
          "chat / chatted",
          "used / did",
          "not_used / didn’t",
        ],
        answers: ["1-0", "2-4", "3-4", "4-4", "5-4", "6-0", "7-4", "8-4"],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <div style='width:162mm;position:absolute;top:111px;left:35px'>I / You / He / She / It / We / You / They <span style="padding: 1px 4px; border: 2px solid rgb(0, 174, 239); font-weight: bold; border-radius: 50%; color: black;"><span>used to</span></span> / <span style="padding: 1px 4px; border: 2px solid transparent; font-weight: bold;"><span>use to</span></span> <sup>1</sup><input id=1 type=Circle > maths without a calculator. </div>
        <div style='width:150mm;position:absolute;top:238px;left:35px'>I / You / He / She / It / We / You / They didn’t <sup>2</sup> <input id=2 type=Circle />  <sup>3</sup> <input id=3 type=Circle /> IT. </div>
        <div style='width:162mm;position:absolute;top:358px;left:35px'>
            What films did they <sup>4</sup> <input id=4 type=Circle />  watch? <br>
            Did you <sup>5</sup> <input id=5 type=Circle />  <sup>6</sup> <input id=6 type=Circle />? <br>
            Yes, I <sup>7</sup> <input id=7 type=Circle />. <br>
            No, I <sup>8</sup> <input id=8 type=Circle />.  <br>
        </div>
        <img src="img/FriendsPlus/Page7/E1/1.jpg" style='height:14cm'/>
      </div>
      `,
    },
  },
  2: {
    unit: "Starter",
    id: "SW9-2024-Starter-P7-E2",
    exerciseKey: "img/FriendsPlus/Page7/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Complete the article with the correct form of
<i>used to</i> and the verbs in brackets.`,
        color: "#1dbfd6",
        width: "27cm",
        left: 50,
        star: 2,
        colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "check",
          "download",
          "follow",
          "<s>post</s>",
          "share",
          "spend time",
          "update",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 240,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div style='position:relative'>
          <div style='line-height:36px;width:150mm;position:absolute;top:296px;left:33px'>
            In the past, we <u style='text-decoration-style: dotted'>&emsp;used to see&emsp;</u>(see) more
            cameras. People <sup>1</sup>#(take) all of
            their photos with a camera. Today, 89% of young
            people use smartphones to take photos. People
            <sup>2</sup>#(not take) many ‘selfies’ before,
            but now the British population takes over 1.2 billion
            ‘selfies’ every year!
          </div>
          <div style='line-height:36px;width:150mm;position:absolute;top:626px;left:33px'>
              In 2005, British people <sup>3</sup>#(not spend) much time on the internet. They
              <sup>4</sup>#(go) online for ten hours a week
              and they <sup>5</sup>#(use) a home computer.
              Now people spend over twenty hours a week
              online, often on a smartphone, laptop or tablet
              – this raises the question ‘What <sup>6</sup>#
              people #(do) with their free time
              before they had the internet?’
          </div>
          
            <img src="img/FriendsPlus/Page7/E2/1.jpg" style='height:27cm'/>
        </div>
      
        
        `,
        answer: [
          "used to take",
          "didn't use to take",
          "didn't use to spend",
          "used to go",
          "used to use",
          "did",
          "use to do",
        ],
      },
    ],
  },

  3: {
    unit: "Starter",
    id: "SW9-2024-Starter-P7-E3",
    exerciseKey: "img/FriendsPlus/Page7/Key/E3answerKey.png",
    audio: "",
    video: "",
    // textAlign:'center',
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 173,
      padding: 0,
      fontSize: 23,
      paddingTop: 10,
      textAlign: "center",
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "awesome places to visit",
          "big hotels",
          "cool shops and markets",
          "fun entertainment",
          "historic buildings",
          "nice parks to walk in",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
      {
        src: [
          "big queues",
          "cheap places to eat",
          "crowds of tourists",
          "green spaces",
          "litter",
          "space on public transport",
          "traffic",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Circle the auxiliary verbs. One of the
sentences does not have one. Then complete the
question tags.`,
        width: "27cm",
        color: "#1dbfd6",
        left: -50,
        star: 2,
        colorStar: "#b4e2ef",
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [
          "can't you",
          "have we",
          "isn't there ",
          "does she",
          "are they",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          // borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "You can download that app",
          "We haven’t got a new HD TV",
          "There|’s a remote control",
          "Bea never uses a spellchecker",
          "They aren’t downloading videos",
        ],
        answers: ["2-2", "5-2", "0-2", "1-2", "4-2"],
        initialValue: ["5-2"],
      },
      Layout: `


      <div style=''>
          They <span style="border-radius: 50%; color: black; border: 2px solid rgb(0, 174, 239);"><span>didn’t</span></span> buy that games console, <u style='text-decoration-style: dotted'>&emsp;did they&emsp;</u>?<br>
          <b>1</b>&ensp;<input id=0 type=Circle />, <input id=0 />?<br>
          <b>2</b>&ensp;<input id=1 type=Circle />, <input id=1 />?<br>
          <b>3</b>&ensp;<input id=2 type=Circle />, <input id=2 />?<br>
          <b>4</b>&ensp;<input id=3 type=Circle />, <input id=3 />?<br>
          <b>5</b>&ensp;<input id=4 type=Circle />, <input id=4 />?<br>

      </div>
      `,
    },
  },
  4: {
    unit: "Starter",
    id: "SW9-2024-Starter-P7-E4",
    exerciseKey: "img/FriendsPlus/Page7/Key/E4answerKey.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    // isHello:true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 200,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: {
      width: 940,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "book",
          "pronounce",
          "buy",
          "ask",
          "learn",
          "fix",
          "improve",
          "<s>deal</s>",
          "handle",
          "contact",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Complete the dialogue with the correct
question tags.
`,
        color: "#1dbfd6",
        width: "27cm",
        left: 50,
        star: 2,
        colorStar: "#b4e2ef",
      },
    ],
    // KeyMau: true,
    // isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <div>
            <b>Harry</b><br>
            <b>Todd</b><br>
            <b>Harry</b><br>
            <b>Todd</b><br><br>
            <b>Harry</b><br>
            <b>Todd</b><br><br>
            <b>Harry</b><br>
            <b>Todd</b><br>
            <b>Harry</b><br><br>
            <b>Todd</b><br><br>
            <b>Harry</b><br>
          </div>
           <div style='width:25cm;margin-left:20px'>
              Hey, I recognise you – it’s Todd, <u style='text-decoration-style: dotted'>&emsp;isn’t it&emsp;</u> ?
              <br> Yeah, that’s me! And you are … ?
              <br> Don’t tell me you don’t remember me!
              We used to be friends!
              <br> Oh, yes! You’re Harry, <sup>1</sup>#? You
              don’t live here now, <br><sup>2</sup>#?
              <br> No, we moved away about six years ago.
              <br> That’s right. I used to play video games
              at your house after school, <br><sup>3</sup>#?
              <br> Yeah … on that really old games console.
              It wasn’t very good, <sup>4</sup>#?
              <br> It was OK! We used to love that Super
              Mario game, <sup>5</sup>#?
              <br> Yeah – that was the best! How is your
              sister? She isn’t called Daisy, <br><sup>6</sup>#?
              <br> Yes, she is! She’s well. And you’ve got a
              brother – What’s his name? It’s Brad,<br><sup>7</sup>#?
              <br>Yes! It’s really cool to see you again!
           </div>

        </div>





          
        `,
        answer: [
          "aren't you",
          "do you",
          "didn't I",
          "was it",
          "didn't we",
          "is she",
          "isn't it",
        ],
      },
    ],
  },
  5: {
    unit: "Starter",
    id: "SW9-2024-Starter-P7-E5",
    exerciseKey: "img/FriendsPlus/Page7/Key/E5answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 850,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "brother(s) / sister(s)",
          "live in …",
          "name",
          "used to play / go / like",
        ],
        width: 600,
        borderColor: "#1dbfd6",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `You meet a friend who you haven’t seen
for a long time. Write four or five questions using
question tags. Use the phrases in the box and
exercise 4 for ideas.`,
        color: "#1dbfd6",
        left: 50,
        star: 3,
        colorStar: "#b4e2ef",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <textarea id=0 rows=5></textarea>
        `,
        fixedValue: ["Hey, it's Derin, isn't it?"],
        answer: [
          "Can you tell me why to consider studying abroad for a term?",
          "Do you know who to approach for guidance on writing a good essay?",
          "Can you recommend where to celebrate our year-end party next week?",
          "Can you tell me who to ask for permission before using the hall?",
        ],
      },
    ],
  },
};

export default json;
