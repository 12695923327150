import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
const json = {
  1: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P31-E1",
    exerciseKey: "/img/FriendsPlus/Page31/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 50,
      marginLeft: 8,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Identify the features of the posts on the advice forum.

`,
        left: 50,
        star: 1,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <div style='display:flex;'>
          <div style='margin-right:30px'>
              <span style='font-weight:bold;margin-right:10px'>a</span>
              <span> emoji <span style='border-bottom:1px dotted;font-weight:bold'>3</span> </span> <br>

              <span style='font-weight:bold;margin-right:10px'>b</span>
              <span> bold <input id=0 /> </span> <br>
            
              <span style='font-weight:bold;margin-right:10px'>c</span>
              <span> italic <input id=1 /> </span> <br>

              <span style='font-weight:bold;margin-right:10px'>d</span>
              <span> underlined word <input id=2 /> </span> <br>

              <span style='font-weight:bold;margin-right:10px'>e</span>
              <span> capitals <input id=3 /> </span> <br>

              

            </div>
            <div> <img src='/img/FriendsPlus/Page31/9.jpg' style='width:600px;' /> </div>
          </div>
        </div>
      
        
        `,
        answer: ["5", "4", "1", "2"],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P31-E2",
    exerciseKey: "/img/FriendsPlus/Page31/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 500,
      marginLeft: 8,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the rest of the post on the advice
        forum. Find examples of features 1–3 that show a
        conversational tone.

`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>

          <div>

               <div  style='display:flex'>
               <span style='font-weight:bold;margin-right:10px'>1</span>
               <span> Questions for the reader: </span>
                <div style='width:200px;overflow:hidden;margin-bottom:20px'><input id=0 /></div> ,
                <div> <input id=1 /> </span>  </div>
               </div>

               <div  style='display:flex'>
                <span style='font-weight:bold;margin-right:10px'>2</span>
                <span> Question tags: </span>
                <div style='width:200px;overflow:hidden;margin-bottom:20px'><input id=2 /></div> ,
                <div style='width:200px;overflow:hidden;margin-bottom:20px'><input id=3 /></div> 
               </div>

               <div  style='display:flex'>
                <span style='font-weight:bold;margin-right:10px'>3</span>
                <span> Question tags: </span>
                <div style='width:200px;overflow:hidden;margin-bottom:20px'><input id=4 /></div> ,
                <div style='width:200px;overflow:hidden;margin-bottom:20px'><input id=5 /></div> ,
                <div style='width:200px;overflow:hidden;margin-bottom:20px'><input id=6 /></div> ,
                <div style='width:200px;overflow:hidden;margin-bottom:20px'><input id=7 /></div> ,
               </div>

               <img src='/img/FriendsPlus/Page31/10.jpg' style='width:600px;margin-top:30px;margin-left:150px' />
          </div>

        </div>
      
        
        `,
        answer: [
          "What else?",
          "Are you feeling like you can't talk to her now?",
          "isn't she?",
          "shouldn't she?",
          "OK",
          "cool",
          "cos",
          "Anyway",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P31-E3",
    exerciseKey: "/img/FriendsPlus/Page31/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      width: 120,
      marginLeft: 8,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "Anyway",
          "ASAP",
          "bet",
          "else",
          "<s>Hey</s>",
          "hey",
          "is it",
          "isn't it",
          "let's",
          "reckon",
          "start",
          "thing",
          "What",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read and complete the forum post
        with the words. 


`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;position:relative'>
        <img src='/img/FriendsPlus/Page31/11.jpg' style='width:600px;' />
        <div style='position:absolute;top:233px;left:331px'> <input id=0 /> </div>
        <div style='position:absolute;top:321px;left:371px'> <input id=1 /> </div>
        <div style='position:absolute;top:351px;left:13px'> <input id=2 /> </div>
        <div style='position:absolute;top:382px;left:13px'> <input id=3 /> </div>
        <div style='position:absolute;top:442px;left:82px'> <input id=4 /> </div>
        <div style='position:absolute;top:442px;left:261px'> <input id=5 /> </div>
        <div style='position:absolute;top:483px;left:69px'> <input id=6 /> </div>
        <div style='position:absolute;top:516px;left:133px'> <input id=7 /> </div>
        <div style='position:absolute;top:604px;left:138px'> <input id=8 /> </div>
        <div style='position:absolute;top:701px;left:165px'> <input id=9 /> </div>
        <div style='position:absolute;top:787px;left:95px'> <input id=10 /> </div>
        <div style='position:absolute;top:844px;left:16px'> <input id=11 /> </div>

        </div>
      
        
        `,
        answer: [
          "is it",
          "hey",
          "let's",
          "start",
          "What",
          "reckon",
          "else",
          "bet",
          "ASAP",
          "thing",
          "isn't it",
          "Anyway",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P31-E4",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      width: 120,
      marginLeft: 8,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "I’m staying with my aunt and my cousins for the summer",
          "I’m feeling lonely because my cousins are a few years older than me. (Alex, age 16)",
        ],
      },
      {
        src: [
          "good to ask for advice",
          "join a summer club",
          "meet new people",
          "play a sport with cousins",
          "stay cheerful",
          "talk to your aunt",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: ` Read the situation. Write a post giving
advice to Alex. Use the post in exercise 3 and the
phrases in the box to help you.


`,
        left: 50,
        star: 3,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;'>
        <hintbox id=1 ></hintbox>
        </div>
        <br>
        <textarea rows=10 id=0 ></textarea>
      
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
