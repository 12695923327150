import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P29-E1",
    exerciseKey: "/img/FriendsPlus/Page29/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      width: 113,
      marginLeft: 10,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "able",
          "can (x2)",
          "<s>can’t</s>",
          "can’t",
          "could (x2)",
          "couldn’t (x2)",
          "will",
          "won’t (x2)",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the table with the words.
`,
        left: 50,
        star: 1,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;position:relative;'>
          <img src='/img/FriendsPlus/Page29/9.jpg' style='width:600px'/>
          <div style='position: absolute;top:140px;left:10px'> <input id=0 />  </div>
          <div style='position: absolute;top:175px;left:68px'> <input id=1 />  </div>
          <div style='position: absolute;top:175px;left:264px'> <input id=2 />  </div>
          <div style='position: absolute;top:269px;left:150px'> <input id=3 />  </div>
          <div style='position: absolute;top:303px;left:12px'> <input id=4 />  </div>
          <div style='position: absolute;top:338px;left:89px'> <input id=5 />  </div>
          <div style='position: absolute;top:338px;left:303px'> <input id=6 />  </div>
          <div style='position: absolute;top:470px;left:127px'> <input id=7 />  </div>
          <div style='position: absolute;top:504px;left:134px'> <input id=8 />  </div>
          <div style='position: absolute;top:539px;left:76px'> <input id=9 />  </div>
          <div style='position: absolute;top:539px;left:274px'> <input id=10 />  </div>

        </div>
      
        
        `,
        answer: [
          "Can",
          "can",
          "can't",
          "couldn't",
          "Could",
          "could",
          "couldn't",
          "won't",
          "able",
          "will",
          "won't",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P29-E2",
    exerciseKey: "/img/FriendsPlus/Page29/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 550,
      marginLeft: 10,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Write affirmative (+) or negative (-)
              sentences, or questions (?).
`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>We’ll be able to study better with music. (?) <br>
          <span style='border-bottom:2px dotted black;font-weight:bold'>
            Will we be able to study better with music?
          </span>
          
          
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            
            <span> 
             I can read and listen to loud rock songs. (-)<br>
            </span> 
            <input  id=0 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span> 
              Alex could read music when she was five. (-) <br>
            </span> 
            <input  id=1 ></input> 
            
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              You can’t concentrate very well. (+) <br>
            </span> 
            <input  id=2 ></input> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
              I could walk from an early age. (?) <br>
            </span> 
            <input  id=3 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
              Ed could speak Greek when he was young. (?) <br>
            </span> 
            <input  id=4 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">6</span>
            <span>
              I won’t be able to relax after the exam. (+) <br>
            </span> 
            <input  id=5 ></input>
          </div>

         

        </div>
      
        
        `,
        answer: [
          "I can't read and listen to loud rock songs.|I cannot read and listen to loud rock songs.",
          "Alex couldn't read music when she was five.|Alex could not read music when she was five.",
          "You can concentrate very well.",
          "Could I walk from an early age?",
          "Could Ed speak Greek when he was young?",
          "I'll be able to relax after the exam.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P29-E3",
    exerciseKey: "/img/FriendsPlus/Page29/key/e3-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Choose the correct words
              on the web page.
`,
        left: -50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontSize: 23,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "mustn’t / don’t&nbsphave&nbspto / should",
          "mustn’t / should / don't&nbsphave&nbspto",
          "don’t&nbsphave&nbspto / have&nbspto / must",
          "shouldn’t / must / mustn’t",
          "mustn’t / have&nbspto / should",
        ],
        answers: ["1-4", "2-0", "3-4", "4-0", "0-8"],
        initialValue: [],
      },
      Layout: `
       <div style='margin-top:20px;position:relative'>
        <img src='/img/FriendsPlus/Page29/10.jpg' style='height:25cm'/> 
        <div style='position:absolute;top:438px;left:110px'> <input id=0 type=Circle /> </div>
        <div style='position:absolute;top:521px;left:158px'> <input id=1 type=Circle /> </div>
        <div style='position:absolute;top:589px;left:249px'> <input id=2 type=Circle /> </div>
        <div style='position:absolute;top:706px;left:202px'> <input id=3 type=Circle /> </div>
        <div style='position:absolute;top:808px;left:303px'> <input id=4 type=Circle /> </div>

       </div>
      `,
    },
  },
  4: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P29-E4",
    exerciseKey: "/img/FriendsPlus/Page29/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted #b5b5b5",
      width: 562,
      fontSize: 20,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "concentrate well",
          "focus on one thing at a time",
          "learn more",
          "not check phone",
          "organise my work",
          "remember more",
          "stay calm",
          "study faster",
          "take breaks",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `How good are you at studying? Write
        sentences with the affirmative or negative of can,
        could, will be able to, should, have to or must. Use
        the ideas in the box to help you.
`,
        left: 50,
        star: 3,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;position:relative'>
        <img src='/img/FriendsPlus/Page29/11.jpg' style='width:600px'/> 
        <div style='position:absolute;top:60px;left:10px'> <input id=0  /> </div>
        <div style='position:absolute;top:90px;left:10px'> <input id=1  /> </div>
        <div style='position:absolute;top:190px;left:10px'> <input id=2  /> </div>
        <div style='position:absolute;top:223px;left:10px'> <input id=3  /> </div>
        <div style='position:absolute;top:310px;left:10px'> <input id=4  /> </div>
        <div style='position:absolute;top:341px;left:10px'> <input id=5  /> </div>
        

       </div>
      
        
        `,
        answer: [
          "I can stay calm before exams.",
          "I can concentrate well for a short time.",
          "I must remember to take breaks!",
          "I mustn't check my phone when I'm studying.",
          "If I take regular breaks, I'll learn more.",
          "If I stopped checking my phone, I could study faster.",
        ],
      },
    ],
  },
};

export default json;
