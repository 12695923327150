import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "LFP-Starter Unit 4",
    id: "SW9-2024-LF-U4-P64-E1",
    exerciseKey: "/img/FriendsPlus/Page64/Key/E1answerkey.jpg",
    audio: "",
    video: "",
    component: Circle_Write,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontWeight: "bold",
      width: 250,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Choose the correct words.`,
        left: -30,
        star: 0,
        color: "#4E3E94",
        colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          background: "none",
          fontSize: 24,
          // borderBottom: "none",
          borderBottom: "2px dotted",
          width: 200,
          height: 32,
          paddingTop: 2,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        // TickRong: true,
        initialWordStyle: {
          // marginLeft: 10,
          padding: "1px 3px",
          border: "2px solid",
          borderRadius: 5,
          // color: "transparent",
          borderColor: "white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 3px",
          // borderRadius: 5,
          borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: [
          "must / might",
          "might / must",
          "may / must",
          "might / must",
          "must / can’t",
          "can’t / might",
          "could / can’t",
        ],
        answers: ["1-4", "0-0", "2-0", "3-4", "4-4", "5-4", "6-0"],
        initialValue: ["0-0"],
      },
      Layout: `
 
            Sienna looks annoyed. She <input id=0 type=Circle /> be angry with us, but we aren’t sure.
            <div style='line-height:45px;width:27cm'>
              <b>1</b>&ensp;My neighbour has won a lot of money on the lottery. He <input id=1 type=Circle /> be really happy!  <br>
              <b>2</b>&ensp;Deniz and Kadir <input id=2 type=Circle /> play tennis with us later, but they haven’t phoned yet.  <br>
              <b>3</b>&ensp;The postman always comes at exactly this time. It <input id=3 type=Circle /> be him at the door.  <br>
              <b>4</b>&ensp;Your answer to the maths question is 28. That <input id=4 type=Circle /> be right. It needs to be over 30.  <br>
              <b>5</b>&ensp;‘Is that Gemma at the bus stop?’  ‘Yes, it <input id=5 type=Circle /> be her.’  <br>
              <b>6</b>&ensp;Justin Bieber is doing a concert in town. There’s a chance that I <input id=6 type=Circle /> see him.  <br>
            </div>

  `,
    },
  },
  2: {
    unit: "LFP-Starter Unit 4",
    id: "SW9-2024-LF-U4-P64-E2",
    exerciseKey: "/img/FriendsPlus/Page64/Key/E2answerkey.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 20,
      // fontWeight: "bold",
      width: 300,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the affirmative or
negative form of <i>can, could</i> and <i>will be able to</i> and
the verbs in brackets.`,
        left: 50,
        width: "27cm",
        // star: 0,
        color: "#4E3E94",
        // colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
  
        My brother is learning to drive. He <u style='text-decoration-style: dotted'>&emsp;’ll be able to take&emsp;</u> me to school one day! (take)
 
        <div style='width:27cm'>
            <b>1</b>&ensp;We had a terrible start to our holiday. We the hotel in the dark. #(find)<br>
            <b>2</b>&ensp;That actor is amazing. He a lot of different roles. #(play)<br>
            <b>3</b>&ensp;Our grandfather was a talented athlete. He at the age of three. #(swim)<br>
            <b>4</b>&ensp;I’m not very good at this new video game! I the rules. #(understand)<br>
            <b>5</b>&ensp;Emily has broken her leg. She far on our trip next week. #(walk)<br>
          </div>
      `,
        answer: [
          "couldn't find / could not find",
          "can play / 's able to play / is able to play",
          "could swim",
          "can't understand / can not understand",
          "won't be able to walk",
        ],
      },
    ],
  },
  3: {
    unit: "LFP-Starter Unit 4",
    id: "SW9-2024-LF-U4-P64-E3",
    exerciseKey: "/img/FriendsPlus/Page64/Key/E3answerkey.jpg",
    audio: "",
    video: "",

    stylesTextInput: {
      textAlign: "center",
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 20,
      // fontWeight: "bold",
      width: 150,
      paddingTop: 10,
      // marginLeft: 20,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the dialogue with the correct form of
<i>can, could</i> and <i>be able to.</i>`,
        left: 50,
        star: 0,
        color: "#4E3E94",
        // colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    component: T6,
    questions: [
      {
        title: `

        <div style='display:flex'>
          <div>
            <b>Ben</b><br><br>
            <b>Sara</b><br><br><br>
            <b>Ben</b><br>
            <b>Sara</b><br><br>
            <b>Ben</b><br><br>
            <b>Sara</b><br>
          </div>
          <div style='width:20cm;margin-left:20px'>
            Look at me in this photo on the beach
            when I was six. I was afraid to go in the
            sea because I <u style='text-decoration-style: dotted'>&emsp;couldn’t&emsp;</u> swim.
            <br> You look very worried! I <sup>1</sup># go in
            the sea without any problems at that age
            because I’d had swimming lessons. But I <br>
            <sup>2</sup># dive or jump in.
            <br> I <sup>3</sup># believe that I used to be
            afraid of the water!
            <br> Ha ha! Yes, you’ve really changed. You
            <sup>4</sup># swim so well now and you’re
            great at cycling, too.
            <br> Thanks! I’m going to keep training and next
            year I might <sup>5</sup># enter that
            triathlon in town.
            <br> Cool! <sup>6</sup># we # come and
            watch you do it?
          </div>

        
        </div>
        
        `,
        answer: [
          "could",
          "couldn't|could not",
          "can't|can not",
          "can",
          "be able to",
          "Will",
          "be able to",
        ],
      },
    ],
  },
  4: {
    unit: "LFP-Starter Unit 4",
    id: "SW9-2024-LF-U4-P64-E2",
    exerciseKey: "img/FriendsPlus/Page64/Key/E4answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "4",
        title: `Correct the mistakes in bold.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#4D3C98",
      justifyContent: "center",
      width: "11cm",
      marginBottom: 10,
      marginLeft: 200,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>help</s>",
          "not like",
          "live",
          "phone",
          "plan",
          "start",
          "not watch",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      marginLeft: 20,
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 700,
      paddingTop: 10,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `

        You <b style='text-decoration:line-through'>not have to</b> pay for this book. It’s free. <u style='text-decoration-style: dotted'>&emsp;don't have to&emsp;</u>
        <div style='width:27cm'>
        
        <b>1</b>&ensp;Jennifer <b>have to</b> do an exam later. <br>#<br>
        <b>2</b>&ensp;Vadim <b>shoulds</b> concentrate more in class. <br>#<br>
        <b>3</b>&ensp;<b>Have we</b> to read this for our homework? <br>#<br>
        <b>4</b>&ensp;You <b>must to</b> improve your fitness. <br>#<br>
        <b>5</b>&ensp;We <b>don’t must</b> run in the corridors at school.  <br>#<br>
       
        </div>
        
        `,
        answer: [
          "Jennifer has to do an exam later.",
          "Vadim should concentrate more in class.",
          "Do we have to read this for our homework?",
          "You must improve your fitness.",
          "We mustn't run in the corridors at school.",
        ],
      },
    ],
  },
  5: {
    unit: "LFP-Starter Unit 4",
    id: "SW9-2024-LF-U4-P64-E2",
    exerciseKey: "img/FriendsPlus/Page64/Key/E5answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the present or present
continuous form of the verbs in brackets.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      marginLeft: 140,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "doesn’t have to",
          "don’t have to have to",
          "must",
          "mustn’t",
          "should",
          "shouldn’t",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 200,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>

       <div style='position:relative'>
          <img src="img/FriendsPlus/Page64/E5/1.jpg" style='height:23cm'/>
          <div style='position:absolute;top:146px;left:43px;width:176mm'>
            Hi Jasmine,<br>
            I’m excited about the school holidays – it’s great that
            we don’t have to wake up early for six whole weeks!
            My mum is happy, too – she <sup>1</sup># shout
            ‘Hurry up, Ellen!’ every morning!
            It’s so cool that you’re going to Australia. Do you
            <sup>2</sup># get up early to go to the airport?
            I visited my cousins in Australia last year and we left
            our house at 4.30 a.m.!
            Don’t forget to prepare for the flight. It’s best to wear
            comfortable clothes – you <br><sup>3</sup># wear tight
            things. Also, you <br><sup>4</sup># try to sleep – it’s a
            good idea on a long flight.
            We saw amazing animals in Australia – kangaroos,
            koalas and a few snakes. You <sup>5</sup># be very
            careful of the snakes – some are poisonous and you
           <br> <sup>6</sup># go near them! Have a lovely time!
            I can’t wait to see your photos!<br>
            Ellen
          </div>
        
       </div>
        
        
        `,
        answer: [
          "doesn't have to",
          "have to",
          "should not|shouldn't",
          "should",
          "must",
          "must not|mustn't",
        ],
      },
    ],
  },
  6: {
    unit: "LFP-Starter Unit 4",
    id: "SW9-2024-LF-U4-P64-E6",
    exerciseKey: "img/FriendsPlus/Page64/Key/E6answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "6",
        title: `The bold words are in the wrong place in the
sentences. Correct the sentences.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      marginLeft: 20,
      width: 650,
      paddingTop: 10,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
   
        These products are useful and attractive <span style='text-decoration:line-through'>both</span> .<br>
        <u style='text-decoration-style: dotted'>These products are both useful and attractive.&emsp;&emsp;</u><br>
        <b>1</b>&ensp;This car isn’t only great but convenient <b>also</b>.<br>#<br>
        <b>2</b>&ensp;I bought a new coat a pair of jeans <b>as well as</b>.<br>#<br>
        <b>3</b>&ensp;This jacket is available <b>both</b> in blue and red.<br>#<br>
        <b>4</b>&ensp;I went to the shops and played <b>too</b> football.<br>#<br>
        <b>5</b>&ensp;This hat is not cheap <b>only</b> but also practical.<br>#<br>
        <b>6</b>&ensp;She often buys <b>as well</b> shoes <b>as</b> bags.<br>#<br>
        
        
        `,
        answer: [
          "This car isn't only great but also convenient.",
          "I bought a new coat as well as a pair of jeans.",
          "This jacket is available in both blue and red.",
          "I went to the shops and played football, too.",
          "This hat is not only cheap but also practical.",
          "She often buys shoes as well as bags.",
        ],
      },
    ],
  },
};

export default json;
