import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P26-E1",
    exerciseKey: "/img/FriendsPlus/Page26/key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the odd word out.`,
        left: -50,
        star: 1,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 20,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "stress / relaxation / embarrassment",
          "boredom / surprise / excitement",
          "worry / fear / anger",
          "excitement / fear / disgust",
          "anger / annoyance / sympathy",
        ],
        answers: ["0-4", "1-0", "2-8", "3-0", "4-8"],
        initialValue: [],
      },
      Layout: `
       <div > 
          <span style="font-weight:bold;">boredom</span> /
          <span style="font-weight:bold;border:1px solid black; border-radius:50%;padding:5px 10px">surprise</span> /
          <span style="font-weight:bold;">stress</span>
       
        </div>

       <div style='margin-top:20px'>
       <span style="margin-right:10px;font-weight:bold">1</span>
       <span><input id=0 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">2</span>
       <span><input id=1 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">3</span>
       <span><input id=2 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">4</span>
       <span><input id=3 type=Circle />.
        </span>
       </div>

        <div style='margin-top:10px'>
        <span style="margin-right:10px;font-weight:bold">5</span>
        <span><input id=4 type=Circle />.
          </span>
        </div>
       
       </div>
      `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U4-P26-E2",
    exerciseKey: "/img/FriendsPlus/Page26/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match some of the words from exercise 1
with 1–6. Then write the adjective form.
`,
        left: 50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>What is the name of the feeling when people … are very afraid of something? 
          <span style='border-bottom:2px dotted black;font-weight:bold'>fear</span> (n)
          <span style='border-bottom:2px dotted black;font-weight:bold'>frightened</span> (a)
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            <span>
              are unhappy because they have nothing to do?

            </span> 
            <input id=0 ></input> (n)
            <input id=1 ></input> (a)
          </div>
          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span> 
              feel shy and worry about what other people
              will think of them?

            </span>  <br>
            <input id=2 ></input> (n)
            <input id=3 ></input> (a)
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              really don’t like the taste or look of something?
            </span> 
            <input id=4 ></input> (n)
            <input id=5 ></input> (a)
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
              can easily understand another person’s problems? 
            </span> 
            <input id=6 ></input> (n)
            <input id=7 ></input> (a)
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
              are a bit angry? 
            </span> 
            <input id=8 ></input> (n)
            <input id=9 ></input> (a)
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">6</span>
            <span>
              feel worried because they have a lot of problems in
              their life?
            </span> <br>
            <input id=10 ></input> (n)
            <input id=11 ></input> (a)
          </div>

          
        </div>
      
        
        `,
        answer: [
          "boredom",
          "bored",
          "embarrassment",
          "embarrassed",
          "disgust",
          "disgusted",
          "sympathy",
          "sympathetic",
          "annoyance",
          "annoyed",
          "stress",
          "stressed",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P26-E3",
    exerciseKey: "/img/FriendsPlus/Page26/key/e3-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Choose the correct words in the online
        comments.`,
        left: -50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontSize: 22,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "sympathetic / surprised / worried",
          "angry / relaxed / annoyed",
          "boring / disgusting / stressful",
          "embarrassed / excited / surprised",
          "worrying / exciting / relaxing!",
          "annoying / sympathetic / afraid",
        ],
        answers: ["0-8", "1-4", "2-8", "3-0", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
       <div style='position:relative;margin-top:20px'> 
          <img src='/img/FriendsPlus/Page26/11.jpg' style='width:700px'/>
          <div style='position:absolute;top:230px;left:315px'>  <input id=0 type=Circle />  </div>
          <div style='position:absolute;top:387px;left:154px'>  <input id=1 type=Circle />  </div>
          <div style='position:absolute;top:444px;left:229px'>  <input id=2 type=Circle />  </div>
          <div style='position:absolute;top:511px;left:107px'>  <input id=3 type=Circle />  </div>
          <div style='position:absolute;top:633px;left:49px'>  <input id=4 type=Circle />  </div>
          <div style='position:absolute;top:705px;left:39px'>  <input id=5 type=Circle />  </div>
       
       </div>
      `,
    },
  },
  4: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P26-E4",
    exerciseKey: "/img/FriendsPlus/Page26/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      // borderBottom: "2px dotted",
      width: 160,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bored / boring",
          "embarrassed / embarrassing",
          "excited / exciting",
          "frightened / frightening",
          "relaxed / <s>relaxing</s>",
          "surprise / surprising",
          "worried / worrying",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Use one word from each pair in the box to
complete the news article.
`,
        left: 50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page26/12.jpg' style='width:600px' />
          <div style='position:absolute;top:415px;left:407px'> <input id=0 />  </div>
          <div style='position:absolute;top:485px;left:61px'> <input id=1 />  </div>
          <div style='position:absolute;top:521px;left:49px'> <input id=2 />  </div>
          <div style='position:absolute;top:625px;left:133px'> <input id=3 />  </div>
          <div style='position:absolute;top:660px;left:284px'> <input id=4 />  </div>
          <div style='position:absolute;top:773px;left:22px'> <input id=5 />  </div>
          
        </div>
      
        
        `,
        answer: [
          "exciting",
          "worried",
          "boring",
          "frightened",
          "surprising",
          "embarrassed",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P26-E5",
    exerciseKey: "/img/FriendsPlus/Page26/key/e5-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 800,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `How do you feel in these situations? Write
two or three sentences using some of the words on this page.
`,
        left: 50,
        star: 3,
        color: "#0067b3",
        colorStar: "#95b0db",
        width: "27cm",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    KeyMau: true,
    isHello: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-top:20px;width:22cm'>
          <p>You go on a big roller coaster at a theme park. <br> 
          <span style='border-bottom:2px dotted black;font-weight:bold'>
            I get so excited on roller coasters. What I find most
            frightening is when I reach the top and look down!
          </span>
          
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            
            <span> 
             You need to perform in a school concert
            </span>  <br>
              
            <textarea rows=2 id=0 ></textarea>
          </div>
          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span> 
              It’s the weekend and you don’t have any plans.

            </span>  <br>
            <textarea rows=2 id=1 ></textarea> 
            
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              Your friend borrows something and doesn’t give it
              back to you.
            </span> 
            <textarea rows=2 id=2 ></textarea> 
            
          </div>

        </div>
      
        
        `,
        answer: [
          "I'm not that bothered by concerts. I don't usually get embarrassed or stressed.",
          "There's nothing boring about staying at home. It makes me feel relaxed.",
          "I get frustrated when people do this. What I find most annoying is when they lie about it.",
        ],
      },
    ],
  },
};

export default json;
