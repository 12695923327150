import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit1",
    id: "SW9-2024-U1-P10-E1",
    exerciseKey: "img/FriendsPlus/Page10/Key/E1answerKey.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words.`,
        color: "#f88125",
        left: -50,
        star: 1,
        colorStar: "#fdcaa2",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "brand_new / used",
          "rare / common",
          "damaged / undamaged ",
          "worthless / valuable",
          "Old-fashioned / Fashionable",
          "useful / useless",
        ],
        answers: ["1-0", "2-0", "3-4", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
       <div>
        <div style='display:flex'>
          <div style='margin-right:100px'>
            <img src="img/FriendsPlus/Page10/E1/1.jpg" style='height:6cm'/>
            <div style='text-align:center;width:70mm'>Some <span style="border: 2px solid rgb(0, 174, 239); font-weight: bold; border-radius: 50%;"><span>brand new</span></span> / <span style="border: 2px solid transparent; font-weight: bold;"><span>used</span></span> trainers. </div>
          </div>
          <div>
            <img src="img/FriendsPlus/Page10/E1/2.jpg" style='height:6cm'/>
            <div style='text-align:center;width:70mm'><b>1</b>&ensp;A <input id=1 type=Circle /> animal. </div>
          </div>
        </div>
        <div style='display:flex'>
          <div style='margin-right:100px'>
            <img src="img/FriendsPlus/Page10/E1/3.jpg" style='height:6cm'/>
            <div style='text-align:center;width:70mm'><b>2</b>&ensp;A(n) <input id=2 type=Circle /> vase.</div>
          </div>
          <div>
            <img src="img/FriendsPlus/Page10/E1/4.jpg" style='height:6cm'/>
            <div style='text-align:center;width:70mm'><b>3</b>&ensp;A <input id=3 type=Circle /> ring. </div>
          </div>
        </div>
        <div style='display:flex;margin:20px 0px'>
          <div style='margin-right:100px'>
            <img src="img/FriendsPlus/Page10/E1/5.jpg" style='height:6cm'/>
            <div style='text-align:center;width:70mm'><b>4</b>&ensp; <input id=4 type=Circle /> clothes.</div>
          </div>
          <div>
            <img src="img/FriendsPlus/Page10/E1/6.jpg" style='height:6cm'/>
            <div style='text-align:center;width:70mm'><b>5</b>&ensp;A <input id=5 type=Circle /> tool. </div>
          </div>
        </div>
       
       </div>
      
      `,
    },
  },
  2: {
    unit: "Unit1",
    id: "SW9-2024-U1-P10-E2",
    exerciseKey: "img/FriendsPlus/Page10/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Complete the blog post with the words.`,
        color: "#f88125",
        width: "27cm",
        left: 50,
        star: 2,
        colorStar: "#fdcaa2",
      },
    ],
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "common",
          "damaged",
          "popular",
          "<s>rare</s>",
          "used",
          "useless",
          "valuable",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "2px dotted",
      borderBottom: "none",
      width: 165,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
      <div style='position:relative'>
        <div style='position:absolute;top:176px;left:507px'>#</div>
        <div style='position:absolute;top:210px;left:185px'>#</div>
        <div style='position:absolute;top:245px;left:340px'>#</div>
        <div style='position:absolute;top:323px;left:64px'>#</div>
        <div style='position:absolute;top:393px;left:339px'>#</div>
        <div style='position:absolute;top:506px;left:33px'>#</div>
        <img src="img/FriendsPlus/Page10/E2/1.jpg" style='height:17cm'/>
      </div>
        
        `,
        answer: ["common", "valuable", "popular", "damaged", "used", "useless"],
      },
    ],
  },

  3: {
    unit: "Unit1",
    id: "SW9-2024-U1-P10-E3",
    exerciseKey: "img/FriendsPlus/Page10/Key/E3answerKey.png",
    audio: "Audios/1.02.mp3",
    video: "",
    // textAlign:'center',
    stylesTextInput: {
      background: "none",
      // borderBottom: "2px dotted",
      borderBottom: "none",
      width: 240,
      // padding: 0,
      fontSize: 23,
      paddingTop: 10,
      textAlign: "center",
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "awesome places to visit",
          "big hotels",
          "cool shops and markets",
          "fun entertainment",
          "historic buildings",
          "nice parks to walk in",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=02  ></headphone> Listen and choose the
correct answer.`,
        width: "27cm",
        color: "#f88125",
        left: -50,
        star: 2,
        colorStar: "#fdcaa2",
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a <br> b <br> c"],
        answers: ["0-8"],
        initialValue: [],
      },
      Layout: `
      The programme is … 
       <div style='display:flex'>
          <div>
            <input id=0 type=Circle /> 
          </div>
           <div style='margin-left:5px'>
            a conversation about the latest action figures.<br>
            an informative programme about the history of collecting action figures.<br>
            an interview with an action figure collector.<br>
           </div>
       </div>
      
      `,
    },
  },
  4: {
    unit: "Unit1",
    id: "SW9-2024-U1-P10-E4",
    exerciseKey: "img/FriendsPlus/Page10/Key/E4answerKey.png",
    audio: "Audios/1.02.mp3",
    video: "",
    component: T6,
    textAlign: "center",
    // isHello:true,
    stylesTextInput: {
      // borderBottom: "2px dotted",
      width: 150,
      // marginLeft: 20,
      borderBottom: "none",
      background: "none",
      paddingTop: 10,
      paddingBottom: 10,
      padding: 0,
    },
    textareaStyle: {
      width: 700,
      resize: "none",
      paddingTop: 3,
      marginBottom: -23,
      marginLeft: 17,
      marginTop: -3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["finish", "go", "lose", "not eat", "not fly", "not see"],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=02  ></headphone> Complete Bill’s tips with the
adjectives he uses. Listen again and check.
`,
        color: "#f88125",
        width: "25cm",
        left: 50,
        star: 2,
        colorStar: "#fdcaa2",
      },
    ],
    // KeyMau: true,
    // isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
          <div style='position:absolute;top:136px;left:85px'>#</div>
          <div style='position:absolute;top:179px;left:335px'><input maxlength=9 /></div>
          <div style='position:absolute;top:223px;left:343px'>#</div>
          <div style='position:absolute;top:266px;left:333px'>#</div>
          <div style='position:absolute;top:298px;left:260px'>#</div>
          <div style='position:absolute;top:341px;left:368px'>#</div>
          <div style='position:absolute;top:373px;left:356px'>#</div>
          <div style='position:absolute;top:416px;left:416px'><input maxlength=11 /></div>
          
          
            <img src="img/FriendsPlus/Page10/E4/1.jpg" style='height:13cm'/>
        </div>





          
        `,
        answer: [
          "Brand new",
          "undamaged",
          "tough",
          "damaged",
          "worthless",
          "Unpopular",
          "popular",
          "fashionable",
        ],
      },
    ],
  },
  5: {
    unit: "Unit1",
    id: "SW9-2024-U1-P10-E5",
    exerciseKey: "img/FriendsPlus/Page10/Key/E5answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 850,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: {
      marginBottom: -23,
      width: 750,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "brother(s) / sister(s)",
          "live in …",
          "name",
          "used to play / go / like",
        ],
        width: 600,
        borderColor: "#f88125",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Answer the questions with your own ideas.`,
        color: "#f88125",
        left: 50,
        star: 3,
        width: "26cm",
        colorStar: "#fdcaa2",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:20cm'>
          Which action figures do you think are more popular –
          <i>Star Wars</i> or <i>Spider-Man</i> figures?<br>
            <u style='text-decoration-style: dotted'>Definitely Star Wars figures because the characters
            are unique and the films are so popular.&emsp;&emsp;&emsp;</u> <br>
            <b>1</b>&ensp;Do you or does anyone you know collect things? What condition are they in? <textarea id=0 rows=2 ></textarea>
            <b>2</b>&ensp;Do you think it is more important for clothes to be fashionable or comfortable? <textarea id=1 rows=2 ></textarea>
            <b>3</b>&ensp;Is it better to buy brand new things or used things? Why? <textarea id=2 rows=2 ></textarea>
            <b>4</b>&ensp;What is the most useful or useless app that you have used? Why was it useful or useless? <textarea id=3 rows=2 ></textarea>
        </div>

        `,
        // initialValue: ["Hey, it's Derin, isn't it?"],
        answer: [
          "I collect Twilight memorabilia. Most of it is quite common, but undamaged.",
          "I think that in general it's more important for clothes to be comfortable to wear.",
          "I think it's better to buy used things because it helps the environment.",
          "The most useful app I've used is a transport app because it shows you the quickest and cheapest ways to travel.",
        ],
      },
    ],
  },
};

export default json;
