import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P51-E1",
    exerciseKey: "/img/FriendsPlus/Page51/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["could", "’d", "had to", "knew", "was", "would"],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `  Complete the table with the words.

`,
        left: 50,
        star: 1,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page51/9.jpg' style='width:600px' />
          <div style='position:absolute;top:177px;left:277px' > <input id=0 /> </div>
          <div style='position:absolute;top:283px;left:277px' > <input id=1 /> </div>
          <div style='position:absolute;top:421px;left:261px' > <input id=2 /> </div>
          <div style='position:absolute;top:511px;left:261px' > <input id=3 /> </div>
          <div style='position:absolute;top:613px;left:261px' > <input id=4 /> </div>

        </div>
      
        `,
        answer: ["was", "'d", "could", "would", "had to"],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P51-E2",
    exerciseKey: "/img/FriendsPlus/Page51/key/e2-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 200,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `  Choose the correct verb and complete the
        reported speech sentences.
`,
        left: -50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: [],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "58px",
              left: "232px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          // borderBottom: "none",
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [
          "were searching",
          "'d seen|had seen",
          "could read",
          "had to finish",
          "'d ridden|had ridden",
          "had to learn",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "said / told",
          "said / told",
          "said / told",
          "said / told",
          "said / told",
          "said / told",
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
        
        <div style='margin-top:20px;position:relative'>
            ‘The two brothers are very talented producers.’ People
            <span > <b> <span > said </span> / <span style='border:1px solid;border-radius:50%;'> told </b> </span>
            us that the two brothers <b> <span style='border-bottom:1px dotted'>were</span> </b> <br>
            very talented producers.

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span >  ‘We’re searching for two pickpockets.’ The police officers
              <input id=0 type=Circle /> that they <br>
              <input id=0 />  for two pickpockets.
             </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span >  ‘We saw the children playing football outside.’ They
              <input id=1 type=Circle />  me that they <br>
              <input id=1 />  the children playing football outside.
             </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span >  ‘You can read all about cybercrime online.’ He
              <input id=2 type=Circle />   us that we <br>
              <input id=2 />  all about cybercrime online.
             </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > ‘The students must finish the assignment soon.’ They
              <input id=3 type=Circle />   that the students <br>
              <input id=3 />  the assignment soon.
             </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span > ‘I’ve ridden the roller coaster for the first time.’ Irina
              <input id=4 type=Circle /> that she <br>
              <input id=4 /> the roller coaster for the first time.
             </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 6 </span>
            <span > ‘You have to learn more about social media.’ I
              <input id=5 type=Circle />  my grandparents that they <br>
              <input id=5 /> more about social media.
             </span>
          </div>

         

       </div>
      `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P51-E3",
    exerciseKey: "/img/FriendsPlus/Page51/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 450,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["could", "’d", "had to", "knew", "was", "would"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `  Complete the police notes from an
        interview with the woman in the headline. Use the
        correct form of say or tell and reported speech.

`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <img src='/img/FriendsPlus/Page51/10.jpg' style='width:700px;margin-left:100px' />
          
                <div style='margin-top:20px;'>
                  <span> She </span>
                  <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> said </span>
                  that she
                  <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> would tell them everything </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > She </span> 
                    <input id=0 width=100px />
                     me that she
                    <input id=1 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                    <span > She </span> 
                    <input id=2 width=100px />
                     that she
                    <input id=3 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                    <span > She </span> 
                    <input id=4 width=100px />
                     us that the driver
                    <input id=5 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 4 </span> 
                    <span > She </span> 
                    <input id=6 width=100px />
                     that she
                    <input id=7 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 5 </span> 
                    <span > She </span> 
                    <input id=8 width=100px />
                     that she
                    <input id=9 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 6 </span> 
                    <span > She </span> 
                    <input id=10 width=100px />
                     that we
                    <input id=11 />
                </div>
         
        </div>
      
        `,
        answer: [
          "told",
          "worked at the shop during the day",
          "said",
          "'d left her phone at work|had left her phone at work",
          "told",
          "was drunk",
          "said",
          "couldn't believe her eyes|could not believe her eyes",
          "said",
          "would never drive fast",
          "said",
          "had to believe her",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P51-E4",
    exerciseKey: "/img/FriendsPlus/Page51/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 900,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "brother / sister",
          "friend(s)",
          "mum / dad",
          "neighbour",
          "teacher",
          "your classmate(s)",
          "go",
          "like",
          "meet",
          "play",
          "see",
          "take",
          "watch",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  What have four people said to you in the
        last week? Use say or tell, reported speech and the
        words in the box or your own ideas.


`,
        left: 50,
        star: 3,
        width: "27cm",
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    KeyMau: true,
    isHello: true,
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;'>
          
                <div style='margin-top:20px;width:25cm'>
                  <span style='border-bottom:2px dotted;margin-right:10px'>
                    My friend Natalia told me that she’d watched the new
                    detective series online last weekend.
                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                    &emsp;&emsp;&ensp;
                  </span>
                  <input id=0 /> <br>
                  <input id=1 /> <br>
                  <input id=2 /> <br>
                  <input id=3 /> <br>
                  <input id=4 />
                </div> <br>
          
         
        </div>
      
        `,
        InputRong: true,
        answer: [
          "My dad said that he liked his new job.",
          "Our teacher said that she would take us to the science museum next month.",
          "",
          "",
          "",
        ],
      },
    ],
  },
};
export default json;
