import React from "react";
import ReactHtmlParser from "react-html-parser";

export const DrawCircleArray = ({
  isHello,
  userAnswers,
  isDoing,
  listBoolean,
  circleColor,
  padding,
  path,
}) => {
  //['0-3', '1-4', '2-5']
  if (!userAnswers) return null;
  return userAnswers.map((item, index) =>
    DrawCircle({
      isHello,
      item,
      isDoing,
      listBoolean,
      circleColor,
      padding,
      path,
    })
  );
};

export const DrawCircle = ({
  isHello,
  item,
  isDoing,
  listBoolean,
  circleColor,
  padding,
  path,
}) => {
  // const [scrollLeft, setScrollLeft] = React.useState(0);
  // const [scrollTop, setScrollTop] = React.useState(0);
  // React.useEffect(() => {
  //   const handleScroll = () => {
  //     setScrollLeft(document.documentElement.scrollLeft);
  //     setScrollTop(document.documentElement.scrollTop);
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  // if (!item) return null;

  // const calculatePosition = (rect) => {
  //   const { x, y, width, height } = rect;
  //   return {
  //     x: x + scrollLeft,
  //     y: y + scrollTop,
  //     // x,
  //     // y,
  //     width,
  //     height,
  //   };
  // };
  const [first, second] = item.split("-").sort((a, b) => {
    if (a.split("_")[0] === b.split("_")[0])
      return a.split("_")[1] - b.split("_")[1];
    return a.split("_")[0] - b.split("_")[0];
  });
  const [paddingY, paddingX] = padding?.split(" ") || [0, 0];
  if (!first || !second) return null;
  let color = circleColor || "gray";
  if (isDoing === false) {
    const isCorrect = listBoolean[`${first}-${second}`];
    if (isHello !== true) {
      color = isCorrect ? "green" : "red";
    } else {
      color = "green";
    }
  }
  if (path === true) {
    const path = document.getElementById("match-path");
    if (!path) return null;
    const firstIndex = Number(first.split("_")[1]);
    const secondIndex = Number(second.split("_")[1]);
    if (
      firstIndex !== 0 &&
      (!firstIndex || !secondIndex || firstIndex === secondIndex)
    ) {
      return null;
    }
    const firstLoc = path.getPointAtLength(firstIndex * 22);
    let newPath = `M${firstLoc.x},${firstLoc.y - 40} `;
    for (let i = firstIndex + 1; i <= secondIndex; i++) {
      let p = path.getPointAtLength(i * 22);
      newPath += `L${p.x},${p.y - 40} `;
    }
    for (let i = secondIndex; i >= firstIndex; i--) {
      let p = path.getPointAtLength(i * 22);
      if (i === secondIndex)
        newPath += `C${p.x + 15},${p.y - 35} ${p.x + 15},${p.y - 5} ${p.x},${
          p.y
        }`;
      newPath += `L${p.x},${p.y} `;
    }
    newPath += `C${firstLoc.x - 15},${firstLoc.y - 5} ${firstLoc.x - 15},${
      firstLoc.y - 35
    } ${firstLoc.x},${firstLoc.y - 40} `;
    let container = document.getElementById("circle-container");
    return (
      <svg
        style={{ position: "absolute", top: 0, left: 0 }}
        width={getComputedStyle(container).width}
        height={getComputedStyle(container).height}
      >
        <path d={newPath} stroke={color} fill="transparent" strokeWidth="2" />
      </svg>
    );
  }

  const firstObject = document.getElementsByClassName(`dot-${first}`)[0];
  const secondObject = document.getElementsByClassName(`dot-${second}`)[0];
  const firstPosition = firstObject?.getBoundingClientRect();
  const secondPosition = secondObject?.getBoundingClientRect();
  if (!firstPosition || !secondPosition) return null;
  return (
    <div
      style={{
        position: "absolute",
        // left: calculatePosition(firstPosition).x - paddingX,
        // top: calculatePosition(firstPosition).y - paddingY,
        // width:
        //   calculatePosition(secondPosition).x -
        //   calculatePosition(firstPosition).x +
        //   calculatePosition(firstPosition).width +
        //   paddingX * 2,
        // height:
        //   calculatePosition(secondPosition).y -
        //   calculatePosition(firstPosition).y +
        //   calculatePosition(firstPosition).height +
        //   paddingY * 2,
        left: firstObject.offsetLeft - paddingX,
        top: firstObject.offsetTop - paddingY,
        width:
          secondObject.offsetLeft -
          firstObject.offsetLeft +
          firstObject.offsetWidth +
          paddingX * 2,
        height:
          secondObject.offsetTop -
          firstObject.offsetTop +
          firstObject.offsetHeight +
          paddingY * 2,
        border: `2px solid ${color}`,
        borderRadius: "50%",
        pointerEvents: "none",
      }}
    ></div>
  );
};

export function calculateResultLine(userAnswers, answers) {
  let userAnswer = userAnswers;
  if (userAnswers.length < answers.length) {
    userAnswer.push("");
  }

  const total = Math.max(userAnswers.length, answers.length); // tổng số đáp án hoặc tổng số đã chọn;
  const booleanArray = new Array(total);
  const listBoolean = {}; // biến hiển thị css đúng/sai
  userAnswer.forEach((item, index) => {
    const isCorrect = answers.includes(item);
    booleanArray[index] = isCorrect;
    Object.assign(listBoolean, { [item]: isCorrect });
  });

  // params
  const correct = booleanArray.reduce(
    (total, item) => (total += item ? 1 : 0),
    0
  );
  const percent = parseInt((correct * 100) / total); // tính %
  const resultString = `${correct}/${total}`; // điểm / tổng
  const star = percent / 20;
  return { listBoolean, booleanArray, percent, resultString, star };
}

function PointDotv2({
  id,
  data,
  margin,
  padding,
  border,
  transform,
  setData,
  sort,
}) {
  const { boxMatch } = data;

  if (data.path === true) {
    const path = document.getElementById("match-path");
    if (!path) return null;
    const index = id.split("_")[1];
    const loc = path.getPointAtLength(index * 22);
    return (
      <div
        className={`dot-${id}`}
        style={{
          cursor: "pointer",
          transform,
          border: border,
          padding,
          margin,
          fontSize: "24px",
          position: "absolute",
          left: loc.x - 10,
          top: loc.y - 40,
          ...boxMatch.find((value) => value.id === id).boxMatchStyle,
        }}
        // onClick={() => handleClick(id)}
      >
        {/* {dotSelect()} */}
        {ReactHtmlParser(boxMatch.find((value) => value.id === id).children)}
      </div>
    );
  }
  return (
    <div
      className={`dot-${id}`}
      style={{
        cursor: "pointer",
        transform,
        border: border,
        padding,
        margin,
        ...boxMatch.find((value) => value.id === id).boxMatchStyle,
      }}
      // onClick={() => handleClick(id)}
    >
      {/* {dotSelect()} */}
      {ReactHtmlParser(boxMatch.find((value) => value.id === id).children)}
    </div>
  );
}

export default PointDotv2;
