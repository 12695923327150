import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P49-E1",
    exerciseKey: "/img/FriendsPlus/Page49/key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Choose the correct words.
`,
        left: -50,
        star: 1,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "none",
          // borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "down / on",
          "across / around",
          "left / down",
          "up / on",
          "inside / up",
          "up / inside",
        ],
        answers: ["0-0", "1-0", "2-0", "3-4", "4-0", "5-0"],
        initialValue: [],
      },
      Layout: `
        
        <div style='margin-top:20px;position:relative'>
            When you walk
            <span > <b> <span >here</span> / <span style='border:1px solid;border-radius:50%;'> around </b> </span>
            the parliament building, you understand its importance.
          
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span >  If you look  </span>
            <input id=0 type=Circle />
            <span> from the tower, you can see the sights of the Canadian capital, Ottawa.  </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > The National Gallery of Canada is opposite. It’s </span>
            <input id=1 type=Circle />
            <span> the street from here. </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > Leave the entrance of the National Gallery and then turn </span>
            <input id=2 type=Circle /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > That famous building is </span>
            <input id=3 type=Circle /> 
             <span> a historic site next to the river. </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span > When you walk </span>
            <input id=4 type=Circle />
            <span> the spectacular building, you’ll notice the paintings at once. </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 6 </span>
            <span > If visitors look </span>
            <input id=5 type=Circle />
            <span> at the tall monument, they’ll see three statues. </span>
          </div>
        

         

       </div>
      `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P49-E2",
    exerciseKey: "/img/FriendsPlus/Page49/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 22,
      width: 180,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["across", "down", "inside", "<s>on</s>", "right", "there", "up"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `  Complete the tourist information leaflet about Ottawa with the words.

`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-left:150px'> <hintbox id=0 ></hintbox> </div>
        <div style='margin-top:20px;position:relative'>
            <img src='/img/FriendsPlus/Page49/9.jpg' style='width:600px;margin-left:150px' />
            <div style='position:absolute;top:283px;left:144px'> <input id=0  /> </div>
            <div style='position:absolute;top:340px;left:144px'> <input id=1  /> </div>
            <div style='position:absolute;top:428px;left:361px'> <input id=2  /> </div>
            <div style='position:absolute;top:485px;left:254px'> <input id=3  /> </div>
            <div style='position:absolute;top:630px;left:146px'> <input id=4  /> </div>
            <div style='position:absolute;top:660px;left:146px'> <input id=5  /> </div>
            
            
        </div>
      
        `,
        answer: ["up", "right", "inside", "there", "across", "down"],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P49-E3",
    exerciseKey: "/img/FriendsPlus/Page49/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 17,
      transform: "rotate(-1deg)",
      fontWeight: "bold",
      width: 230,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["across", "down", "inside", "<s>on</s>", "right", "there", "up"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `  Complete the tourist information leaflet with the phrases in bold from exercise 2.

`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
            <img src='/img/FriendsPlus/Page49/10.jpg' style='width:700px;margin-left:150px' />
            <div style='position:absolute;top:106px;left:423px'> <input id=0  /> </div>
            <div style='position:absolute;top:138px;left:199px'> <input id=1  /> </div>
            <div style='position:absolute;top:195px;left:205px'> <input id=2  /> </div>
            <div style='position:absolute;top:278px;left:206px'> <input id=3  /> </div>
            <div style='position:absolute;top:475px;left:206px'> <input id=4  /> </div>
            <div style='position:absolute;top:614px;left:211px'> <input id=5  /> </div>
            <div style='position:absolute;top:711px;left:301px'> <input id=6  /> </div>
            <div style='position:absolute;top:745px;left:213px'> <input id=7  /> </div>
            <div style='position:absolute;top:810px;left:213px'> <input id=8  /> </div>
            
            <img src='/img/FriendsPlus/Page49/9.jpg' style='width:600px;margin-left:200px;margin-top:40px' />
            
        </div>
      
        `,
        answer: [
          "The",
          "was built in",
          "look",
          "is located in",
          "is well worth a visit",
          "Look left",
          "is one of the",
          "monuments",
          "It was installed in",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P49-E4",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 17,
      transform: "rotate(-1deg)",
      fontWeight: "bold",
      width: 230,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["across", "down", "inside", "<s>on</s>", "right", "there", "up"],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  Write part of an information leaflet
        about a famous building or monument that
        you have visited or would like to visit. Use the
        examples in exercises 2 and 3 to help you.

`,
        left: 50,
        star: 3,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    hideBtnFooter: true,
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
            <p style='font-weight:bold'> Include some of the following information:</p>
            <ul>
              <li>When it was built and where it is located</li>
              <li>What style it is and what it is made from</li>
              <li>What you can see if you stand in front of it</li>
              <li>What you can find inside it and see from it</li>
              <li>Why it is important</li>
            </ul>
            <textarea id=0 rows=10 ></textarea>
            
            
        </div>
      
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
