import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P35-E1",
    exerciseKey: "/img/FriendsPlus/Page35/key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words.
`,
        left: -50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "move / moved",
          " ’ll&nbsplook / look",
          " ’ll&nbspsee / see",
          "go / went",
          "didn’t / wouldn’t",
        ],
        answers: ["0-0", "1-6", "2-2", "3-4", "4-4"],
        initialValue: [],
      },
      Layout: `
       <div style='margin-top:20px;position:relative'>
        <img src='/img/FriendsPlus/Page35/11.jpg' style='width:1000px' />
        <div style='position:absolute;top:54px;left:663px;font-size:19px;font-weight:bold'> <input id=0 type=Circle />  </div>

        <div style='position:absolute;top:84px;left:304px;font-size:19px;font-weight:bold'> <input id=1 type=Circle />  </div>

        <div style='position:absolute;top:84px;left:662px;font-size:19px;font-weight:bold'> <input id=2 type=Circle />  </div>

        <div style='position:absolute;top:114px;left:375px;font-size:19px;font-weight:bold'> <input id=3 type=Circle />  </div>

        <div style='position:absolute;top:113px;left:652px;font-size:19px;font-weight:bold'> <input id=4 type=Circle />  </div>


       </div>
        

        
      `,
    },
  },
  2: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P35-E2",
    exerciseKey: "/img/FriendsPlus/Page35/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
      fontSize: 21,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "become", "jump", "<s>look</s>", "not fall", "not stay"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Complete the zero conditional sentences
          with the correct form of the verbs in the box.
`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        <div style='margin-left:200px'><hintbox id=0 ></hintbox></div>
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page35/12.jpg' style='width:600px;margin-left:200px' />
          <div style='position:absolute;top:220px;left:443px'> <input id=0 />  </div>
          <div style='position:absolute;top:261px;left:372px'> <input id=1 />  </div>
          <div style='position:absolute;top:336px;left:596px'> <input id=2 />  </div>
          <div style='position:absolute;top:409px;left:324px'> <input id=3 />  </div>
          <div style='position:absolute;top:481px;left:350px'> <input id=4 />  </div>

        </div>
      
        
        `,
        answer: [
          "is",
          "jumps",
          "doesn't fall|does not fall",
          "becomes",
          "doesn't stay|does not stay",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P35-E3",
    exerciseKey: "/img/FriendsPlus/Page35/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "become", "jump", "<s>look</s>", "not fall", "not stay"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `  Complete the zero conditional sentences
        with the correct form of the verbs in the box.
`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          If I  <span style='border-bottom:1px dotted;font-weight:bold'>invented</span> (invent) the most intelligent robot in
            the world, I <span style='border-bottom:1px dotted;font-weight:bold'>’d be</span> (be) really famous!
                  
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span > We </span>
            <input id=0 /> (visit) the science museum tomorrow unless the weather
            <input id=1 />  (be) bad.
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <input id=2 />
            <span > (not be) surprised if astronauts </span>
            <input id=3 /> (go) to Jupiter in the next sixty years.
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > Unless Monica  </span>
            <input id=4 /> (study) for her science exam, she
            <input id=5 /> (do) <br> badly - just like last year.
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > What </span>
            <input id=6 width=210px /> (people / do) if a big meteor suddenly
            <input id=7 /> (hit) the Earth?
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span > He </span>
            <input id=8 /> (not win) the competition next week unless he
            <input id=9 /> (change) <br> his design.
          </div>

          


        </div>
      
        
        `,
        answer: [
          "'ll visit|will visit",
          "is",
          "wouldn't be|would not be",
          "went",
          "studies",
          "'ll do|will do",
          "would people do",
          "hit",
          "won't win|will not win",
          "changes",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P35-E4",
    exerciseKey: "/img/FriendsPlus/Page35/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "become", "jump", "<s>look</s>", "not fall", "not stay"],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  Complete the film review with the correct form of the verbs in brackets.

`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
         <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page35/13.jpg' style='width:600px;margin-left:200px' />
          <div style='position:absolute;top:121px;left:422px'> <input id=0 />  </div>
          <div style='position:absolute;top:305px;left:266px'> <input id=1 />  </div>
          <div style='position:absolute;top:341px;left:371px'> <input id=2 />  </div>
          <div style='position:absolute;top:483px;left:252px'> <input id=3 />  </div>
          <div style='position:absolute;top:595px;left:492px'> <input id=4 />  </div>
          <div style='position:absolute;top:667px;left:221px'> <input id=5 />  </div>
        </div>
      
        
        `,
        answer: ["had", "could", "succeeded", "found", "would", "enjoyed"],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P35-E5",
    exerciseKey: "/img/FriendsPlus/Page35/key/e5-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 500,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "become", "jump", "<s>look</s>", "not fall", "not stay"],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: ` Complete the sentences with conditionals or wish form and your own ideas.

`,
        left: 50,
        star: 3,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    character: ",",
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <span style='border-bottom:1px dotted;font-weight:bold'>Humans wish</span>
          they could perform time travel in the near future. 
                  
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span > I wish medicine experts </span>
            <input id=0 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > Unless scientists do experiments, </span>
            <input id=1 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > If I travelled to the moon, </span>
            <input id=2 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > Water evaporates if </span>
            <input id=3 /> 
          </div>

        </div>
      
        `,
        answer: [
          "created effective drugs to kill all bacteria.",
          "they won't find cures for diseases.",
          "I'd take lots of photos to show my friends!",
          "you heat it.",
        ],
      },
    ],
  },
};

export default json;
