import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P32-E1",
    exerciseKey: "/img/FriendsPlus/Page32/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 233,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "design",
          "discover",
          "experiment",
          "explore",
          "imagine",
          "<s>invent</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match the words with photos 1–5.


`,
        left: 50,
        star: 1,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],

    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page32/7.jpg' style='width:600px' />
          <div style='position:absolute;top:208px;left:336px'> <input id=0 />  </div>
          <div style='position:absolute;top:459px;left:29px'> <input id=1 />  </div>
          <div style='position:absolute;top:459px;left:336px'> <input id=2 />  </div>
          <div style='position:absolute;top:716px;left:29px'> <input id=3 />  </div>
          <div style='position:absolute;top:716px;left:336px'> <input id=4 />  </div>



        </div>
      
        
        `,
        answer: ["design", "imagine", "experiment", "discover", "explore"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U4-P32-E2",
    exerciseKey: "/img/FriendsPlus/Page32/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adapt",
          "become extinct",
          "create",
          "cure",
          "<s>develop</s>",
          "test",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match the verbs in the box with the
definitions. Then write the noun form of each verb.
`,
        left: 50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;'>
          <p>when a scientist or designer thinks of a new idea
            and makes it successful  <br>
          <span style='border-bottom:2px dotted black;font-weight:bold'>develop</span> (v)
          <span style='border-bottom:2px dotted black;font-weight:bold'>development</span> (n)
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            <span>
             when a person makes something new

            </span> 
            <input id=0 ></input> (v)
            <input id=1 ></input> (n)
          </div>
          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span> 
              when a doctor or scientist makes an illness go
              away

            </span>  
            <input id=2 ></input> (v)
            <input id=3 ></input> (n)
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              when an animal or a person changes their
              behaviour because they are in a new situation
            </span> 
            <input id=4 ></input> (v)
            <input id=5 ></input> (n)
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
               when a scientist uses or looks at something
                carefully to see how well it works <br>
            </span> 
            <input id=6 ></input> (v)
            <input id=7 ></input> (n)
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
              when an animal or a plant no longer exists
            </span> 
            <input id=8 ></input> (v)
            <input id=9 ></input> (n)
          </div>

                  
        </div>
      
        
        `,
        answer: [
          "create",
          "creation",
          "cure",
          "cure",
          "adapt",
          "adaptation",
          "test",
          "test",
          "become extinct",
          "extinction",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P32-E3",
    exerciseKey: "/img/FriendsPlus/Page32/key/e3-key.png",
    audio: "",
    video: "",
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      width: 50,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the
          questions with discovery
          and invention nouns.
          Then choose the correct answers.
`,
        left: -50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          // borderBottom: "none",
          borderBottom: "2px dotted",
          textAlign: "center",
          fontSize: 19,
          width: 130,
        },
        answers: [
          "design",
          "adaptation",
          "invention",
          "extinction",
          "discovery",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a|&nbsp&nbspa&nbsptrain&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|b|&nbsp&nbspa&nbspparachute&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|c|&nbspa&nbspcomputer|",
          "a|&nbspits&nbspweight&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|b|&nbspits&nbsplength&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|c|&nbsp&nbspits&nbspcolour|",
          "a|&nbsp&nbspthe&nbsptelephone&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|b|&nbsp&nbspthe&nbspradio&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|c|&nbsp&nbspthe&nbsptelevision|",
          "a|&nbsp&nbspthe&nbspEuropean&nbsprabbit&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|b|&nbsp&nbspthe&nbspgreater&nbspflamingo&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp <br>|c|&nbsp&nbspthe&nbspmountain&nbspgorilla|",
          "a|&nbsp&nbspthe&nbspuse&nbspof&nbspX-rays&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|b|&nbsp&nbsppenicillin<br>|c|&nbsp&nbspthe&nbspvaccine&nbspfor&nbsppolio|",
        ],
        answers: ["0-4", "1-8", "2-0", "3-10", "4-4"],
        initialValue: [],
      },
      Layout: `
       <div style='margin-top:20px;position:relative'>
        <img src='/img/FriendsPlus/Page32/8.jpg' style='width:600px;margin-left:200px'/> 
        <div style='position:absolute;top:314px;left:282px'> <input id=0  /> </div>
        <div style='position:absolute;top:354px;left:275px;font-size:19px'> <input id=0 type=Circle  /> </div>

        <div style='position:absolute;top:401px;left:284px'> <input id=1  /> </div>
        <div style='position:absolute;top:440px;left:275px;font-size:19px'> <input id=1  type=Circle /> </div>

        <div style='position:absolute;top:488px;left:319px'> <input id=2  /> </div>
        <div style='position:absolute;top:527px;left:275px;font-size:19px'> <input id=2  type=Circle /> </div>

        <div style='position:absolute;top:547px;left:544px'> <input id=3  /> </div>
        <div style='position:absolute;top:585px;left:275px;font-size:19px'> <input id=3  type=Circle /> </div>

        <div style='position:absolute;top:661px;left:285px'> <input id=4  /> </div>
        <div style='position:absolute;top:705px;left:275px;font-size:19px'> <input id=4  type=Circle /> </div>

        
        
        

       </div>
      `,
    },
  },
  4: {
    unit: "Unit 2",
    id: "SW9-2024-U4-P32-E4",
    exerciseKey: "/img/FriendsPlus/Page32/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 600,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adapt",
          "become extinct",
          "create",
          "cure",
          "<s>develop</s>",
          "test",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences about discoveries and
        inventions. Use some of the words on this page and your
        own ideas.
`,
        left: 50,
        star: 3,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    character: ",",
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>Cars are essential in the modern world. I think it’s
            hard to imagine life without  <br>
          <span style='border-bottom:2px dotted black;font-weight:bold'>the invention of the wheel
            because it’s so important for transport.</span>
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            <span>
             I use the internet every day
            </span>
            <input id=0 ></input>  are at the top of my list of useful inventions because
            <input id=1 ></input> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span> 
              Some diseases and illnesses no longer exist in the world. An important goal now is
            </span>  
            <input id=2 ></input> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              My smartphone always runs out of battery. I can’t think of anything better than
            </span> 
            <input id=4 ></input> 
          </div>

        </div>
      
        
        `,
        answer: [
          "Computers and smartphones",
          "I use them to find information on different websites.",
          "to find a cure for cancer.",
          "a phone that doesn't need a battery.",
        ],
      },
    ],
  },
};

export default json;
