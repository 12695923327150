import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Starter U6",
    id: "SW9-2024-Starter-U6-P58-E1",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` <span style='color:#574d89;font-weight:800;'>Speaking</span>  

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;color:rgb(87, 77, 137);' > 1 </span> 
                    <span > Look at the photo. Which planet do you think this
s                   cene shows? What is the astronaut doing?  </span>
                    <textarea rows=5 id=0 ></textarea>
                </div>
                <img src='/img/FriendsPlus/Page58/13.jpg' style='width:600px;margin-top:20px;margin-left:150px' />

        </div>
      
        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Starter U6",
    id: "SW9-2024-Starter-U6-P58-E2",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` <span style='color:#574d89;font-weight:800;'>Reading</span>  

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;color:rgb(87, 77, 137);' > 2 </span> 
                    <span > Read the formal letter. What would the students like
                    Professor Stewart to do?  </span>
                    <img src='/img/FriendsPlus/Page58/14.jpg' style='width:600px;margin-top:20px;margin-left:200px' /> <p></p>
                    <textarea rows=5 id=0 ></textarea>
                </div>

        </div>
      
        `,
        answer: [],
      },
    ],
  },
  3: {
    unit: "Starter U6",
    id: "SW9-2024-Starter-U6-P58-E3",
    exerciseKey: "/img/FriendsPlus/Page58/key/e3-key.jpg",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      // fontWeight: "bold",
      width: 900,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Read the letter again. Complete the questions with
        <i>how many, what, when, where, who or why</i>. Then answer them.

`,
        left: 50,
        star: 0,
        width: "27cm",
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <img src='/img/FriendsPlus/Page58/14.jpg' style='width:600px;margin-top:20px;margin-left:200px' /> <p></p>
                </div>
                <p></p>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    <input id=0 width=100px />
                    <span> are the students from?</span> <br>
                    <input id=1  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                    <input id=2 width=100px />
                    <span> are they interested in the series?</span> <br>
                    <input id=3  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                    <input id=4 width=100px />
                    <span> are they going to start the project?</span> <br>
                    <input id=5  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                    <input id=6 width=100px />
                    <span>  is their science teacher?</span> <br>
                    <input id=7  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>5</span>
                    <input id=8 width=100px />
                    <span> are they going to look at in particular? </span> <br>
                    <input id=9  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>6</span>
                    <input id=10 width=100px />
                    <span> questions do they ask? </span> <br>
                    <textarea id= 11 rows=3></textarea>
                </div>

        </div>
      
        `,
        answer: [
          "Where",
          "Blackburn School in Lancashire",
          "Why",
          "they are aiming to study astronomy or astrophysics at university",
          "When",
          "next month",
          "Who",
          "Ms Best",
          "What",
          "the exploration of Mars",
          "What",
          "If a spacecraft completed the long journey to Mars, where would be the best place for it to land?, What would be the biggest challenge or threat to any human who tried to live on Mars?",
        ],
      },
    ],
  },
  4: {
    unit: "Starter U6",
    id: "SW9-2024-Starter-U6-P58-E4",
    exerciseKey: "/img/FriendsPlus/Page58/key/e4-key.jpg",
    audio: "/Audios/1.20.mp3",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 800,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: `<span style='color:#574d89;font-weight:800;'>Listening</span> <br>
        <span style='font-size:30px'>4</span> <headphone name=20 colorImg=#71699c ></headphone> Listen to the conversation between
        the teacher and the three students. How many students would like to go to Mars?

`,
        left: 50,
        star: 0,
        width: "27cm",
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <input id=0 />
        </div>
      
        `,
        answer: ["One - Maya"],
      },
    ],
  },
  5: {
    unit: "Starter U6",
    id: "SW9-2024-Starter-U6-P58-E5",
    exerciseKey: "/img/FriendsPlus/Page58/key/e5-key.jpg",
    audio: "/Audios/1.20.mp3",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 130,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: ` <headphone name=20 colorImg=#71699c ></headphone>  Listen again. Write David, Maya or Sophia.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    <input id=0  />
                    <span> isn’t very keen on adventure.</span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                    <input id=1  />
                    <span>  thinks that Mars could be dangerous.</span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                    <input id=2  />
                    <span>  would have said ‘no’ to the question last year.</span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                    <input id=3  />
                    <span>  thinks that people might not return.</span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>5</span>
                    <input id=4  />
                    <span>  is worried about the long journey.</span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>6</span>
                    <input id=5  />
                    <span>  thinks that she has the right personality to go to Mars. </span>
                </div>

               

        </div>
      
        `,
        answer: ["David", "Sophia", "Maya", "Sophia", "David", "Maya"],
      },
    ],
  },
  6: {
    unit: "Starter U6",
    id: "SW9-2024-Starter-U6-P58-E6",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` <span style='color:#574d89;font-weight:800;'>Writing</span>  

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>

                <b style='font-size:30px'>6</b>&ensp; <span style='margin-right:10px;font-weight:bold;' > A TASK You are doing a science project. Write a
                  letter to a famous professor asking questions.
                 </span> <br>
                 <textarea rows=5 id=0 ></textarea>
        
                <span style='margin-right:10px;font-weight:bold;' > B THINK AND PLAN </span> <br> 
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > Who are you? Why are you writing?
                    How have you heard of this famous professor? </span>
                    <textarea rows=2 id=1 ></textarea>
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                    <span > What is your project about? Endangered
                    animals? Cures for diseases? Meteors? </span>
                    <textarea rows=2 id=2 ></textarea>
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                    <span > What questions would you like to ask? </span>
                    <textarea rows=2 id=3 ></textarea>
                </div>

                <span style='margin-right:10px;font-weight:bold;' > C WRITE Use the text in exercise 2 and the writing
                guide. </span> <br>
                <img src='/img/FriendsPlus/Page58/15.jpg' style='width:600px;margin-left:200px;margin-top:40px' />
                <br><br>
                <textarea rows=6 id=4 ></textarea>

        </div>
      
        `,
        answer: [],
      },
    ],
  },
};

export default json;
