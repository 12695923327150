import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Match_Write from "../../components/ExcerciseTypes/Match_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P50-E1",
    exerciseKey: "/img/FriendsPlus/Page50/key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Match a–f with 1–6.
`,
        left: -50,
        star: 1,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: [],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "241px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "241px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "241px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "241px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "241px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "241px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "241px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: ["1-10", "2-7", "12-3", "4-9", "11-5", "0-8"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "do_you_usually_do / are_you_usually_doing",
          "I’m_seeing / I_see",
          "We’re_learning / We_learn",
          "does_the_World_Cup_Final_start / is_the_World_Cup_Final_starting",
          "Does_your_aunt_work / Is_your_aunt_working",
          "is_always_using / always_uses",
          "Are_you_going / Do_you_go",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
         <input id=0 type=boxMatch />
         <input id=1 type=boxMatch />
<input id=2 type=boxMatch />
<input id=3 type=boxMatch />
<input id=4 type=boxMatch />
<input id=5 type=boxMatch />
<input id=7 type=boxMatch />
<input id=8 type=boxMatch />
<input id=9 type=boxMatch />
<input id=10 type=boxMatch />
<input id=11 type=boxMatch />
<input id=12 type=boxMatch />

            <div style='line-height:47px;display:flex'>
                  <div style='margin-right:300px'>
                    <b>1</b>&ensp;Jessica is scared<br>
                    <b>2</b>&ensp;Are you worried<br>
                    <b>3</b>&ensp;They’re good<br>
                    <b>4</b>&ensp;Is he thrilled<br>
                    <b>5</b>&ensp;Are you terrified<br>
                    <b>6</b>&ensp;We’re happy<br>
                  </div>
                  <div>
                    <b>a</b>&ensp;at skateboarding.<br>
                    <b>b</b>&ensp;of heights.<br>
                    <b>c</b>&ensp;of injections?<br>
                    <b>d</b>&ensp;about the maths test?   <br>
                    <b>e</b>&ensp;about the exciting trip.<br>
                    <b>f</b>&ensp;at hip-hop music?<br>
                  </div>
            </div>
                  
          
        `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P50-E2",
    exerciseKey: "/img/FriendsPlus/Page50/key/e2-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Choose the correct words
`,
        left: -50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: [],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "58px",
              left: "232px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "none",
          // borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "at / in / about",
          "by / at / of",
          "of / at / about",
          "in / of / about",
          "at / about / by",
          "on / of / in",
        ],
        answers: ["0-8", "1-0", "2-4", "3-8", "4-4", "5-0"],
        initialValue: [],
      },
      Layout: `
        
        <div style='margin-top:20px;position:relative'>
            Are you scared
            <span > <b> <span style='border:1px solid;border-radius:50%;'>of</span> / <span > at / in </b> </span>
            swimming in the sea?

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span >  I’m not nervous </span>
            <input id=0 type=Circle />
            <span> going to the dentist. </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > My friend is fascinated </span>
            <input id=1 type=Circle />
            <span>  French films. </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > Is Danny bad </span>
            <input id=2 type=Circle />
            <span> maths? </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > I think Kate will be worried </span>
            <input id=3 type=Circle /> 
             <span>  going to the doctor’s. </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span > I'm very nervous </span>
            <input id=4 type=Circle />
            <span>  the interview next week. </span>

          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 6 </span>
            <span > Are you keen </span>
            <input id=5 type=Circle />
            <span>  fast rides? </span>
          </div>

       </div>
      `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P50-E3",
    exerciseKey: "/img/FriendsPlus/Page50/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 230,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "keen",
          "good",
          "happy",
          "thrilled",
          "nervous",
          "scared",
          "<s>worried</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `  Complete the definitions with the words
          and the correct prepositions.

`,
        left: 50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;'>
          <div>
                <div style='margin-top:20px;'>
                  <span> If you feel unhappy because you think something bad will happen, you feel </span>
                  <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> worried about </span>
                    it.
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > If you smile and you’re pleased about something, you’re very </span> 
                    <input id=0 />
                     it.
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                <span> If you’re afraid of something, you’re  </span> 
                <input id=1 />
                it.
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <span> If you have got a talent for something, you’re </span>  
                <input id=2 />
                it.
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <span> If you feel really excited about the Christmas party, you're </span> 
                <input id=3/>
                 it.
              </div>
            
            </div>

             <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                <span> If you feel a little worried or unhappy before an exam, you feel </span> 
                <input id=4/>
                it.
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 6 </span>
                <span> If you like a friend or a person in your family, you are very </span>
                <input id=5/>
                it.
              </div>
            
            </div>
         

        </div>
      
        `,
        answer: [
          "happy about",
          "scared of",
          "good at",
          "thrilled at",
          "nervous about",
          "keen on",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P50-E4",
    exerciseKey: "/img/FriendsPlus/Page50/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 21, // fontWeight: "bold",
      width: 160,
      paddingTop: 10,
      padding: 3,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "keen",
          "good",
          "happy",
          "thrilled",
          "nervous",
          "scared",
          "<s>worried</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  Complete the text with adjectives from
        this page. There may be more than one answer. 

`,
        left: 50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
        width: "27cm",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: "/",
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
            <img src='/img/FriendsPlus/Page50/12.jpg' style='width:600px;margin-left:100px' />
            <div style='position:absolute;top:476px;left:250px'> <input id=0  /> </div>
            <div style='position:absolute;top:536px;left:302px'> <input id=1  /> </div>
            <div style='position:absolute;top:597px;left:351px'> <input id=2  /> </div>
            <div style='position:absolute;top:685px;left:302px'> <input id=3  /> </div>
            <div style='position:absolute;top:714px;left:300px'> <input id=4  width=190px /> </div>
            
        </div>
      
        `,
        answer: ["keen", "thrilled", "good", "scared", "nervous /  worried"],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P50-E5",
    exerciseKey: "/img/FriendsPlus/Page50/key/e5-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 800,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "keen",
          "good",
          "happy",
          "thrilled",
          "nervous",
          "scared",
          "<s>worried</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `  Complete the questions with the correct
        prepositions. Then write answers that are true for
        you. Use complete sentences.

`,
        left: 50,
        star: 3,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>

                <div style='margin-top:20px;'>
                  <span> What ride in Universal Studios Singapore are you thrilled </span>
                  <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> at </span>
                  ? <br>
                  <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> I'm thrilled at the Transformers. </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > What sports are you good </span> 
                    <input id=0 width=100px /> ? <br>
                     <input id=1 />
                </div>
                     <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                    <span > What type of music are you keen </span> 
                    <input id=2 width=100px /> ? <br>
                     <input id=3 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                    <span > What exams are you worried </span> 
                    <input id=4 width=100px /> ? <br>
                     <input id=5 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 4 </span> 
                    <span > What animals are you scared </span> 
                    <input id=6 width=100px /> ? <br>
                     <input id=7 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 5 </span> 
                    <span > What things are you happy </span> 
                    <input id=8 width=100px /> ? <br>
                     <input id=9 />
                </div>
        
        </div>
      
        `,
        answer: ["at", "", "on", "", "about", "", "of", "", "about", ""],
      },
    ],
  },
};
export default json;
