import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "SW9-2024-U1-P12-E1",
    exerciseKey: "img/FriendsPlus/Page12/Key/E1answerKey.png",
    audio: "Audios/1.03.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 50,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "decided",
          "didn’t stay",
          "’d left",
          "hadn’t seen",
          "wasn’t enjoying",
          "<s>were driving</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=03  ></headphone> Read and listen to the magazine
article. Put a–e in the correct order.`,
        color: "#dc3d47",
        left: 50,
        star: 1,
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: T6,
    questions: [
      {
        title: `
        <b>a</b>&ensp;Samantha went to the UK to meet Anaïs.#<br>
        <b>b</b>&ensp;They used their story to make a film.#<br>
        <b>c</b>&ensp;Anaïs got in contact with Samantha. <span style="line-height: 1; width: 50px; font-size: 24px; font-weight: 400; text-align: center; border-width: 0px 0px 2px; border-style: solid solid dotted; border-top-color: rgb(168, 168, 168); border-right-color: rgb(168, 168, 168); border-bottom-color: initial; border-left-color: rgb(168, 168, 168); border-image: initial; border-radius: 0px; padding: 5px; display: inline-block; background: none; box-shadow: none; color: black;">1</span> <br>
        <b>d</b>&ensp;They went on an important trip together.#<br>
        <b>e</b>&ensp;They found out that they shared the same birthday.#<br>
        
        `,
        initialValue: [],
        answer: ["3", "5", "4", "2"],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SW9-2024-U1-P12-E2",
    exerciseKey: "img/FriendsPlus/Page12/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Read the magazine article again.
Complete the questions with <i>who, what</i> or <i>where</i>.
Then choose the correct answers.`,
        color: "#f0ab9c",
        width: "27cm",
        left: -50,
        star: 2,
        colorStar: "#f0ab9c",
      },
    ],
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 200,
      paddingTop: 10,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: ["Who", "What", "What", "Where", "Who"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "London / Los_Angeles / South Korea",
          "Samantha / Anaïs / their_friend",
          "first_met / were_born / left_South_Korea.",
          "Skype / letters / text_messages",
          "The_United_States / South_Korea / France",
          "a_friend / their_adoptive_parents / their_mother",
        ],
        answers: ["0-4", "1-4", "2-4", "3-0", "4-4", "5-8"],
        initialValue: ["0-4"],
      },
      Layout: `
       <u style='text-decoration-style: dotted'>&emsp;Where&emsp;</u> does Samantha live?<br>
      <input id=0 type=Circle /><br>
      <b>1</b>&ensp;#found some fascinating information?<br>
      <input id=1 type=Circle /><br>
      <b>2</b>&ensp;#happened to Samantha and Anaïs on<br>
      19th November 1987?<br>
      They <input id=2 type=Circle /><br>
      <b>3</b>&ensp;#did they use to communicate?<br>
      <input id=3 type=Circle /><br>
      <b>4</b>&ensp;#did they travel to together?<br>
      <input id=4 type=Circle /><br>
      <b>5</b>&ensp;#did they send a letter to?<br>
      <input id=5 type=Circle /><br>
       <img src="img/FriendsPlus/Page12/E2/1.jpg" style='height:36cm'/>
      
      `,
    },
  },

  3: {
    unit: "Unit 1",
    id: "SW9-2024-U1-P12-E3",
    exerciseKey: "img/FriendsPlus/Page12/Key/E3answerKey.png",
    audio: "",
    video: "",
    // textAlign:'center',
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 800,
      padding: 0,
      fontSize: 23,
      paddingTop: 5,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "awesome places to visit",
          "big hotels",
          "cool shops and markets",
          "fun entertainment",
          "historic buildings",
          "nice parks to walk in",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Answer the questions. Write complete
sentences.`,
        width: "27cm",
        color: "#f0ab9c",
        left: 50,
        star: 3,
        colorStar: "#f0ab9c",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        

    <div style='width:20cm'>
         Why was Samantha surprised when she saw Anaïs’s photo?<br>
        <u style='text-decoration-style: dotted'>She was surprised because Anaïs looked exactly&emsp;&emsp;</u> <br>
        <u style='text-decoration-style: dotted'>the same as her.&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</u> <br>
        <b>1</b>&ensp;What happened to Samantha and Anaïs when they were born?<br>#<br>
        <b>2</b>&ensp;What did they learn from the DNA test?<br>#<br>
        <b>3</b>&ensp;What did they say in their letter?<br>#<br>
        <b>4</b>&ensp;What type of film did they make?<br>#<br>
        <b>5</b>&ensp;How did they make the film?<br>#<br>
    </div>
          
        `,
        answer: [
          "They were adopted.",
          "They learned that they were identical twins.",
          "They thanked their mother for giving them life.",
          "They made a documentary film.",
          "A friend helped them to film the events as they happened in real life.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SW9-2024-U1-P12-E4",
    exerciseKey: "img/FriendsPlus/Page12/Key/E4answerKey.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    // isHello:true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 200,
      // marginLeft: 20,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: {
      width: 700,
      resize: "none",
      paddingTop: 3,
      marginBottom: -23,
      // marginLeft: 17,
      marginTop: -3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["finish", "go", "lose", "not eat", "not fly", "not see"],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<b>VOCABULARY PLUS</b> Complete the
sentences with the adverb form of the words in
brackets.
`,
        color: "#f0ab9c",
        width: "27cm",
        left: 50,
        star: 2,
        colorStar: "#f0ab9c",
      },
    ],
    // KeyMau: true,
    // isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
         The woman in the photo looked <u style='text-decoration-style: dotted'>&emsp;&emsp;surprisingly&emsp;&emsp;</u><br>
        similar to Samantha. (surprising)
        <div style='width:26cm'>
            <b>1</b>&ensp; #, Samantha and Anaïs grew up in different countries. (fascinating)<br>
            <b>2</b>&ensp;The young women # started to speak on Skype. (immediate)<br>
            <b>3</b>&ensp;The women laughed #. (identical)<br>
            <b>4</b>&ensp;They # took a DNA test. (quick)<br>
            <b>4</b>&ensp; #, the test was positive. (incredible)<br>
        </div>
        `,
        answer: [
          "Fascinatingly",
          "immediately",
          "identically",
          "quickly",
          "Incredibly",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SW9-2024-U1-P12-E5",
    exerciseKey: "img/FriendsPlus/Page12/Key/E5answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 850,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "brother(s) / sister(s)",
          "live in …",
          "name",
          "used to play / go / like",
        ],
        width: 600,
        borderColor: "#f0ab9c",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `You meet a friend who you haven’t seen
for a long time. Write four or five questions using
question tags. Use the phrases in the box and
exercise 4 for ideas.`,
        color: "#f0ab9c",
        left: 50,
        star: 3,
        colorStar: "#f0ab9c",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <textarea id=0 rows=5></textarea>
        `,
        initialValue: ["Hey, it's Derin, isn't it?"],
        answer: [
          "Can you tell me why to consider studying abroad for a term?",
          "Do you know who to approach for guidance on writing a good essay?",
          "Can you recommend where to celebrate our year-end party next week?",
          "Can you tell me who to ask for permission before using the hall?",
        ],
      },
    ],
  },
};

export default json;
