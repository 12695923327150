import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P15-E1",
    exerciseKey: "img/FriendsPlus/Page15/Key/E1answerKey.png",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 170,
      padding: 0,
      fontSize: 23,
      paddingTop: 3,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "been",
          "has (x4)",
          "hasn’t (x3)",
          "have (x3)",
          "haven’t (x2)",
          "lived",
          "living",
          "’s",
          "<s>’ve</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the table with the words.`,
        color: "#5cccd9",
        left: 50,
        star: 1,
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "#0067b4",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: "/",
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
      <div style='position:relative;margin-right:20px'>
        <div style='position:absolute;top:56px;left:26px;width:160mm'>
              I / You / We / You / They’ve travelled a lot.<br>
              He / She / It’s <sup>1</sup><input /> in different places.<br>
              I / You / We / You / They <sup>2</sup><input /> arrived.<br>
              He / She / It <sup>3</sup><input /> rushed into things.<br>
              <sup>4</sup><input /> I / you / we / you / they felt bored? <br>
              <sup>5</sup><input /> he / she / it made a good decision?
        </div>
        <div style='position:absolute;top:370px;left:26px;width:156mm'>
            I / You / We / You / They’ve <sup>6</sup><input width=160px /> studying.<br>
            He / She / It <sup>7</sup><input width=100px /> been avoiding technology.<br>
            I / You / We / You / They haven’t been <sup>8</sup><input width=160px /> an
            alternative lifestyle.<br>
            He / She / It <sup>9</sup><input /> been using computers.<br>
            <sup>10</sup><input /> I / you / we / you / they been eating
            healthy foods?<br>
            <sup>11</sup><input /> he / she / it been enjoying life?
        </div>
       
        
        
        <img src="img/FriendsPlus/Page15/E1/1.jpg" style='height:19cm'/>
      </div>
      

    
        `,
        // initialValue: ["", "", "1"],
        answer: [
          "lived",
          "have / haven't",
          "has / hasn't",
          "Have",
          "Has",
          "been",
          "'s",
          "living",
          "has / hasn't",
          "Have / Haven't",
          "Has / Hasn't",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P15-E2",
    exerciseKey: "img/FriendsPlus/Page15/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Write sentences and questions using the
present perfect continuous and for or since.`,
        color: "#5cccd9",
        width: "27cm",
        left: 50,
        star: 2,
        colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 900,
      paddingTop: 10,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `

        I / try / to decide / yesterday<br>
        <u style='text-decoration-style: dotted'>I’ve been trying to decide since yesterday.&emsp;&emsp;</u><br>
        <b>1</b>&ensp;she / hesitate / about the decision / last month<br>#<br>
        <b>2</b>&ensp;we / consider / the options / weeks<br>#<br>
        <b>3</b>&ensp;he / make / his mind up / a few days / ?<br>#<br>
        <b>4</b>&ensp;they / live / this lifestyle / May / ?<br>#<br>
        <b>5</b>&ensp;you / not use / the internet / the last two weeks<br>#<br>
        <b>6</b>&ensp;my sister / not eat / any meat or fish / she became a vegetarian<br>#<br>
        `,
        answer: [
          "She's been hesitating about the decision since last month.",
          "We've been considering the options for weeks.",
          "Has he been making his mind up for a few days?",
          "Have they been living this lifestyle since May?",
          "You haven't been using the internet for the last two weeks.|You have not been using the internet for the last two weeks.",
          "My sister hasn't been eating any meat or fish since she became a vegetarian.|My sister has not been eating any meat or fish since she became a vegetarian.",
        ],
      },
    ],
  },

  3: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P15-E3",
    exerciseKey: "img/FriendsPlus/Page15/Key/E3answerKey.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 800,
      padding: 0,

      fontSize: 22,
      paddingTop: 1,
      paddingBottom: 4,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "15cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#0067b4",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "changed",
          "<s>commit</s>",
          "dropped out",
          "keep your options",
          "made up my mind",
          "rush into",
          "stick with",
          "took my time",
          "try out",
        ],
        width: 600,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the online article with the
present perfect simple or continuous form of the
verbs in brackets and <i>for</i> or <i>since</i>.`,
        width: "27cm",
        color: "#5cccd9",
        left: 50,
        star: 2,
        colorStar: "#b4e2ef",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
      <div style='position:relative;margin-right:20px'>
        <div style='position:absolute;top:93px;left:39px;width:160mm;font-size:22px'>
               Nineteen-year-old British student Tom Davies is
              near the end of an incredible journey. He’s <u style='text-decoration-style: dotted'>been travelling&emsp;</u>(travel) around the world 
              <sup>1</sup><input width=100px /> six months and he <span style='white-space: nowrap'><sup>2</sup><input width=200px /></span> (camp) next
              to the road every night.<br>
              On his long journey, Tom <sup>3</sup><input width=120px /> (visit)
              four continents and he <sup>4</sup><input width=100px /> (be) to
              twenty-one different countries including Turkey,
              India and Australia. His friends are still watching his
              route on the internet – they <span style='white-space: nowrap'><sup>5</sup><input width=240px /></span>
              (follow) him online <span style='white-space: nowrap'><sup>6</sup><input width=100px /></span> the beginning.<br>
              Tom <sup>7</sup><input width=230px /> (not cycle) <sup>8</sup><input width=100px />
              a long time just for fun, he <sup>9</sup><input width=190px />
              (help) to raise money for charity. He
              <sup>10</sup><input width=120px /> (make) £50,000 so far and he
              hopes to raise more money. Tom is looking forward
              to a holiday because he <span style='white-space: nowrap'><sup>11</sup><input width=139px /></span> (not
              have) the chance to relax <sup>12</sup><input width=100px /> he started!
        </div>
        <img src="img/FriendsPlus/Page15/E3/1.jpg" style='height:28cm'/>
      </div>
        `,
        answer: [
          "for",
          "'s been camping|has been camping",
          "'s visited|has visited",
          "'s been|has been",
          "'ve been following|have been following",
          "since",
          "hasn't been cycling|has not been cycling",
          "for",
          "'s been helping|has been helping",
          "'s made|has made",
          "hasn't had|has not had",
          "since",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P15-E4",
    exerciseKey: "img/FriendsPlus/Page15/Key/E4answerKey.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    // isHello:true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 200,
      // marginLeft: 20,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
      marginBottom: -23,
      // marginLeft: 17,
      marginTop: -3,
    },
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "chat to friends online",
          "choose a film to watch",
          "<s>decide to go out</s>",
          "plan what to have for lunch",
          "start cooking lunch",
          "<s>tidy my bedroom</s>",
          "try out the new games console",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `You’ve spent the morning at home with a
friend. Tell your parents about your day so far. Use
the present perfect simple or continuous and the
ideas in the box.
`,
        color: "#5cccd9",
        width: "27cm",
        left: 50,
        star: 3,
        colorStar: "#b4e2ef",
      },
    ],
    // KeyMau: true,
    // isHello: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>       
        <textarea id=0 rows=10 ></textarea>
        `,
        fixedValue: [
          `I've been tidying my bedroom!
We've decided to go out later.`,
        ],
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P15-E5",
    exerciseKey: "img/FriendsPlus/Page15/Key/E5answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 850,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "brother(s) / sister(s)",
          "live in …",
          "name",
          "used to play / go / like",
        ],
        width: 600,
        borderColor: "#6fd7e7",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `You meet a friend who you haven’t seen
for a long time. Write four or five questions using
question tags. Use the phrases in the box and
exercise 4 for ideas.`,
        color: "#0067b4",
        left: 50,
        star: 3,
        colorStar: "#95b0db",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <textarea id=0 rows=5></textarea>
        `,
        initialValue: ["Hey, it's Derin, isn't it?"],
        answer: [
          "Can you tell me why to consider studying abroad for a term?",
          "Do you know who to approach for guidance on writing a good essay?",
          "Can you recommend where to celebrate our year-end party next week?",
          "Can you tell me who to ask for permission before using the hall?",
        ],
      },
    ],
  },
};

export default json;
