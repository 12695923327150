import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "LFP-Starter Unit 2",
    id: "SW9-2024-LFP-Starter-U2-P62-E1",
    exerciseKey: "/img/FriendsPlus/Page62/key/e1-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 190,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Complete the dialogues using the present perfect
        simple or continuous form of the verbs in the box.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span>‘Why isn’t James at football practice today?’ ‘He
                     <b><span style='border-bottom:2px dotted;'> ’s broken </span></b></span>
                     his leg. He fell off his bike.’
                </div>
                <p></p>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    ‘I really enjoyed that book.’ ‘Yes, it’s great! I
                     <input id=0  />
                    it for two weeks, but I still have 100 more pages to go!’
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                   ‘Why are you angry?’ ‘My sister
                     <input id=1 />
                    my necklace!’
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                   ‘When is the next bus?’ ‘We don’t know! We
                     <input id=2 />
                    at this bus stop for a long time now. We’re bored!’
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                   ‘Emma needs to do her homework.’ ‘Yes. She
                     <input id=3 />
                     that video game since two o’clock. She should stop now!’
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>5</span>
                   ‘Are you in any school clubs this year?’ ‘Yes, I
                     <input id=4 />
                      the drama club.’
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>6</span>
                   ‘When’s your brother’s birthday?’ ‘It’s today. Look at this brilliant present that I
                     <input id=5 />
                      for him.’
                </div>

                
                

        </div>
      
        `,
        answer: [
          "'ve been reading",
          "has lost",
          "'ve been waiting",
          "'s been playing",
          "'ve joined",
          "'ve bought",
        ],
      },
    ],
  },
  2: {
    unit: "LFP-Starter Unit 2",
    id: "SW9-2024-LFP-Starter-U2-P62-E2",
    exerciseKey: "/img/FriendsPlus/Page62/key/e2-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 270,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Complete the sentences with the present perfect
        simple or continuous form of the verbs in brackets
        and for or since.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span>It
                     <b><span style='border-bottom:2px dotted;'> hasn’t been raining </span></b></span>
                     (not rain) <b><span style='border-bottom:2px dotted;'> for </span></b></span>
                     very long. Only fifteen minutes.
                </div>
                <p></p>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    I <input id=0  />  (learn) German 
                    <input id=1 width=100px /> September. I’ll continue studying it next year.
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                    They  <input id=2  />   (know) Stephen
                    <input id=3  width=100px /> years
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                    You <input id=4  />  (not listen) to me
                    <input id=5  width=100px /> the last ten minutes!
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                    My mum <input id=6  />  (not hear) that song
                    <input id=7 width=100px /> she was young.
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>5</span>
                     <input id=8  />   (you / stand) here
                    <input id=9 width=100px /> hours? It’s late now.
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>6</span>
                     <input id=10  />   (Martina / have) that phone
                    <input id=11 width=100px /> her birthday?
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>7</span>
                     She <input id=12  />   (get ready)
                    <input id=13 width=100px /> almost two hours now!
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>8</span>
                     He <input id=14  />  (love) table tennis
                    <input id=15 width=100px /> he was very young.
                </div>
   

        </div>
      
        `,
        answer: [
          "'ve been learning",
          "since",
          "'ve known",
          "for",
          "haven't been listening",
          "for",
          "hasn't heard",
          "since",
          "Have you been standing",
          "for",
          "Has Martina had",
          "since",
          "'s been getting ready",
          "for",
          "'s loved",
          "since",
        ],
      },
    ],
  },
  3: {
    unit: "LFP-Starter Unit 2",
    id: "SW9-2024-LFP-Starter-U2-P62-E3",
    exerciseKey: "/img/FriendsPlus/Page62/key/e3-key.jpg",
    audio: "",
    video: "",
    component: Circle_Write,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 270,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Correct the mistakes in bold. Tick the sentence that is correct.

`,
        left: -30,
        star: 0,
        color: "#71699c",
        // colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          background: "none",
          fontSize: 24,
          // borderBottom: "none",
          borderBottom: "2px dotted",
          width: 200,
          height: 32,
          paddingTop: 2,
        },
        answers: ["haven't enjoyed", "started", "'ve taken up", "found", ""],
        initialValue: [],
      },
      Circle: {
        TickRong: true,
        initialWordStyle: {
          marginLeft: 10,
          padding: "1px 10px",
          border: "2px solid",
          borderRadius: 5,
          color: "transparent",
          borderColor: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          borderRadius: 5,
          // borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓", "✓", "✓", "✓", "✓"],
        answers: ["4-0"],
        initialValue: [],
      },
      Layout: `
 
            Irem <b style='text-decoration:line-through'>has changed</b> her mind yesterday. <u style='text-decoration-style: dotted'>&emsp;changed&emsp;</u>
            <div style='line-height:45px;width:27cm'>
              <b>1</b>&ensp;So far, I <b>didn’t enjoy</b> this film.. # <input id=0 type=Circle />  <br>
              <b>2</b>&ensp;We<b>’ve started</b> the course a week ago.. # <input id=1 type=Circle />  <br>
              <b>3</b>&ensp;Since March, I <b>took up</b> running.. # <input id=2 type=Circle />  <br>
              <b>4</b>&ensp;He<b>’s found</b> a new job in 2015.. # <input id=3 type=Circle />  <br>
              <b>5</b>&ensp;<b>Did</b> Ian <b>leave</b> school last year?. # <input id=4 type=Circle />  <br>
            </div>

  `,
    },
  },
  4: {
    unit: "LFP-Starter Unit 2",
    id: "SW9-2024-LF-U2-P62-E2",
    exerciseKey: "img/FriendsPlus/Page62/key/E4answer.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "4",
        title: `Order the words to make sentences.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 900,
      paddingTop: 10,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `


        I / her / is / even though / sometimes / she / annoying / like<br>
        <u style='text-decoration-style: dotted'>I like her even though she is sometimes annoying.&emsp;&emsp;</u><br>
        <b>1</b>&ensp;the / wasn't / was / Although / it / very / shining / warm / sun<br>#<br>
        <b>2</b>&ensp;he / all / get / necessary / the / didn't / qualifications / job / though / He / had / the<br>#<br>
        <b>3</b>&ensp;people / use / to / while driving / In spite of / continue / the law, / mobile phones<br>#<br>
        <b>4</b>&ensp;I / birthday / Despite / a / had / having / headache / a / great<br>#<br>
        
        `,
        answer: [
          "Although the sun was shining, it wasn't very warm.",
          "He didn't get the job though he had all the necessary qualifications.",
          "In spite of the law, people continue to use mobile phones while driving.",
          "Despite having a headache, I had a great birthday",
        ],
      },
    ],
  },
  5: {
    unit: "LFP-Starter Unit 2",
    id: "SW9-2024-LF-U2-P62-E2",
    exerciseKey: "img/FriendsPlus/Page62/key/E5answer.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with phrases a–e.
Put the verbs into the past simple.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 600,
      paddingTop: 10,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
   

        Fred dropped out of the photography course,<br>
        <u style='text-decoration-style: dotted'>whereas we continued.&emsp;&emsp;</u><br>
        <b>1</b>&ensp;The work experience was quite difficult.<br>#<br>
        <b>2</b>&ensp;Everybody thought that the answer was correct,<br>#<br>
        <b>3</b>&ensp;It was really hot inside the classroom.<br>#<br>
        <b>4</b>&ensp;I learned French at the language school,<br>#<br>
        <b>a</b>&ensp;although / we not be sure<br>
        <b>b</b>&ensp;however / I learn a lot from it<br>
        <b>c</b>&ensp;in contrast / it feel cold outside<br>
        <b>d</b>&ensp;whereas / my friend study Italian there<br>
        <b>e</b>&ensp;<span style='text-decoration:line-through'>whereas / we continue</span><br>
        
        `,
        answer: [
          "However, I learned a lot from it.",
          "although we weren't sure.",
          "In contrast, it felt cold outside.",
          "whereas my friend studied Italian there.",
        ],
      },
    ],
  },
};

export default json;
