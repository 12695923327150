import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P44-E1",
    exerciseKey: "/img/FriendsPlus/Page44/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 40,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["in", "that (x2)", "where", "which", "<s>who</s>", "whose"],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Look at the map. Match labels 1–10 with
            places A–J.

`,
        left: 50,
        star: 1,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        
         <div style='margin-top:20px;'>
         <div style='display:flex'>
         
          <div style='display:flex'>
            <div>
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> G </span>
                    <span> pedestrian walkway </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                    <input id=0 /> 
                    <span > diving pool  </span>
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <input id=1 /> 
                <span> open-air cinema </span> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <input id=2 /> 
                <span> skatepark </span> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                <input id=3/> 
                <span> ancient ruins  </span> 
              </div>
            
            </div>

            <div style='margin-left:60px'>
              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 6 </span>
                <input id=4/> 
                <span> green space  </span> 
              </div>

             <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 7 </span>
                <input id=5/> 
                <span> industrial site </span>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 8 </span>
                <input id=6/> 
                <span> city centre </span>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 9 </span>
                <input id=7/> 
                <span> sports stadium </span>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 10 </span>
                <input id=8/> 
                <span> leisure facilities </span>
              </div>
            
            </div>
          
          </div>
          <img src='/img/FriendsPlus/Page44/9.jpg' style='width:500px;margin-left:30px' />
        </div>        
        </div>
      
        `,
        answer: ["h", "b", "d", "a", "c", "i", "f", "j", "e"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P44-E2",
    exerciseKey: "/img/FriendsPlus/Page44/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["in", "that (x2)", "where", "which", "<s>who</s>", "whose"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Match some of the places in exercise 1
          with 1–6.

`,
        left: 50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        
        <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span> Where would you go to … ? go skateboarding with friends </span>
                    <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> skatepark </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    <span > watch the latest film  </span>
                    <input id=0 /> 
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                <span> watch a football match </span> 
                <input id=1 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <span> relax in a natural area </span> 
                <input id=2 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <span> take photos for a history project  </span> 
                <input id=3/> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                <span> do work experience with an engineering company  </span> 
                <input id=4/> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 6 </span>
                <span> visit shops or museums  </span> 
                <input id=5/> 
              </div>
            
            </div>
           
        </div>
      
        `,
        answer: [
          "open-air cinema",
          "sports stadium",
          "green space",
          "ancient ruins",
          "industrial site",
          "city centre",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P44-E3",
    exerciseKey: "/img/FriendsPlus/Page44/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 22,
      width: 230,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "heavy traffic",
          "<s>leisure facilities</s>",
          "music venue",
          "pedestrian walkways",
          "railway line",
          "skatepark",
          "sports stadium",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Complete the posts on an online forum
        with the words.

`,
        left: 50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;position:relative'>
            <img src='/img/FriendsPlus/Page44/10.jpg' style='width:600px' />
            <div style='position:absolute;top:223px;left:374px'> <input id=0 /> </div>
            <div style='position:absolute;top:281px;left:171px'> <input id=1 /> </div>
            <div style='position:absolute;top:309px;left:379px'> <input id=2 /> </div>
            <div style='position:absolute;top:438px;left:72px'> <input id=3 /> </div>
            <div style='position:absolute;top:466px;left:361px'> <input id=4 /> </div>
            <div style='position:absolute;top:524px;left:32px'> <input id=5 /> </div>
        </div>
      
        `,
        answer: [
          "skatepark",
          "sports stadium",
          "music venue",
          "heavy traffic",
          "pedestrian walkways",
          "railway line",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P44-E4",
    exerciseKey: "/img/FriendsPlus/Page44/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 22,
      width: 230,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "it could do with",
          "I think my town needs",
          "I wish we had more",
          "there are too many / few",
          "there isn’t / aren’t enough",
          "there’s a lack of",
          "there’s too much / little",
          "what we need is",
          "",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  Now write a post about where you live for
        the online forum. Use the posts in exercise 3 and the
        phrases in the box to help you.

`,
        left: 50,
        star: 3,
        color: "#0067b3",
        colorStar: "#95b0db",
        width: "27cm",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-left:150px;'><hintbox id=0 ></hintbox> </div>
        <div style='margin-top:20px;position:relative'>
            <img src='/img/FriendsPlus/Page44/11.jpg' style='width:600px;margin-left:150px;' />
            <div style='width:22cm;margin-top:20px;'>
              <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'>
                I think my town needs a new railway line. There aren’t enough trains that go to the city centre and it could also do with more bike lanes and pedestrian walkways.
              </span> <br> <br>
              <textarea rows=10 id=0 ></textarea>
            </div>
        </div>
      
        `,
        answer: [
          "I think our city needs more green spaces and it could do with a new park next to the ancient ruins. There's a lack of places to see bands too. What we need is a good music venue or a big sports stadium where bands could also play. There's too much heavy traffic in the city centre - I wish we had better public transport and more bike lanes.",
        ],
      },
    ],
  },
};

export default json;
